import {  Button, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AirtimeForm } from "../../components/PayBills/AirtimeForm";

import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/PayBills/BackButton";
import { DataForm } from "../../components/PayBills/DataForm";
import { CableForm } from "../../components/PayBills/CableForm";
import { InternetForm } from "../../components/PayBills/InternetForm";
import { PowerForm } from "../../components/PayBills/Power";
import { CloudEnergyForm } from "../../components/PayBills/CloudEnergyForm";
import { SteamaCoForm } from "../../components/PayBills/SteamaCoForm";

const BillForm = () => {
    const { type } = useParams();
    const {bills, activeBill} = useSelector(state => state.billpayment);
    const dispatch = useDispatch();

    useEffect(() => {
      if (bills) {
        const bill = bills.find(item => item.slug === type);
        if (bill) {
          dispatch({type: 'SET_ACTIVE_BILL', payload: bill});
        };
      }
    }, [type, bills]);

  return (
    <div className="md:p-10 p-5">
      
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] mb-0 text-[24px] text-brand-primary">
          {activeBill?.name}
        </h2>
        <BackButton />
      </div>
      <div className="mt-10 bg-white rounded-lg md:p-10">
        <div className="bill-wrapper">
            {
                {
                    airtime: <AirtimeForm bill={activeBill} />,
                    cloud_energy: <CloudEnergyForm bill={activeBill} />,
                    data_bundle: <DataForm bill={activeBill} />,
                    internet: <InternetForm bill={activeBill} />,
                    cables: <CableForm bill={activeBill} />,
                    power: <PowerForm bill={activeBill} />,
                    steamaco: <SteamaCoForm bill={activeBill} />
                }[type]
            }
        </div>
      </div>
    </div>
  );
};

export default BillForm;
