import { useEffect, useState } from "react";
import CircleClose from "../../../assets/icons/circleClose.svg";
import { Modal, Button , notification} from "antd";
import { InputField } from "../Inputs/Input";
import { addPartner, updatePartner } from "../../../core-units/utils/queryFn";

export const PartnerForm = ({isModalOpen, handleCancel, data}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
     const [name, setName] = useState(data?.name || '');

    const [notice, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, data) => {
      notice[type]({
        message: data.message,
        description: data.description,
      });
    };

    useEffect(() => {
        if (data)
            setName(data?.name)
    }, [data]);

    const save = async () => {
        let resp;
        if (!name.length) {
            setError('Please enter a name');
            return;
        }

        try {
            setLoading(true);
            if (data) {
                resp = await updatePartner(data.id, {name});
            } else {
                resp = await addPartner({name})
            }
            setLoading(false);
            if (resp.status) {
                openNotificationWithIcon("success", {message : 'Success', description : resp.message})
                setName('');
                handleCancel(true);
            } else {
                openNotificationWithIcon("error", {message : 'Error', description : resp.message})
            }
        }catch(e) {
            setLoading(false);
            
        }
    }

    return (
        <Modal
            footer={null}
            open={isModalOpen}
            onCancel={handleCancel}
            centered
            closeIcon={<img src={CircleClose} alt="" />}
            title={`${data ? 'Edit' : 'Add'} Partner`}
        >
            <div className=" h-full w-full items-center justify-center p-10">
                {contextHolder}
                    
                <div className="mt-5 md:mt-0 w-full">
                    <span className='text-[#AEB5C2]'>Name</span>
                    <InputField 
                        type="text" 
                        size='large' 
                        className='mt-1' 
                        placeholder="Enter Partner Name"
                        name='name' 
                        value={name}
                        onChange={(e) => setName(e.target.value)} 
                    />
                    <span className="text-red-500"> {error}</span>   

                </div>
                <div className="mt-2 md:mt-0 w-full">

                <Button
                    onClick={save}
                    loading={loading}
                    type="text"
                    disabled={loading}
                    className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
                >
                    <span className="text-white uppercase font-bold">
                        {loading ? 'Submitting...' : 'Submit'}
                    </span>
                </Button>
                </div>
            </div>
        </Modal>
    )
}