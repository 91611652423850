import { Button, Input, Modal, notification } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';

import { FLUTTERWAVE_KEY } from "../../../config";
import { initializeTransaction } from "../../../core-units/utils/queryFn";
import { useDispatch } from "react-redux";
import { formatNumber } from "../../../core-units/utils";

export const AddFundsModal = ({isModalOpen, handleClose, user}) => {
    const [amount, setAmount] = useState('');
    const [errMsg, setErrMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingMsg, setLoadingMsg] = useState('')
    const {data: settings} = useSWR('config/one?key=minimum_amount');
    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
          message: data.message,
          description:data.description,
        });
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [config, setConfig] = useState({
        tx_ref: null,
        public_key: FLUTTERWAVE_KEY,
        customer: {
            email: user?.email,
            phone_number: user?.phone,
            name: user?.firstname,
        },
        amount: 0,
        currency: 'NGN',
        payment_options: "card,mobilemoney,ussd",
    });

    const handlePayment = useFlutterwave(config);

    useEffect(() => {
        if(config.tx_ref) {
            handlePayment({
                callback: (response) => {
                    console.log('card payment', response);
                    if (response.status === 'successful') {
                        setLoadingMsg('Verifying Payment...');
                        closePaymentModal();
                        dispatch({type: 'TOGGLE_FUNDS_MODAL'})
                        navigate(`/transaction/${response.tx_ref}/status`);
                    } else {
                        setLoading(false);
                        setLoadingMsg('');
                        openNotificationWithIcon("error", {message : 'Payment Error', description : response.message})
                    }
                },
                onClose: () => {
                    setLoading(false);
                    setLoadingMsg('');
                    setConfig({...config, tx_ref: null});
                },
            });
        }
    }, [config])

    const getTrx = async () => {
        if (amount < parseInt(settings?.data.value)) {
            openNotificationWithIcon('error', {
                message : "Amount Error", 
                description : 'Minimum deposit amount is ' + settings?.data?.value
            })
            return;
        }
        setLoading(true);
        setLoadingMsg('...Initializing Payment')
        await initializeTransaction()
            .then(async res => {
                // console.log(res);
                if (res.status) {
                    setConfig({
                        ...config, 
                        tx_ref: res.data, 
                        amount: parseFloat(amount),
                    });
                    setLoading(false);
                } else {
                    setLoading(false)
                    openNotificationWithIcon('error', {
                        message : "Initialize Error", 
                        description : 'Unable to initialize payment: ' + res.message
                    })
                }
            })
            .catch(err => {
                setLoading(false);
            });
    };
    


    return (
        <Modal
            centered
            open={isModalOpen}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
            title="Fund Wallet"
        >
            {contextHolder}
            <div className="md:py-8 py-4 ">
                <div className="mt-5 md:mt-0 w-full">
                    <div className="flex justify-between mb-1">
                        <span>Enter Amount</span>
                        <span>Min Amount: {formatNumber(settings?.data?.value)}</span>
                    </div>
                    <Input
                        size="large"
                        name="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <span className="text-red-500 mt-5">{errMsg}</span>
                </div>
                <Button
                    onClick={getTrx}
                    loading={loading}
                    type="text"
                    className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
                >
                    <span className="text-white uppercase font-bold">
                        {loading ? loadingMsg : 'Make Payment'}
                    </span>
                </Button>
            </div>
        </Modal>
    )
}