import sha256 from 'crypto-js/sha256';
import api from '../api';

export const dayPeriod = () => {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
        return 'morning'
    } else if (curHr < 18) {
        return 'afternoon'
    } else {
        return 'evening'
    }
}

export const maskNumber = (number) => {
    const mask = "*";
    let maskedNumber = number.substring(0, 3) + mask.repeat(number.length-6) + number.slice(-2);
    return maskedNumber;
  };


  export const formatNumber = number =>
  !number
    ? '₦' + 0
    : '₦' + parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
export const hash = string => sha256(string);

export const updateDeviceNo = data => api.post('user/update-web-no', data);

export const products = [
    '1KVA Solution',
    '1KVA Solution + 200L Solution',
    '2KVA Solar Solution',
    '2KVA Solar Solution + 80L Vaccine Fridge',
    '2KVA Solar Solution + 318L Freezers',
    '2KVA Solar Solution + 339L Chiller',
    '3.6KVA Solar Solution',
    '5KVA Solar Solution'
]

export const formattedPhoneNumber = phoneNumber => {
    let pNumber = phoneNumber.toString();
    const first = pNumber.charAt(0);
    if (first === '0') {
        pNumber = pNumber.substring(1);
    }
    return '+234' + pNumber;
};