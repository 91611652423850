import React from "react";
import { Button, Spin, Modal, Upload, Select, notification, Empty, Collapse } from "antd";
import { Link, useParams } from "react-router-dom";
import { formatNumber } from "../../../core-units/utils";
import { useQuery } from "@tanstack/react-query";
import { singleLeaseProduct } from "../../../core-units/utils/queryFn";
import { useState } from "react";
import CircleClose from "../../../assets/icons/circleClose.svg";
import iconInfo from "../../../assets/icons/infoBlue.svg";
import api from "../../../core-units/api";
import downl from "../../../assets/icons/downloadWhite.svg";
import dayjs from "dayjs";
import award from "../../../assets/icons/award.svg";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";

import sendMail from "../../../assets/icons/mailSend.svg";
import { GuarantorsList } from "./GuarantorsList";

const View = () => {
  //   const navigate = useNavigate();
  const [notice, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, data) => {
    notice[type]({
      message: data.message,
      description: data.description,
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [docType, setDocType] = useState(null);
  const [file, setFile] = useState()
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const { id } = useParams();
  const product = useQuery({
    queryKey: ["leaseProduct", id],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => singleLeaseProduct(id),
  });
  const changeStat = (checked, e) => {
    console.log(checked, e);
    if (checked === "validate") {
      setOptions({
        message: "Do you want to validate credit check??",
        btnAction: () => deactivate(checked ? 1 : 0),
      });
      setIsModalOpen(true);
    }
    if (checked === "agreement") {
      setFileModal(true);
    }
    if (checked === "status") {
      setOptions({
        message: "Do you want to change application status?",
        btnAction: () => approveDisapprve(e),
      });
      setIsModalOpen(true);
    }
  };
  const handleCancel = () => {
    product.remove();
    setIsModalOpen(false);
    setFileModal(false);
  };

  const deactivate = (stat) => {
    let data = product.data;
    data.status = stat;
    api
      .post(`/lease-products/${data.id}`, data)
      .then((res) => {
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { Dragger } = Upload;
  const fileDroped = (e) => {
    console.log("Dropped files", e);
    setFile(e.file)
    e.onSuccess();
  };

  const sendFile = () => {
    let data = new FormData()
    data.append('lease_application_id', id)
    data.append('file', file)
    api.post(`/admin/lease-application/send-lease-agreement`, data).then(res => {
      console.log(res)
      openNotificationWithIcon('success', {message : "Success", description : res.data.message})
      handleCancel()
    }).catch(err => {
      console.log(err)
    })
  }

  const download = (type) => {
    setDocType(type)
    setLoading(true);
    api
      .get(`/admin/lease-application/download-document?id=${id}&document_type=${type}`)
      .then((res) => {
        window.open(res.data, "blank");
        setLoading(false);
        setDocType(null);

      }).catch(error => {

        setLoading(false);
        setDocType(null);
      })
  };

  const approveDisapprve = (status) => {
    setLoading(true);
    console.log(status);
    let data = "";
    if (status === 1) {
      data = "APPROVED";
    } else if (status === 2) {
      data = "DISAPPROVED";
    } else if (status === 0) {
      console.log("kkkk");
      setLoading(false);
      handleCancel();
      return;
    }
    api
      .post("/admin/lease-application", {
        id,
        status: data,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        handleCancel();
      })
      .catch((err) => {
        openNotificationWithIcon('error', {message : "Error", description : err.data.message})
        console.log(err);
        setLoading(false);
        handleCancel();
      });
  };

  return (
    <div className="md:p-10 p-5">
      {contextHolder}

      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className="flex h-full w-full items-center justify-center p-10">
          <div>
            <img src={iconInfo} alt="" className=" mx-auto" />
            <p className="mt-8">{options.message}</p>
            <div className="flex justify-around w-full mt-10">
              <Button
                onClick={options.btnAction}
                type="text"
                className=" uppercase bg-brand-primary font-bold "
              >
                {loading ? (
                  <span className="text-white">
                    {" "}
                    <Spin indicator={<LoadingOutlined spin />} /> LOADING...
                  </span>
                ) : (
                  <span className="text-white">CONTINUE</span>
                )}
              </Button>
              <Button
                onClick={handleCancel}
                type="text"
                className=" uppercase bg-brand-secondary font-bold "
              >
                <span className="text-white">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        footer={null}
        open={fileModal}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className=" py-10 h-full w-full ">
          <Dragger maxCount={1} customRequest={fileDroped}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>

          <div className="flex justify-around w-full mt-10">
            <Button
              onClick={sendFile}
              type="text"
              className=" uppercase bg-brand-primary font-bold "
            >
              {loading ? (
                <span className="text-white">
                  {" "}
                  <Spin indicator={<LoadingOutlined spin />} /> LOADING...
                </span>
              ) : (
                <span className="text-white">CONTINUE</span>
              )}
            </Button>
            <Button
              onClick={handleCancel}
              type="text"
              className=" uppercase bg-brand-secondary font-bold "
            >
              <span className="text-white">Cancel</span>
            </Button>
          </div>
        </div>
      </Modal>

      <div className="md:flex">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          View Application
        </h2>
      </div>
      <div className="bg-white rounded-lg md:p-16 mt-5">
        {product.data && (
          <div>
            <div className="md:flex gap-5 xl:gap-24">
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <div className="h-[195px] w-full overflow-x-auto  rounded-t-md">
                  <img
                    src={product.data.product?.image_url}
                    className="w-full h-full"
                    alt=""
                  />
                </div>
                <div className="mt-3 bg-brand-primary px-5 py-2 rounded-md flex justify-between">
                  <div className="bg-white rounded-full text-brand-primary w-20 py-1 text-center">
                    Price
                  </div>
                  <div className=" font-bold text-white  py-1 text-center">
                    {formatNumber(product.data.product?.amount)}
                  </div>
                </div>
                <Collapse className="mt-3">
                  <Collapse.Panel header="Download Files" key="1">
                  <div
                    className="bg-brand-secondary px-5 py-2 rounded-md flex justify-between cursor-pointer"
                    onClick={() => download('identification')}
                  >
                    {loading && docType === "identification" ? (
                      <span className="text-white">
                        {" "}
                        <Spin indicator={<LoadingOutlined style={{ color: 'white'}} spin />} /> LOADING...
                      </span>
                    ) : (
                      <div className=" text-white w-50">Identification Document</div>
                    )}
                    <div className=" font-bold text-brand-base w-20 py-1  text-right">
                      <img src={downl} alt="" className="ml-auto" />
                    </div>
                  </div> 
                  <div
                    className="mt-3 bg-brand-secondary px-5 py-2 rounded-md flex justify-between cursor-pointer"
                    onClick={() => download('proof_of_address')}
                  >
                    {loading && docType === "proof_of_address" ? (
                      <span className="text-white">
                        {" "}
                        <Spin indicator={<LoadingOutlined style={{ color: 'white'}} spin />} /> LOADING...
                      </span>
                    ) : (
                      <div className=" text-white w-50">Proof of Address</div>
                    )}
                    <div className=" font-bold text-brand-base w-20 py-1  text-right">
                      <img src={downl} alt="" className="ml-auto" />
                    </div>
                  </div> 
                  <div
                    className="mt-3 bg-brand-secondary px-5 py-2 rounded-md flex justify-between cursor-pointer"
                    onClick={() => download('bank_statement')}
                  >
                    {loading && docType === "bank_statement" ? (
                      <span className="text-white">
                        {" "}
                        <Spin indicator={<LoadingOutlined style={{ color: 'white'}} spin />} /> LOADING...
                      </span>
                    ) : (
                      <div className=" text-white w-50">Bank Statment</div>
                    )}
                    <div className=" font-bold text-brand-base w-20 py-1  text-right">
                      <img src={downl} alt="" className="ml-auto" />
                    </div>
                  </div> 
                  </Collapse.Panel>
                </Collapse>
                
                <div className="mt-3 gap-5  py-2 rounded-md flex justify-between">
                  <div
                    className=" border-2 py-3 w-1/2 px-4 rounded-md text-brand-base flex gap-4 items-center  justify-center cursor-pointer"
                    onClick={() => {
                      changeStat("validate");
                    }}
                  >
                    <img src={award} alt="" />
                    Validate Credit Check
                  </div>
                  <div
                    className=" border-2 py-3 w-1/2 px-4 rounded-md text-brand-base flex gap-4 items-center  justify-center cursor-pointer"
                    onClick={() => {
                      changeStat("agreement");
                    }}
                  >
                    <img src={sendMail} alt="" />
                    Send Agreement
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <h3 className="px-3 font-bold text-lg text-brand-primary">
                  {product.data.product?.product_name}
                </h3>
                <div className=" p-3 border-b cursor-pointer">
                  <Link to={`/customers/${product?.data?.user.id}`} className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold text-brand-primary">
                      {product.data.user.firstname} {product.data.user.lastname}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold text-brand-primary">
                        View Details
                      </span>
                    </div>
                  </Link>
                </div>
                <div className=" p-3 border-b cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base">
                        Credit Check Status
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold">
                        {product.data.credit_check ? "Yes" : "No"}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className=" mt-3 p-3 border-b  cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base"> Date of Birth</span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold">
                        12/05/2023
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className=" mt-3 p-3 border-b  cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base"> Duration</span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold">
                        {product.data.product?.lease_duration} Month(s){" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" mt-3 p-3 border-b  cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base"> Date Applied</span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold">
                        {dayjs(product.data.application_date).format(
                          "DD/MM/YYYY - HH:MM a"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" mt-3 p-3   cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2">
                      <span className=" text-brand-base"> Email Address</span>
                    </div>
                    <div className="flex gap-2">
                      <span className=" text-brand-base font-bold">
                        {product.data.user.email}{" "}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-3">
                  <Select
                    defaultValue={product.data.status}
                    className="w-full"
                    size="large"
                    onChange={(e) => {
                      changeStat("status", e);
                    }}
                    options={[
                      { value: 0, label: "Pending" },
                      { value: 1, label: "Approved" },
                      { value: 2, label: "Disapproved" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <p className="text-lg mb-5 pt-5">Guarantors </p>
            {product.data?.guarantor.length > 0 ? 
              <GuarantorsList guarantors={product?.data?.guarantor} />
              : 
              <Empty />
            }
          </div>
        )}
        {product.isLoading && (
          <div className="flex h-full mt- items-center  justify-center">
            {" "}
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
