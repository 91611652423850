import api from "../api"
import { saveAs } from 'file-saver';

export const getUser = async () =>  await api.get('/user');

export const createBillPayment = data =>
  api.post(`bill-payment/create?payment_type=${data.payment_type}`, data);

export const processCloudEnergy = data =>
  api.post(`bill-payment/process-cloud-energy`, data);

export const processSteamaCo = data =>
  api.post(`steamaco/process-payment`, data);

export const getUserAccount = () => api.get('dashboard');

export const validateSteamaCoCustomer = id => api.get(`steamaco/validate-customer/${id}`);

export const initializeTransaction = () => api.get('transaction/initialize');

export const verifyTransaction = data =>
  api.post('transaction/verify-payment', data);

export const getTransaction = id => api.get(`transfer/one?id=${id}`)

export const getTransactions = async (page, querys, download, link) => {
  if(download) {
    // console.log('rrr', download)
    let res = await api.get(`/transaction/user-transaction?export=true&export_type=${download}`, {
      responseType: 'blob',
    })
    // console.log(res)
    saveAs(res.data, 'transactions.xlsx')
    // return res.data.data
    return
  }

  if(link){
    let res = await api.get(link)
    // console.log(res.data, 'link')
  }
  
  let res = await api.get(`/transaction/user-transaction?page=${page}&from=${querys.from}&to=${querys.to}`)
  return res.data

}

export const getSingleTransaction = async (id) => {
  let res = await api.get(`/transaction/single?id=${id}`)
  return res.data
}

export const getDashboardData = async(querys) => {
  console.log(querys, 'query q')
  if(querys.from){
    // `/transaction/user-transaction?from=${querys.from}&to=${querys.to}&export=true&export_type=${download}`
    let res = await api.get(`/dashboard/web-app?from=${querys.from}&to=${querys.to}`)
    return res.data

  }
  let res = await api.get('/dashboard/web-app')
  return res.data
}

export const downloadReciept = (id) => {
  // http://example.com/api/v1/transaction/download-receipt
  api.get(`transaction/download-receipt?id=${id}`, 
  {responseType : 'blob'}
  ).then(res => {
    console.log(res)
    saveAs(res.data, 'transaction.pdf')
})}

export const getProductCat = async () => {
  let res = await api.get('/product-categories')
  return res.data

}

export const getAllLease = async () => {
  let res = await api.get(`/lease-products`)
  return res.data
}

export const getUserLease = async () => {
  let res = await api.get(`/lease`)
  return res.data
}

export const singleLease = async (id) => {
  let res = await api.get(`/lease-products/${id}`)
  return res.data
}
export const getPaymentSchedule = async (id) => {
  let res = await api.get(`/lease/schedule?id=${id}`)
  return res.data
}

export const adminLease = async () => {
  let res = await api.get(`/admin/lease-application/all?page=`)
  return res.data
}

export const singleLeaseProduct = async (id) => {
  let res = await api.get(`/admin/lease-application/${id}`)
  return res.data
}

export const getDocTypes = async () => {
  let res = await api.get('utilities/document-type');
  return res.data;
};

export const checkVerification = () =>
  api.get('lease-application/check-verification');

export const leaseApply = (data) =>
  api.post('lease-application/apply', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
  });


export const getCustomers = async (page) => {
  let res = await api.get(`user/all-users?page=${page}`)
  return res.data
}

export const getSingleCustomer = async (id) => {
  let res = await api.get(`user/${id}`)
  return res.data
}

export const saveCustomer = (data) =>  {
  return api.post('admin/customers', data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export const register = data => api.post('auth/register?client=website', data);

export const accountSetup = data => api.post('user/account-setup', data);

export const getBillProducts = code =>
  api.get(`bill-payment/bill-products?biller_code=${code}`);

export const validatePIN = data => api.post('user/validate-pin', data);

export const getLgas = state => api.get(`utilities/lgas?q=${state}`);

export const businessSetup = async data => api.post('/user/business', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  }
});

export const initiateBVN = data => api.post('user/initiate-bvn-consent', data);
export const verifyBVN = data => api.post('user/verify-bvn', data);

export const verifyBVNPin = data => api.post('user/verify-bvn-pin', data);

export const updatePin = data => api.post('settings/update-pin', data);

export const downloadGuarantorID = id => api.get(`admin/lease-application/download-guarantor/${id}`);

export const fetchFinancialPartners = async () => {
  let res = await api.get(`admin/finance-partners`);
  return res.data;
}
export const addPartner = (data) => api.post(`admin/finance-partners`, data)
export const updatePartner = (id, data) => api.post(`admin/finance-partners/${id}`, data)
export const deletePartner = (id) => api.delete(`admin/finance-partners/${id}`)