import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../core-units/utils";
import iconPlus from "../../../assets/icons/icon-plus-blue.svg"
import transferIcon from "../../../assets/icons/transfer.svg"
import savingsIcon from "../../../assets/icons/savings.svg"
import payBills from "../../../assets/icons/pay-bill.svg"
import greenSuc from "../../../assets/icons/greenSuc.svg"
import { Empty, Select } from "antd";
import useSWR from "swr";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const WalletHome = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [transactions, setTransactions] = useState([])
  const {data} = useSWR('dashboard');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) setTransactions(data?.data?.recent_transactions);

  }, [data]);

  return (
    <div className="md:p-10 p-5">
      <div className="md:flex">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Wallet 
        </h2>
      </div>
      <div className="mt-10 flex bg-white rounded-lg md:p-10">
        <div className="w-full px-5">
          <h3 className="mb-5">Overview</h3>
          <hr />
          <div className="bg-brand-primary text-white mt-10 rounded-lg wallet-balance w-full h-[150px]">
            <div className="flex p-10 justify-between">
              <div className="balance-wrapper">
                <span>Wallet Balance</span>
                <span className="balance-text">{formatNumber(user?.wallet?.balance)}</span>
              </div>
              <div className="add-funds-wrapper content-center">
                
                <button className="add-funds-btn flex md:text-center" onClick={() => dispatch({type: 'TOGGLE_FUNDS_MODAL'})}>
                  <img src={iconPlus} height={24} className="mr-1" />
                  Add Funds
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-5">
          <h3 className="mb-5">Quick Action</h3>
          <hr />
          <div className="flex justify-between mt-10 w-full">
            <div className="rounded-lg quick-links transfer">
              <img src={transferIcon} className="mb-5" />
              <span>Transfer</span>
            </div>
            <div className="rounded-lg quick-links">
              <img src={savingsIcon} className="mb-5" />
              <span>Savings</span>
            </div>
            <div onClick={() => navigate('/bill-payment')} className="rounded-lg quick-links">
              <img src={payBills} className="mb-5" />
              <span>Pay Bills</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 bg-white rounded-lg md:p-10">
        <div className="flex justify-between items-baseline content-center mb-5">
          <h3 className="font-normal content-center">Recent Transactions</h3>
          <Select 
            style={{width: '150px'}}
            className="customSelect mt-1"
            defaultValue={'week'}
            options={[
              {value: 'week', label: 'Current Week'},
              {value: 'month', label: 'Current Month'},
              {value: 'last_30', label: 'Last 30 days'},
            ]} 
          />
        </div>
        <hr />
        {transactions.map(transaction =>
          <div className="transaction-item py-3 px-5 items-center" key={transaction.id}>
            <div className="flex gap-3 items-center md:w-[40%]">
              <img src={greenSuc} style={{height: '16px'}} />
              <div className="flex flex-col">
                <span>{transaction.biller_name}</span>
                <small>{transaction.description}</small>
              </div>
            </div>
            <div className="items-center">{dayjs(transaction.created_at).format('DD/MM/YYYY')}</div>
            <div>{formatNumber(transaction.amount)}</div>
          </div>
        )}
        {transactions.length === 0 && <div className="p-3"><Empty /></div>}
      </div>
    </div>
  );
};

export default WalletHome;
