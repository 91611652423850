import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { BackButton } from '../../components/PayBills/BackButton';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { FLUTTERWAVE_KEY } from "../../../config";
import { formatNumber, formattedPhoneNumber } from '../../../core-units/utils';
import { createBillPayment, getTransaction, initializeTransaction, processCloudEnergy, processSteamaCo, validatePIN } from '../../../core-units/utils/queryFn';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Confirmation = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const {activeBill} = useSelector(state => state.billpayment);
    const {formData } = useSelector(state => state.billpayment);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [notice, contextHolder] = notification.useNotification();
    const [timer, setTimer] = useState(5);
    const [startTimer, setStartTimer] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [completed, setCompleted] = useState(null);
    const [loadingText, setLoadingText] = useState('...Verifying PIN');
    const dispatch = useDispatch();

    const openNotificationWithIcon = (type, data) => {
        notice[type]({
            message: data.message,
            description:data.description,
        });
    };

    const [pin, setPIN] = useState('');

    const [otpRef, ] = useState({
        otp1: useRef(),
        otp2: useRef(),
        otp3: useRef(),
        otp4: useRef(),
    });

    const [config, setConfig] = useState({
        public_key: FLUTTERWAVE_KEY,
        tx_ref: null,
        amount: parseFloat(formData?.amount),
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: user?.email,
            phone_number: user?.phone,
            name: user?.firstname,
        },
        // redirect_url: 'https://flutterwave.com'
    });
    const handlePayment = useFlutterwave(config);

    useEffect(() => {
        if (config.tx_ref) {
            handlePayment({
                callback: (response) => {
                    // console.log('card payment', response);
                    if (response.status === 'successful') {
                        setLoadingText('Processing Payment...')
                        closePaymentModal();
                        processPayment(config.tx_ref);
                    } else {
                        setLoading(false);
                        setLoadingText('');
                        openNotificationWithIcon("error", {message : 'Payment Error', description : response.message})
                    }
                    // closePaymentModal() // this will close the modal programmatically
                },
                onClose: () => {
                    setLoading(false);
                    setLoadingText('');
                    setConfig({...config, tx_ref: null});
                },
            });
        }
            
    }, [config]);

    useEffect(() => {
        if (startTimer && transaction) {
            const interval = setInterval(() => {
                if (timer === 0) {
                    verifyTransaction();
                    setTimer(5);
                } else {
                    setTimer(timer - 1);
                }
            }, 1000);
        
            return () => clearInterval(interval);
        }
    }, [transaction, startTimer, timer]);
    
    useEffect(() => {
        if (completed) {
            onCompleted(completed);
        }
    }, [completed]);

    useEffect(() => {
        if (otpRef.otp1.current) {
            otpRef.otp1.current.focus();
        }
    }, [otpRef.otp1]);

    const otpController = (val, next, prev, index) => {
        if (val.length < 1) {
            const code = pin.slice(0, -1);
            // setFielValue(field, code);
            setPIN(code);
            if (index === 0) {
                setPIN('');
                // setFielValue(field, '');
            } else {
                prev.current.focus();
            }
        } else if (next && val.length > 0) {
            const code = pin + val;
            // add value to code
            setPIN(code);
            // setFielValue(field, code);
            next.current.focus();
        } else {
            const code = pin + val;
            // add value to code
            setPIN(code);
            submitData(code);

            // setFielValue(field, code);
            return 0;
        }
    };

    const submitData = async val => {
        console.log('submitting data')
        // navigate(`/bill-payment/${activeBill?.slug}/success`)
        if (val.length === 4) {
          setLoading(true);
          await validatePIN({pin: val})
            .then(async res => {
                if (res.status) {
                    setLoadingText('Processing Payment...');
                    let resp;
                    switch (formData.bill_type) {
                        case 'cloudenergybill':
                            // setLoading(false);
                            resp = await createBillPayment({
                                amount: formData.amount,
                                account_reference: formData.account_reference,
                                phone: formattedPhoneNumber(formData.phone),
                                payment_type: 'cloudenergy'
                            });
                            break;
                        case 'steamaco':
                            // setLoading(false);
                            resp = await initializeTransaction();
                            break;
                        default:
                            formData.payment_type = 'flutterwave';
                            resp = await createBillPayment(formData);
                            break;
                    }
                    if (resp.status) {
                        if (formData.bill_type === 'cloudenergybill') {
                            dispatch({type: 'SET_BILL_FORM', payload: {...formData, internal_transaction_reference: resp.data.internal_transaction_reference}})
                            if (formData.payment_method === 'wallet') {
                                setLoadingText('Processing Payment...')
                                processPayment(resp.data.internal_transaction_reference);
                            } else {
                                setConfig({...config, tx_ref: resp.data.internal_transaction_reference});
                            }
                        } else if (formData.bill_type === 'steamaco') {
                            if (formData.payment_method === 'wallet') {
                                setLoadingText('Processing Payment...')
                                processPayment(resp.data);
                            } else {
                                setConfig({...config, tx_ref: resp.data});
                            }
                        } else {
                            setTransaction(resp.data);
                            setStartTimer(true);
                        }
                    } else {
                        setLoading(false)
                        openNotificationWithIcon("error", {message : 'Payment Error', description : resp.message})
                        otpRef.otp4.current.focus();
                    }
                    
                } else {
                    setLoading(false);
                    // setLoadingText('Confirm');
                    openNotificationWithIcon("error", {message : 'Validation Error', description : res.message})
                }
            })
            .catch(err => {
                setLoading(false);
                openNotificationWithIcon("error", {message : 'Internal Error', description : 'Something went wrong. Unable to process transaction.'})
            });
        }
    };


    const verifyTransaction = async () => {
        await getTransaction(transaction.transaction_id)
            .then(res => {
                const {data} = res;
                if (res.status) {
                    setLoading(false);
                    setStartTimer(false);
                    setCompleted(data);
                } else if (!res.status && data.status === 2) {
                    setLoading(false);
                    setStartTimer(false);
                    openNotificationWithIcon("error", {
                        message : 'Payment Error', 
                        description : res.message
                    })
                }
            })
            .catch(err => {
                console.log('verification error:', err.message);
                setStartTimer(false);
                setLoading(false);
                setStartTimer(false);
                setTimer(5);
                openNotificationWithIcon("error", {
                    message : 'Payment Error', 
                    description : 'Your transaction has been submitted is being processed. If your wallet does not funded after 30 minutes, please contact support.'
                })
            });
    };
    
    const onCompleted = data => {
        data.bill_type = formData.bill_type;
        data.provider = formData.provider;
        data.purpose = formData.purpose;
        data.receiver_name = formData.receiver_name;
        data.bank_name = formData.bank_name;
        data.bank_code = formData.bank_code;
    
        // account.balance = data.new_balance || data.balance;
    
        // dispatch(setAccount(account));
        // dispatch(updateTransactions(data.history || data));

        navigate(`/bill-payment/${activeBill?.slug}/success`)
    };

    const processPayment = async (reference) => {

        setLoadingText('Completing Payment...');
        try {
            let res;
            if (formData.bill_type === 'steamaco') {
                res = await processSteamaCo({
                    amount: formData.amount,
                    payment_method: formData.payment_method,
                    steamaco_id: formData.steamaco_id,
                    reference,
                })
            } else {
                res = await processCloudEnergy({
                    amount: formData.amount,
                    account_reference: formData.account_reference,
                    internal_transaction_reference: reference,
                    phone: formData.phone,
                    payment_option: formData.payment_method,
                })
            }

            if (res.status) {
                setTransaction(res.data);
                setStartTimer(true);
            } else {
                setLoading(false);
                openNotificationWithIcon("error", {message : 'Transaction Error', description : res.message})
            }
    
        } catch {
            setLoading(false);
            openNotificationWithIcon("error", {message : 'Transaction Error', description : 'Error processing payment'})
        }
    };
    
    
    
    return (
        <div className="md:p-10 p-5">
            {contextHolder}
      
            <div className="md:flex justify-between">
                <h2 className="md:text-[32px] text-[24px] mb-0 text-brand-primary">
                {activeBill?.name}
                </h2>
                <BackButton />
            </div>
            <div className="mt-10 bg-white rounded-md md:p-10">
                <div className="bill-wrapper">
                    <h1 className="text-brand-primary font-[500] text-center">Confirm Transaction</h1>
                    <ul className='bill-info'>
                        
                        <li className='flex justify-between'>
                            <span>Amount</span>
                            <span className='font-[600]'>{formatNumber(formData?.amount)}</span>
                        </li> 
                        {formData?.bill_type === 'airtime' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Network Provider</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {(formData?.bill_type === 'data_bundle' || formData?.bill_type === 'internet') && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Network Provider</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>{formData?.bill_type === 'data_bundle' ? 'Data Bundle' : 'Internet Plan'}</span>
                                <span className='font-[600]'>{formData?.biller_name}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'power' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Biller</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Account Number/User ID</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'cables' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Biller</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Package</span>
                                <span className='font-[600]'>{formData?.biller_name}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>IUC Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}


                        {formData?.bill_type === 'cloudenergybill' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Account Reference</span>
                                <span className='font-[600]'>{formData?.account_reference}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.phone}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'steamaco' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>SteamaCo ID</span>
                                <span className='font-[600]'>{formData?.steamaco_id}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Customer Name</span>
                                <span className='font-[600]'>{formData?.account_name}</span>
                            </li> 
                        </>)}

                    </ul>
                    <div className='flex justify-between'>
                        <input 
                            type='number' 
                            maxLength={1} 
                            className='pin-input' 
                            disabled={loading}
                            ref={otpRef.otp1}
                            onChange={(e) => otpController(e.target.value, otpRef.otp2, '', 0)}
                        />
                        <input 
                            type='number' 
                            maxLength={1} 
                            className='pin-input' 
                            ref={otpRef.otp2}
                            disabled={loading}
                            onChange={(e) => otpController(e.target.value, otpRef.otp3, otpRef.otp1, 1)}
                        />
                        <input 
                            type='number' 
                            maxLength={1} 
                            className='pin-input' 
                            ref={otpRef.otp3}
                            disabled={loading}
                            onChange={(e) => otpController(e.target.value, otpRef.otp4, otpRef.otp2, 2)}
                        />
                        <input 
                            type='number' 
                            maxLength={1} 
                            className='pin-input' 
                            ref={otpRef.otp4}
                            disabled={loading}
                            onChange={(e) => otpController(e.target.value, '', otpRef.otp3, 3)}
                        />
                    </div>
                    <div className="mt-5">
                        <button 
                            type="button" 
                            onClick={() => submitData(pin)} 
                            className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                            {loading ? (
                                <span className="text-white">
                                    {" "}
                                    <Spin className='text-white mr-2' indicator={<LoadingOutlined spin />} /> {loadingText}
                                </span>
                                ) : (
                                <span className="text-white">CONFIRM</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}