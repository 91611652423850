import React, { useState } from "react";
import { Button, Modal, Spin, Table, Tooltip, notification} from "antd";
import deleteIcon from "../../../assets/icons/del.svg";
import editIcon from "../../../assets/icons/edit.svg";
import dayjs from "dayjs";
import CircleClose from "../../../assets/icons/circleClose.svg";
import { deletePartner } from "../../../core-units/utils/queryFn";
import iconInfo from "../../../assets/icons/infoBlue.svg";
import { LoadingOutlined } from "@ant-design/icons";


const PartnersTable = ({ data, onEdit, onPageChange, perPage, total, page, onRefresh }) => {
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [item, setItem] = useState(null);

  const [notice, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, data) => {
      notice[type]({
        message: data.message,
        description: data.description,
      });
    };

  const doDelete = async () => {
    setLoading(true);
    await deletePartner(item.id).then(res => {
      setLoading(false);
      if (res.status) {
        setConfirmDelete(false);
        setItem(null);
        onRefresh()
      }else {
        openNotificationWithIcon("error", {message : 'Error', description : res.message})
      }
    }).catch(err => {
      setLoading(false);
      openNotificationWithIcon("error", {message : 'Error', description : err.data.message})
    })
   
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    
    },
   
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "date",
      render: (record) => <span>{dayjs(record?.created_at).format("DD/MM/YYYY")}</span>,
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="flex gap-4 w-full">
           <Tooltip title="Edit">
            <img
              src={editIcon}
              alt=""
              className="cursor-pointer"
              onClick={() => onEdit(record)}
            />
           </Tooltip>
          <Tooltip title="Delete">
            <img
              src={deleteIcon}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setItem(record)
                setConfirmDelete(true)}}
            />
          </Tooltip>
        </div>
      ),
    },
  ];


  return (
    <div className="h-[50vh] overflow-auto">
      <Modal
        footer={null}
        open={confirmDelete}
        onCancel={() => {
          setConfirmDelete(false);
          setItem(null);
        }}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className="flex h-full w-full items-center justify-center p-10">
          {contextHolder}
          <div>
            <img src={iconInfo} alt="" className=" mx-auto" />
            <p className="mt-8">Are you sure you want to delete this partner?</p>
            <div className="flex justify-around w-full mt-10">
              <Button
                onClick={doDelete}
                type="text"
                className=" uppercase bg-brand-primary font-bold "
              >
                {loading ? (
                  <span className="text-white">
                    {" "}
                    <Spin indicator={<LoadingOutlined spin />} /> Deleting...
                  </span>
                ) : (
                  <span className="text-white">Yes</span>
                )}
              </Button>
              <Button
                onClick={() => setConfirmDelete(false)}
                type="text"
                className=" uppercase bg-brand-secondary font-bold "
              >
                <span className="text-white">No, Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ 
          defaultPageSize: perPage, 
          total,
          current: page, 
          // pageSizeOptions: ['10', '20', '30', '50', '100']
          onChange: (page, pageSize) => {
            onPageChange(page);
          },
        }}
        sticky={true}
      />
    </div>
  );
};

export default PartnersTable;
