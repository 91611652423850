import React from 'react'
import download from '../../../assets/icons/download.svg'
import downloadW from '../../../assets/icons/downloadWhite.svg'
import {useParams} from 'react-router-dom'
import logo from '../../../assets/images/logo.svg'
import {useQuery} from '@tanstack/react-query'
import { downloadReciept, getSingleTransaction } from '../../../core-units/utils/queryFn'
import {Skeleton} from 'antd'
import {maskNumber } from '../../../core-units/utils'
import success from '../../../assets/icons/success.svg'
import failed from '../../../assets/icons/failed.svg'



const SingleTransaction = () => {
  const {id} = useParams()
  const transaction = useQuery({ queryKey: ['transactions', id], queryFn: () => getSingleTransaction(id), retry : false })

  return (
    <div className='md:p-10 p-5'>
        <div className="flex">
            <h2 className="md:text-[32px] text-[24px] text-brand-primary">View Transaction</h2>
        </div>
        <div className="  md:my-10 rounded-[10px] md:py-8 py-0 px-0 xl:px-14 md:px-8 bg-white">
          <div className="md:flex hidden">
            <p className="text-lg mb-5">Transactions {id} </p>
            <div className="flex  ml-auto gap-5">
              <div className="flex gap-2 border mb- py-3 px-3 rounded-lg cursor-pointer hover:bg-black" onClick={() => downloadReciept(transaction.data.id)} >
                <img src={download} alt="" />
                <span className="mt-0.5 text-[#9B9898]">PDF</span>
              </div>
              {/* <div className="flex gap-2 border py-3 px-3 rounded-lg cursor-pointer hover:bg-black">
                <img src={download} alt="" />
                <span className="mt-0.5 text-[#9B9898]">Excel </span>
              </div> */}
            </div>
          </div>
          <div className="mt-8">
            <div className="md:border  md:p-8 ">
                <div className="md:grid grid-cols-3 gap-8">
                    <div className="w-full bg-brand-primary flex md:items-center md:justify-center rounded-t-md md:rounded-t-[0]">
                      {transaction.isLoading ? <div className='mt-5'>
                            <Skeleton active/>
                        </div> : <>
                        {transaction.data?.status === 1 ? <div className='hidden md:block'>
                        <img src={success} alt="" className='mx-auto' />
                        <p className="text-white mt-2 text-center">Transaction Successful</p>
                        <p className="text-white mt-2 mb-0 text-center  text-3xl">{transaction.data.amount}</p>
                      </div> : <div className='hidden md:block'>
                        <img src={failed} alt="" className='mx-auto' />
                        <p className="text-white mt-2 text-center">Transaction Failed</p>
                        <p className="text-white mt-2 mb-0 text-center  text-3xl">{transaction.data?.amount}</p>
                      </div>}
                      {transaction.data?.status === 1 ? <div className='md:hidden flex p-3 gap-4'>
                        <img src={success} alt="" className='w-[20%]' />
                        <div className="">
                          <p className="text-white mb-0 text-2xl font-bold">{transaction.data?.amount}</p>
                          <p className="text-white mt-1 mb-0">Transaction Successful</p>
                        </div>
                        <img src={downloadW} alt="" className='ml-3 w-5' onClick={() => downloadReciept(transaction.data.id)}  />
                      </div> : <div className='hidden md:block'>
                        <img src={failed} alt="" className='mx-auto' />
                        <p className="text-white mt-2 font-bold">Transaction Failed</p>
                        <p className="text-white mt-2 mb-0 text-center  text-3xl">{transaction.data?.amount}</p>
                      </div>}
                        </>}
                     
                    </div>
                    <div className="w-full py-1 bg-[#B3D5F0]  my-0 md:hidden">
                      <h4 className="text-center w-full">PURPOSE: {transaction.data?.description}</h4>
                  </div>
                    <div className="col-span-2">
                        <img src={logo} alt="" className='md:block hidden' />
                        {transaction.isLoading && <div className='mt-5'>
                            <Skeleton active/>
                        </div>}
                        {transaction.data && 
                        <div className="md:grid grid-cols-2  gap-5 bg-[#ECF3FF] md:bg-white pt-4 px-4 md:px-0 md:pt-4">
                            <div className=''>
                                <p className="uppercase font-bold">BENEFICIARY</p> 
                                {/* data_bundle */}
                                {transaction.data.bill_type === 'airtime' || transaction.data.bill_type === 'data_bundle' ? <p className="border-b py-3">{transaction.data?.biller_name }</p> :  <p className="border-b py-3">{transaction.data?.recipient.user.firstname + " " +transaction.data.recipient.user.lastname }</p>}
                                {transaction.data.bill_type === 'airtime' || transaction.data.bill_type === 'data_bundle' ? <p className="border-b py-3">{transaction.data?.customer }</p> : <p className="border-b py-3">{maskNumber(transaction.data?.recipient?.account_no)}</p>}
                                <p className=" py-3">{transaction.data?.recipient?.bank_name}</p>
                            </div>
                            <div className=''>
                                <p className="uppercase font-bold">SENDER</p>
                                <p className="border-b py-3">{transaction.data.user.firstname + " " + transaction.data.user.lastname}</p>
                                <p className="border-b py-3">{maskNumber(transaction.data?.user.wallet.account_no)}</p>
                                <p className=" py-3">{transaction.data?.user.wallet.bank_name}</p>
                            </div>
                        </div>}
                    </div>
                </div>
            <div className="w-full py-2 bg-gray-200 my-5 hidden md:block">
                <h4 className="text-center w-full">PURPOSE: {transaction.data?.description}</h4>
            </div>
            <h4 className="font-semibold mb-3 mt-7 md:mt-0">Important Information</h4>
            <ul>
                <li className='list-item list-disc list-inside mt-2'>This is an online auto generated transaction receipt.</li>
                <li className='list-item list-disc list-inside mt-2'>This is an authentic receipt, for further inquiries, </li>
                <li className='list-item list-disc list-inside mt-2'>Contact us @: 0700 123 4567 | customer@cloudpay.biz </li>
            </ul>
            </div>
          </div>
        </div>
    </div>
  )
}

export default SingleTransaction