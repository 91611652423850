import { useFormik } from "formik";
import {useDispatch, useSelector} from 'react-redux';
import { InputField } from "../Inputs/Input";
import { useState } from "react";
import * as Yup from 'yup';
import { Col, Row, Select } from "antd";
import { useNavigate } from "react-router-dom";

export const CloudEnergyForm = ({bill}) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

  const dispatch = useDispatch();
  const {formData} = useSelector(state => state.billpayment);

  const formik = useFormik({
    initialValues: {
        country: bill?.country,
        account_reference: '',
        amount: formData?.amount || '',
        phone: formData?.phone || '',
        provider: 'CloudEnergy',
        bill_type: 'cloudenergybill',
        network: 'CloudEnergy',
        payment_method: ''
    },
    validationSchema: Yup.object({
        amount: Yup.number().min(3).required('An amount is required'),
        account_reference: Yup.string().required('This field is required'),
        phone: Yup.number().required('Enter a valid phone number'),
    }),
    onSubmit: (values, helpers) => {
        dispatch({type: 'SET_BILL_FORM', payload: values});
        navigate(`/bill-payment/${bill?.slug}/preview-confirm`)
    },
  });

  const handleChange = (field, value) => {
    formik.setFieldValue([field], value);
    // dispatch(fieldChangeBillPayment(formik.values));
  };


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>Account Reference</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='account_reference' 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    value={formik.values.account_reference} 
                />
                <span className="text-red-500"> {formik.errors.account_reference && formik.touched.account_reference && formik.errors.account_reference}</span>   
            </div>
            
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>Amount</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='amount' 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    value={formik.values.amount} 
                />
                <span className="text-red-500"> {formik.errors.amount && formik.touched.amount && formik.errors.amount}</span>   
            </div>
           
            <Row>
                <Col span={11} className="mr-8">
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Phone Number</span>
                        <InputField 
                            size='large' 
                            className='mt-1' 
                            name='phone' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.phone} 
                        />
                        <span className="text-red-500"> {formik.errors.phone && formik.touched.phone}</span>   
                    </div>
                </Col>
                <Col span={11}>
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Payment Type</span>
                        <Select 
                            size="large"
                            className="customSelect mt-1"
                            options={[
                                { label: 'Wallet', value: 'wallet'},
                                { label: 'Card', value: 'card' }
                            ]} 
                            onChange={(value) => handleChange('payment_method', value)}
                        />
                        <span className="text-red-500"> {formik.errors.payment_method && formik.touched.payment_method}</span>   
                    </div>    
                </Col>
            </Row>
            <div className="mt-5">
                <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                    PROCEED
                </button>
            </div>
        </form>
    )
}