import React from "react";
import { Button, Col, Empty, Row, Spin, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber } from "../../../core-units/utils";
import { useQuery } from "@tanstack/react-query";
import { getPaymentSchedule, singleLeaseProduct } from "../../../core-units/utils/queryFn";
import { useState } from "react";
import myLease from "../../../assets/icons/my-lease.svg";
import greenSuc from "../../../assets/icons/greenSuc.svg"

import dayjs from "dayjs";
import { PaymentSchedule } from "./PaymenSchedule";

const View = () => {
  //   const navigate = useNavigate();
  const [notice, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, data) => {
    notice[type]({
      message: data.message,
      description: data.description,
    });
  };
  const navigate = useNavigate();


  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
 
  const { id } = useParams();

  const product = useQuery({
    queryKey: ["leaseProduct", id],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => singleLeaseProduct(id),
  });

  const schedules = useQuery({
    queryKey: ["paymentSchedules", id],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => getPaymentSchedule(id),
  });
  
  const addL = () => {
    navigate("/lease/me");
  };

  console.log(schedules);

  return (
    <div className="md:p-10 p-5">
      {contextHolder}

      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          View Application
        </h2>
        <div className="flex gap-5">
          <Button
            className="h-full w-[250px] bg-brand-secondary border-0  flex w-full justify-center items-center  gap-3 "
            size="large"
            onClick={addL}
          >
            <img src={myLease} alt="" />
            <span className="text-white ">My Lease</span>
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-lg md:p-16 mt-5">
        {product.data && (
          <div>
            <div className="md:flex gap-5 xl:gap-24">
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <div className="h-[195px] w-full overflow-x-auto  rounded-t-md">
                  <img
                    src={product.data.product?.image_url}
                    className="w-full h-full"
                    alt=""
                  />
                </div>
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <h5 className="font-bold text-lg text-brand-primary">
                  {product.data.product?.product_name}
                </h5>
                <div className="rounded-[10px] border">
                  <div className="p-3 border-b cursor-pointer">
                    <div className="flex mt-2 gap-3 justify-between">
                      <div className="flex gap-2">
                        <span className=" text-brand-base">
                          Amount
                        </span>
                      </div>
                      <div className="flex gap-2">
                        <span className=" text-brand-base font-bold">
                          {formatNumber(product.data.product?.amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 border-b  cursor-pointer">
                    <div className="flex mt-2 gap-3 justify-between">
                      <div className="flex gap-2">
                        <span className=" text-brand-base"> Duration</span>
                      </div>
                      <div className="flex gap-2">
                        <span className=" text-brand-base font-bold">
                          {product.data.product?.lease_duration} Month(s){" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 border-b  cursor-pointer">
                    <div className="flex mt-2 gap-3 justify-between">
                      <div className="flex gap-2">
                        <span className=" text-brand-base"> Date Applied</span>
                      </div>
                      <div className="flex gap-2">
                        <span className=" text-brand-base font-bold">
                          {dayjs(product.data.application_date).format(
                            "DD/MM/YYYY - HH:MM a"
                          )}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 cursor-pointer">
                    <div className="flex mt-2 gap-3 justify-between">
                      <div className="flex gap-2">
                        <span className=" text-brand-base"> Status</span>
                      </div>
                      <div className="flex gap-2">
                      {product.data.status === 0 &&  <span className=" text-brand-secondary font-bold">Pending</span>}
                      {product.data.status === 1 &&  <span className=" text-brand-success font-bold">Approved</span>}
                      {product.data.status === 2 &&  <span className=" text-brand-danger font-bold">Rejected</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gap-5" />
            <Row className='w-full mt-5' style={{borderBottomWidth: 3, borderColor: '#2f8fcd'}}>
              <Col onClick={() => setActiveTab(1)} className={`account-setup-tabs ${activeTab === 1 ? 'active' : ''}`}>About Lease</Col>
              <Col onClick={() => setActiveTab(2)} className={`account-setup-tabs ${activeTab === 2 ? 'active' : ''}`}>Payment Schedule</Col>
            </Row>
            <div className="w-full mt-5 border-4 rounded-[6px]">
                {activeTab === 1 ?
                  <div className="p-3">{product?.data?.product?.description}</div>
                :                  
                  schedules?.data.length === 0 ? <div className="p-3"><Empty /></div> : <PaymentSchedule schedules={schedules?.data} />                  
                }
            </div>
          </div>
        )}
        {product.isLoading && (
          <div className="flex h-full mt- items-center  justify-center">
            {" "}
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
