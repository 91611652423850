import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display : false
    }
  },
};

// export const data = {
//   labels: ['Successful', 'Failed'],
//   datasets: [
//     {
//       label: 'Transactions',
//       data: [40, 19],
//       backgroundColor: [
//         '#2F8FCD',
//         '#F79637',
//       ],
//     },
//   ],
// };

export function PieChart({data}) {
  const _data = {
    labels: ['Successful', 'Failed'],
    datasets: [
      {
        label: 'Transactions',
        data: [data.success, data.failed],
        backgroundColor: [
          '#2F8FCD',
          '#F79637',
        ],
      },
    ],
  }
  return <Pie data={_data} options={options} />;
}
