import React from "react";
import { Button, Input, Spin, Empty } from "antd";
import search from "../../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllLease } from "../../../core-units/utils/queryFn";
import calender from "../../../assets/icons/calender.svg";
import plane from "../../../assets/icons/plain.svg";
import myLease from "../../../assets/icons/my-lease.svg";
import { formatNumber } from "../../../core-units/utils";

const LeaseItem = ({ data, navigate }) => {
  return (
    <div className=" rounded-md w-full" onClick={()=>{navigate(`/lease/${data.id}/view`)}}>
      <div className="h-[175px] w-full overflow-x-auto bg-none rounded-t-lg">
        <img src={data.image_url} alt="" className="w-full" />
      </div>
      <div className="bg-white p-3 cursor-pointer">
        <div className="flex justify-between">
          <div>
            <h3 className="font-bold text-brand-primary">{data.product_name}</h3>
            <div className="flex gap-2">
              <img src={calender} alt="" />
              <span className=" text-brand-base">{data.lease_duration} Months</span>
            </div>
          </div>
          <div>
            <button className="bg-brand-primary p-2 px-5 rounded-lg">
              <img src={plane} />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#DFF0D6] font-[500] text-brand-primary text-center p-3 rounded-b-lg">
          {formatNumber(data.amount)}
      </div>
    </div>
  );
};

const UserLease = () => {
  const navigate = useNavigate();

  const addL = () => {
    navigate("/lease/me");
  };
  
  const allLease = useQuery({
    queryKey: ["allLease"],
    queryFn: getAllLease,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="md:p-10 p-5">
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Lease 
        </h2>
        <div className="flex md:w-[70%] gap-5">
          <Input className="w-full" placeholder="Search for Lease Items" />
          <Button
            className="h-full bg-brand-primary border-0  "
            size="large"
          >
            <img src={search} alt="" className="w-[50px]" />
          </Button>
          <Button
            className="h-full w-[250px] bg-brand-secondary border-0  flex w-full justify-center items-center  gap-3 "
            size="large"
            onClick={addL}
          >
            <img src={myLease} alt="" />
            <span className="text-white ">My Lease</span>
          </Button>
        </div>
      </div>
      {!allLease.isLoading && !allLease.data?.data && (
        <div className="mt-40">
          <Empty description={<span>No Lease Product Added</span>}>
            <Button
              onClick={() => navigate(-1)}
              className="h-full mt-1 bg-brand-secondary border-0  flex mx-auto justify-center items-center  gap-3 "
              size="large"
            >
              <span className="text-white ">Go Back</span>
            </Button>
          </Empty>
        </div>
      )}
      {allLease.isLoading && (
        <div className="flex h-full mt-40 items-center  justify-center">
          {" "}
          <Spin size="large" />
        </div>
      )}
      {allLease.data && (
        <div className="mt-10 grid md:grid-cols-3  gap-4">
          {allLease.data.data.map((i) => (
            <LeaseItem data={i} key={i.id} navigate={navigate} />
          ))}
        </div>
      )}
    </div>
  );
};

export default UserLease;
