import React, { useState } from "react";
import { Table, Modal, Button , Spin, Tooltip} from "antd";
import eye from "../../../assets/icons/eye.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import del from "../../../assets/icons/delBlack.svg";
import CircleClose from "../../../assets/icons/circleClose.svg";
import iconInfo from "../../../assets/icons/infoBlue.svg";
import {  LoadingOutlined } from "@ant-design/icons";
import api from "../../../core-units/api";


const TableComp = ({ data, state }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [lease, setLease] = useState()
  const navigate = useNavigate();
  const view = (rec) => {
    navigate(`/lease/${rec.id}/application`);
  };

  const doDelete = () => {
    console.log(lease)
    setLoading(true)
    api.delete(`/admin/lease-application/${lease.id}`).then(res => {
      console.log(res.data)
      setLoading(false)
      handleCancel()

    }).catch(err=>{
      console.log(err) 
      setLoading(false)
      handleCancel()
    })
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "application_date",
      key: "date",
      render: (date) => <span>{dayjs(date).format("DD/MM/YYYY")}</span>,
    },
    {
      title: "Name",
      key: "bill_type",
      render: (record) => (
        <>
          <span>
            {record?.user?.firstname} {record?.user?.lastname}
          </span>
        </>
      ),
    },
    {
      title: "Product",
      key: "from",
      render: (record) => (
        <>
          <span>{record?.product?.product_name}</span>
        </>
      ),
    },
    {
      title: "Amount",
      key: "amount",
      render: (record) => (
        <>
          <span>{record?.product?.amount}</span>{" "}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <>
          {record === 0 && <span className="text-[#F79637]">Pending</span>}
          {record === 1 && <span className="text-[#00B224]">Approved</span>}
          {record === 2 && <span className="text-[#EE3539]">Rejected</span>}
        </>
      ),
    },
    {
      title: "Action",
      render: (record) => (
        <div className="flex gap-4 w-full">
          <Tooltip title="View Details">
            <img
              src={eye}
              alt=""
              className="cursor-pointer"
              onClick={() => view(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <img
              src={del}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setLease(record)
                setIsModalOpen(true)}}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleCancel = () => {
    state.refetch()
    // window.location.reload()
    setIsModalOpen(false);
  };
  return (
    <div className="h-[50vh] overflow-auto">
      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className="flex h-full w-full items-center justify-center p-10">
          <div>
            <img src={iconInfo} alt="" className=" mx-auto" />
            <p className="mt-8">Are you sure you want to delete this lease</p>
            <div className="flex justify-around w-full mt-10">
              <Button
                onClick={doDelete}
                type="text"
                className=" uppercase bg-brand-primary font-bold "
              >
                {loading ? (
                  <span className="text-white">
                    {" "}
                    <Spin indicator={<LoadingOutlined spin />} /> LOADING...
                  </span>
                ) : (
                  <span className="text-white">CONTINUE</span>
                )}
              </Button>
              <Button
                onClick={handleCancel}
                type="text"
                className=" uppercase bg-brand-secondary font-bold "
              >
                <span className="text-white">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        sticky={true}
      />
    </div>
  );
};

export default TableComp;
