import { Button, Modal, Steps } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import success from "../../../assets/icons/greenSuc.svg";
import { useNavigate, useParams } from "react-router-dom";
import { SolutionOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { PersonalInformation } from "../../components/Forms/PersonalInformation";
import { BusinessInformation } from "../../components/Forms/BusinessInformation";
import { Guarantors } from "../Lease/Guarantors";
import { getSingleCustomer } from "../../../core-units/utils/queryFn";
import { BvnNinInfo } from "../../components/Forms/BVNInformation";
import { BackButton } from "../../components/PayBills/BackButton";

const ViewDetails = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false)
  const { id } = useParams();
  
  const next = (values) => {
    setCurrent(current + 1);
    setFormData(values);
  };

  const prev = (values) => {
    setCurrent(current - 1);
    setFormData(values);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const steps = [
    {
      title: 'Basic Information',
      // description: 'Fill in customer personal informations',
      icon: <UserOutlined />,
    },
    {
      title: 'Business Information',
      // description: 'Provide customer\'s business informations',
      icon: <SolutionOutlined />,
    },
    {
      title: 'BVN & NIN',
      icon: <UsergroupAddOutlined />
    },
  ];

  useEffect(() => {
    async function getDetails () {
      await getSingleCustomer(id)
        .then(res => {
          setFormData(res)
        })
    }

    getDetails();

  }, [id])


  return (
    <div className="md:p-10 p-5">
      <Modal
        centered
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <div className="md:py-16 py-8 ">
          <img src={success} alt="" className="w-[20%] mx-auto" />
          <p className="text-center mt-5">Customer On-Boarding is Successfull</p>
          <Button
            // onClick={cont}
            type="text"
            className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
          >
            <span className="text-white uppercase font-bold">Close</span>
          </Button>
        </div>
      </Modal>
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          User Details
        </h2>
        <BackButton />

      </div>
      <div className="mt-5 bg-white rounded-lg md:p-10">
        <div>
          <Steps current={current} items={steps} />
          {current === 0 && <PersonalInformation next={next} data={formData} />}
          {current === 1 && <BusinessInformation next={next} prev={prev} data={formData} />}
          {current === 2 && <BvnNinInfo 
            next={next} 
            prev={prev} 
            data={formData}
            onComplete={() => {
              setIsModalOpen(true);
              setCurrent(0);
              setFormData({})
            }} />}
          
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
