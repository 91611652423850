import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import { Formik } from "formik";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { InputField } from "../Inputs/Input";
import { getLgas } from "../../../core-units/utils/queryFn";

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const PersonalInformation = ({next, data}) => {
  const [userTypes, setUserTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const {data: statesData, error} = useSWR('utilities/states?q=161');
  const {data: empStatusData} = useSWR('utilities/employment-status');
  const {data: userTypeData} = useSWR('user-types');

  useEffect(() => {
    if (statesData && statesData.status) {
      setStates(statesData.data.map(item => ({value: item.id, label: item.name})));
    }
  }, [statesData]);

  useEffect(() => {
      if (userTypeData && userTypeData.status) {
        setUserTypes(userTypeData.data.map(item => ({value: item.id, label: item.name})));
      }
  }, [userTypeData]);

  useEffect(() => {
      if (empStatusData && empStatusData.status) {
        setStatuses(empStatusData.data.map(item => ({value: item.id, label: item.name})));
      }
  }, [empStatusData]);

  useEffect(() => {
      if (data && data?.profile?.state_id && lgas.length < 1)
          fetchLgas(data.profile.state_id);

  }, [data]);

  const fetchLgas = async state_id => {
      await getLgas(state_id).then(res => {
          if (res.status) {
              setLgas(res.data.map(item => ({value: item.id, label: item.name})));
          }
      });
  };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstname: data?.firstname || '',
          lastname: data?.lastname || '',
          phone: data?.phone || '',
          email: data?.email || '',
          address: data?.profile?.contact_address || '',
          state: data?.profile?.state_id || '',
          state_name: data?.profile?.state?.name || '',
          lga: data?.profile?.lga_id || '',
          lga_name: data?.profile?.lga?.name || '',
          dob: data?.profile?.dob || '',
          employment_status: data?.profile?.employment_status_id || '',
          employment_status_name:  '',
          avg_monthly_salary: data?.profile?.avg_monthly_salary || '',
          user_type: data?.user_type || '',
          user_type_name: data?.user_type_name || '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstname) {
            errors.firstname = "A first name is Required";
          }
          if (!values.lastname) {
            errors.lastname = "A last name is required";
          }
          if (!values.phone) {
            errors.phone = "Provide a phone number";
          }
          if (!values.email) {
            errors.email = "Provide an email address";
          }
          
          return errors;
        }}
        onSubmit={(values) => {
            const newData = {...data}; 
            next({
                ...newData, 
                firstname: values?.firstname,
                lastname: values?.lastname,
                phone: values?.phone,
                email: values?.email,
                nok: values?.nok,
                nok_phone: values?.nok_phone,
                proof_of_address: values?.proof_of_address,
                bvn_check: values?.bvn_check,
                nin: values?.nin,
                nin_check: values?.nin_check,
                residential_address: values?.residential_address,
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="py-10">
            <Row type="flex" align="middle">
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>First Name</span>
                  <InputField size="large" className="mt-1" name="firstname" onChange={handleChange} onBlur={handleBlur} value={values.firstname} />
                  <span className="text-red-500"> {touched.firstname && errors.firstname}</span>   
              </Col>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>Last Name</span>
                  <InputField size="large" className="mt-1" name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname} />
                  <span className="text-red-500"> {errors.lastname && touched.lastname}</span>   
              </Col>
            </Row>
            
            <Row className='mt-10'>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>Phone</span>
                  <InputField size="large" className="mt-1" name="phone" onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                  <span className="text-red-500"> {touched.phone && errors.phone}</span>   
              </Col>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>Email</span>
                  <InputField size="large" className="mt-1" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                  <span className="text-red-500"> {errors.email && touched.email}</span>   
              </Col>
            </Row>
            
            <Row className='mt-10'>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>Contact Address</span>
                  <InputField size="large" className="mt-1" name="address" onChange={handleChange} onBlur={handleBlur} value={values.address} />
                  <span className="text-red-500"> {errors.address && touched.address && errors.address}</span>   
              </Col>
              <Col span={11}>
                  <span className='text-[#AEB5C2]'>State of Origin</span>
                  <Select 
                      size="large"
                      showSearch
                      className="customSelect mt-1"
                      options={states} 
                      value={values?.state}
                      filterOption={filterOption}
                      onChange={(value) => {
                          const selectedItem = states.find(item => item.value === value)
                          fetchLgas(value);
                          setFieldValue('state', value);
                          setFieldValue('state_name', selectedItem.label);
                      }}
                  />
                  <span className="text-red-500"> {errors.state && touched.state && errors.state}</span>   
              </Col>
          </Row>
          <Row className='mt-10'>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>LGA</span>
                  <Select 
                      size="large"
                      showSearch
                      filterOption={filterOption}
                      className="customSelect mt-1"
                      options={lgas} 
                      value={values?.lga}
                      onChange={(value) => {
                          const selectedItem = lgas.find(item => item.value === value)
                          setFieldValue('lga', value);
                          setFieldValue('lga_name', selectedItem.lable);
                      }}
                  />
                  <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
              </Col>
              <Col span={11}>
                  <span className='text-[#AEB5C2]'>Date of Birth</span>
                  <InputField 
                    type="date" 
                    size="large" 
                    className="mt-1" 
                    name="dob" 
                    onChange={(date) => setFieldValue('dob', date)} 
                    value={values.dob} 
                  />
                  <span className="text-red-500"> {errors.dob && touched.dob && errors.dob}</span>   
              </Col>
          </Row>
          <Row className='mt-10'>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>Employment Status</span>
                  <Select 
                      size="large"
                      showSearch
                      filterOption={filterOption}
                      className="customSelect mt-1"
                      options={statuses} 
                      value={values?.employment_status}
                      onChange={(value) => {
                          const selectedItem = statuses.find(item => item.value === value)
                          setFieldValue('employment_status', value);
                          setFieldValue('employment_status_name', selectedItem.lable);
                      }}
                  />
                  <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
              </Col>
              <Col span={11}>
                  <span className='text-[#AEB5C2]'>Average Monthly Income</span>
                  <InputField type="number" size='large' className='mt-1' name='avg_monthly_salary' onChange={handleChange} onBlur={handleBlur} value={values.avg_monthly_salary} />
                  <span className="text-red-500"> {errors.avg_monthly_salary && touched.avg_monthly_salary}</span>   
              </Col>
          </Row>
          <Row className='mt-10'>
              <Col span={11} className='mr-10'>
                  <span className='text-[#AEB5C2]'>User Type</span>
                  <Select 
                      size="large"
                      showSearch
                      filterOption={filterOption}
                      className="customSelect mt-1"
                      options={userTypes} 
                      defaultValue={values?.user_type}
                      onChange={(value) => {
                          setFieldValue('user_type', value);
                      }}
                  />
                  <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
              </Col>
              <Col span={11}>
                  <span className='text-[#AEB5C2]'>Partner Code</span>
                  <InputField size='large' className='mt-1' name='partner_code' onChange={handleChange} onBlur={handleBlur} value={values.partner_code} />
                  <span className="text-red-500"> {errors.partner_code && touched.partner_coded}</span>   
              </Col>
          </Row>
            <div className="md:flex gap-10 md:mt-10 mt-5">
              <div className="md:w-1/2">
                
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="submit"
                  className="mt-8 h-10 flex justify-center items-center rounded-md text-white text-center bg-brand-primary w-full"
                >
                    <span className="text-white uppercase font-bold">
                      Next
                    </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }