import { useFormik } from "formik";
import {useDispatch, useSelector} from 'react-redux';
import { InputField } from "../Inputs/Input";
import { useState } from "react";
import * as Yup from 'yup';
import { Col, Row, Select, notification } from "antd";
import { validateSteamaCoCustomer } from "../../../core-units/utils/queryFn";
import { useNavigate } from "react-router-dom";
import { LoaderComponent } from "../Loader/Loader";

export const SteamaCoForm = ({bill}) => {
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notice, contextHolder] = notification.useNotification();

    const dispatch = useDispatch();
    const {formData} = useSelector(state => state.billpayment);
    const navigate = useNavigate();

    const openNotificationWithIcon = (type, data) => {
        notice[type]({
            message: data.message,
            description:data.description,
        });
    };

    const formik = useFormik({
        initialValues: {
            country: bill?.country,
            reference: '',
            amount: formData?.amount || '',
            steamaco_id: formData?.steamaco_id || '',
            account_name: '',
            provider: 'SteamaCo',
            bill_type: 'steamaco',
            network: 'SteamaCo',
        },
        validationSchema: Yup.object({
            amount: Yup.number().min(3).required('An amount is required'),
            steamaco_id: Yup.string().required('This field is required'),
        }),
        onSubmit: (values, helpers) => {
            dispatch({type: 'SET_BILL_FORM', payload: values});
            navigate(`/bill-payment/${bill?.slug}/preview-confirm`)
        },
    });

    const doVerify = async () => {
        setLoading(true);
        await validateSteamaCoCustomer(formik.values.steamaco_id)
            .then(res => {
                setLoading(false);
                if (res.status) {
                    const {first_name, last_name} = res.data;
                    formik.setFieldValue('account_name', first_name + ' ' + last_name);
                    setVerified(true);
                } else {
                    setVerified(true);
                    openNotificationWithIcon("error", {message : 'Payment Error', description : res.message})
                }
            })
            .catch(err => setLoading(false) | console.log(err));
    };

    const handleChange = (field, value) => {
        formik.setFieldValue([field], value);
        // dispatch(fieldChangeBillPayment(formik.values));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>SteamaCo ID</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='steamaco_id' 
                    onChange={formik.handleChange} 
                    onBlur={doVerify} 
                    value={formik.values.steamaco_id} 
                />
                <span className="text-red-500"> {formik.errors.steamaco_id && formik.touched.steamaco_id}</span>   
            </div>
            {loading && <LoaderComponent />}
            
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>Customer Name</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='account_name' 
                    value={formik.values.account_name} 
                    readonly={true}
                />
            </div>
           
            <Row>
                <Col span={11} className="mr-8">
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Amount</span>
                        <InputField 
                            size='large' 
                            className='mt-1' 
                            name='amount' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur} 
                            value={formik.values.amount} 
                        />
                        <span className="text-red-500"> {formik.errors.amount && formik.touched.amount && formik.errors.amount}</span>   
                    </div>
                </Col>
                <Col span={11}>
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Payment Type</span>
                        <Select 
                            size="large"
                            className="customSelect mt-1"
                            options={[
                                { label: 'Wallet', value: 'wallet'},
                                { label: 'Card', value: 'card' }
                            ]} 
                            onChange={(value) => handleChange('payment_method', value)}
                        />
                        <span className="text-red-500"> {formik.errors.type && formik.touched.type && formik.errors.type}</span>   
                    </div>    
                </Col>
            </Row>
            <div className="mt-5">
                <button 
                    disabled={!verified}
                    type="submit" 
                    className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                    PROCEED
                </button>
            </div>
        </form>
    )
}