import React, {useState, useEffect} from 'react'
import {DatePicker, Button, notification} from 'antd'
import search from '../../../assets/icons/search.svg'
import total from '../../../assets/icons/total.svg'
import thumbs from '../../../assets/icons/thumbup.svg'
import thumbD from '../../../assets/icons/thumbdown.svg'
import { Barchart } from '../../components/BarChart'
import { PieChart } from '../../components/PieChart/PieChart'
import {useQuery} from '@tanstack/react-query'
import { getDashboardData } from '../../../core-units/utils/queryFn'
import {Skeleton} from 'antd'
import { DotChartOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { formatNumber } from '../../../core-units/utils'


const Dashboard = () => {
  const [notice, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, data) => {
        notice[type]({
          message: data.message,
          description:data.description,
        });
      };
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [query, setQuery] =useState({from : '', to : ''})

  const filterByDate = () => {
    console.log(from, to)
    if(!from || from === 'Invalid Date'){
      openNotificationWithIcon('error', {message : 'Invalid date range', description : "Fill in dates correctly"})
      return
    }
    if(!to || to === 'Invalid Date'){
      openNotificationWithIcon('error', {message : 'Invalid date range', description : "Fill in dates correctly"})
      return
    }
    setQuery({from : from, to : to})
  }

  useEffect(() => {
    dashboard.remove()
    // dashboard.refetch()
    console.log('query change', query)

    // eslint-disable-next-line
  }, [query])
  
  const dashboard = useQuery({ queryKey: ['dashboard'], queryFn: () => getDashboardData(query), retry : false })
  return (
    <div className='md:p-10 p-5'>
      {contextHolder}
        <div className="md:flex">
            <h2 className="md:text-[32px] text-[24px] text-brand-primary">Dashboard</h2>
            <div className="ml-auto flex gap-5">
                <DatePicker placeholder="From" onChange={(e) => {setFrom(dayjs(e).format())} }  />
                <DatePicker placeholder="To" onChange={(e) => {setTo(dayjs(e).format())} }    />
                <Button className='h-full mt-1 bg-brand-primary border-0  ' size='large' onClick={filterByDate}>
                    <img src={search} alt=""  />
                </Button>
            </div>
        </div>
        <div className="md:grid grid-cols-3 gap-6 mt-14 hidden">
          <div className=" rounded-[10px] md:py-8 py-5 px-5 xl:px-14 md:px-8 bg-white">
            <div className="flex gap-5 ">
              {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <>
              <img src={total} alt="" className='w-1/4' />
              <div className="py-3 ml-3 w-2/4">
                <h2 className="font-semibold">Total</h2>
                <h2 className="font-semibold text-2xl">{formatNumber(dashboard.data.tile.total_amount) }</h2>
              </div>
              <h2 className=" mt-3 text-right text-brand-primary text-2xl w-1/4">{dashboard.data.tile.total}</h2>
              </>}
            </div>
          </div>
          <div className=" rounded-[10px] md:py-8 py-5 px-5 xl:px-14 md:px-8 bg-white">
            <div className="flex gap-5 ">
            {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <>
              <img src={thumbs} alt="" className='w-1/4' />
              <div className="py-3 ml-3 w-2/4">
                <h2 className="font-semibold">Successful</h2>
                <h2 className="font-semibold text-2xl">{formatNumber(dashboard.data.tile.success_amount)}</h2>
              </div>
              <h2 className=" mt-3 text-right text-brand-primary text-2xl w-1/4">{dashboard.data.tile.success}</h2>
              </>}
            </div>
          </div>
          <div className=" rounded-[10px] md:py-8 py-5 px-5 xl:px-14 md:px-8 bg-white">
            <div className="flex gap-5 ">
            {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <>
              <img src={thumbD} alt="" className='w-1/4' />
              <div className="py-3 ml-3 w-2/4">
                <h2 className="font-semibold">Failed</h2>
                <h2 className="font-semibold text-2xl">{formatNumber(dashboard.data.tile.failed_amount)}</h2>
              </div>
              <h2 className=" mt-3 text-right text-brand-primary text-2xl w-1/4">{dashboard.data.tile.failed}</h2>
              </>}
            </div>
          </div>
          <div className="bg-white col-span-2 md:py-8 py-5 px-5 xl:px-14 rounded-[10px]">
            <p className="text-lg mb-5">Successful/Failed Transaction</p>
            <div className="mt-8">
            {dashboard.isLoading && <div className='flex items-center mx-auto  h-60'> <Skeleton.Node className='mx-auto' active={true}>
              <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
            </Skeleton.Node> </div>}
             {dashboard.data &&  <Barchart data={dashboard.data.bar_chart}/>}
            </div>
         {  dashboard.data &&  <div className="my-6 flex content-center justify-center gap-10 ">
                <div className="flex gap-5"> <div className="rounded-full bg-[#2F8FCD] w-5 h-5 -mt-0.5"></div>  Successful</div>
                <div className="flex gap-5"> <div className="rounded-full bg-[#F79637] w-5 h-5 -mt-0.5"></div>  Failed</div>
            </div>}
            {/* <p className="text-lg my-5">Successful/Failed Transaction</p> */}
          </div>
          <div className="bg-white md:py-8 py-5 px-5 xl:px-14">
          <p className="text-lg mb-5">Transaction Percentage </p>
          <div className="mt-8 mb-4">
            {dashboard.isLoading && <div className='flex items-center mx-auto  h-60'> <Skeleton.Node className='mx-auto' active={true}>
              <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
            </Skeleton.Node> </div>}
            {dashboard.data && <PieChart data={dashboard.data.pie_chart}/>}
          </div>
        { dashboard.data &&  <div className="my-6 flex content-center justify-center gap-10 ">
                <div className="gap-5"> <div className="rounded-full bg-[#2F8FCD] w-5 h-5 -mt-0.5 mx-auto mb-2" ></div>  Successful</div>
                <div className=" gap-5"> <div className="rounded-full bg-[#F79637] w-5 h-5 -mt-0.5 mx-auto mb-2"></div>  Failed</div>
            </div>}
          </div>
        </div>
        <div className="mt-8">

          {/* MOBILE DASHBOARD  */}
          <div className="grid grid-cols-3 gap-1 md:hidden">
            <div className=''>
          {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <div className='bg-[#ECF3FF] p-2 rounded-md'>
                <div className="flex flex-row-reverse">
                  <img src={total} alt="" className='w-1/4' />
                  <h2 className=" text-brand-primary text-lg w-1/4 mr-auto">{dashboard.data.tile.total}</h2>
                </div>
              <div className="">
                <h2 className="text-xs">Total</h2>
                <h2 className="font-semibold text-base">{dashboard.data.tile.total_amount}</h2>
              </div>
              </div>}
            </div>
            <div className=''>
          {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <div className='bg-[#ECF3FF] p-2 rounded-md'>
                <div className="flex flex-row-reverse">
                  <img src={thumbs} alt="" className='w-1/4' />
                  <h2 className=" text-brand-primary text-lg w-1/4 mr-auto">{dashboard.data.tile.success}</h2>
                </div>
              <div className="">
                <h2 className="text-xs">Successful</h2>
                <h2 className="font-semibold text-base">{dashboard.data.tile.success_amount}</h2>
              </div>
              </div>}
            </div>
            <div className=''>
          {dashboard.isLoading && <Skeleton active/>}
              {dashboard.data && <div className='bg-[#ECF3FF] p-2 rounded-md'>
                <div className="flex flex-row-reverse">
                  <img src={thumbD} alt="" className='w-1/4' />
                  <h2 className=" text-brand-primary text-lg w-1/4 mr-auto">{dashboard.data.tile.failed}</h2>
                </div>
              <div className="">
                <h2 className="text-xs">Failed</h2>
                <h2 className="font-semibold text-base">{dashboard.data.tile.failed_amount}</h2>
              </div>
              </div>}
            </div>
          </div>

          <div className="bg-white col-span-2 md:py-8 py-10  rounded-[10px] md:hidden">
            <p className="text- mb-5">Successful/Failed Transaction</p>
            <div className="mt-3 h-[40vh]">
            {dashboard.isLoading && <div className='flex items-center mx-auto  h-60'> <Skeleton.Node className='mx-auto' active={true}>
              <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
            </Skeleton.Node> </div>}
             {dashboard.data &&  <Barchart data={dashboard.data.bar_chart} view='mobile'/>}
            </div>
         {  dashboard.data &&  <div className="my-6 flex content-center justify-center gap-10 ">
                <div className="flex gap-5"> <div className="rounded-full bg-[#2F8FCD] w-5 h-5 -mt-0.5"></div>  Successful</div>
                <div className="flex gap-5"> <div className="rounded-full bg-[#F79637] w-5 h-5 -mt-0.5"></div>  Failed</div>
            </div>}
            {/* <p className="text-lg my-5">Successful/Failed Transaction</p> */}
          </div>

          <div className="bg-white md:py-8 py-10  xl:px-14 md:hidden">
          <p className="text-lg mb-5">Transaction Percentage </p>
          <div className="mt-8 mb-4">
            {dashboard.isLoading && <div className='flex items-center mx-auto  h-60'> <Skeleton.Node className='mx-auto' active={true}>
              <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
            </Skeleton.Node> </div>}
            {dashboard.data && <div className="mx-auto"> <PieChart data={dashboard.data.pie_chart}/></div>}
          </div>
        { dashboard.data &&  <div className="my-6 flex content-center justify-center gap-10 ">
                <div className="gap-5"> <div className="rounded-full bg-[#2F8FCD] w-5 h-5 -mt-0.5 mx-auto mb-2" ></div>  Successful</div>
                <div className=" gap-5"> <div className="rounded-full bg-[#F79637] w-5 h-5 -mt-0.5 mx-auto mb-2"></div>  Failed</div>
            </div>}
          </div>
        </div>
    </div>
  )
}

export default Dashboard