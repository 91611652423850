import { Link, useParams } from "react-router-dom";
import { formatNumber } from '../../../core-units/utils';
import success from '../../../assets/icons/success-green.svg';
import { useEffect, useState } from 'react';
import { verifyTransaction } from '../../../core-units/utils/queryFn';
import { LoaderComponent } from "../../components/Loader/Loader";

export const TransactionStatus = () => {
    const { ref } =  useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        async function verify() {
            await verifyTransaction({txt_ref: ref})
                .then(resp => {
                    setLoading(false);
                    if (resp.status) {
                        setData(resp.data.history);
                    } else {

                    }
                })
                .catch(err => {
                    setLoading(false);
                });
        }
        verify();
    }, [ref]);
    
    return (
        <div className="md:p-10 p-5">
            <div className="md:flex justify-between">
                <h2 className="md:text-[32px] text-[24px] mb-0 text-brand-primary">
                    Fund Wallet
                </h2>
            </div>

            <div className="mt-10 bg-white rounded-md md:p-10">
                {loading ? 
                    <LoaderComponent />
                :
                 <div className="bill-wrapper">
                    <img src={success} alt="success" className='md:mx-auto my-5' />
                    <h1 className="text-brand-primary font-[500] text-center">{data?.description}</h1>
                    <ul className='bill-info'>
                        <li className='flex justify-between'>
                            <span>Description</span>
                            <span className='font-[600]'>{data?.biller_name}</span>
                        </li> 
                        <li className='flex justify-between'>
                            <span>Amount</span>
                            <span className='font-[600]'>{formatNumber(data?.amount)}</span>
                        </li> 
                        <li className='flex justify-between'>
                            <span>Transaction ID</span>
                            <span className='font-[600]'>{ref}</span>
                        </li> 
                        <li className='flex justify-between'>
                            <span>Payment Type</span>
                            <span className='font-[600]'>{data?.type}</span>
                        </li> 
                        <li className='flex justify-between'>
                            <span>Charges</span>
                            <span className='font-[600]'>0.00</span>
                        </li> 
                        
                    </ul>
                    
                    <div className="mt-5">
                        <Link to={`/bill-payment`} className='bg-brand-primary text-center w-full text-white font-bold p-3 rounded-lg'>
                            BACK TO HOME
                        </Link>
                    </div>
                </div>}
            </div>
        </div>
    )
}