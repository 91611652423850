import React from 'react'
import logo from '../../../assets/images/logo.svg'
import success from '../../../assets/icons/success-1.svg'
import { Card, Space } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import {useNavigate, useSearchParams} from 'react-router-dom'
import { useSelector } from 'react-redux';


const EmailVerification = () => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );
    const [searchParams, ] = useSearchParams();

    const email = searchParams.get('email');

    const navigate = useNavigate();

    return (
        <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
            <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
                <div className='py-20 mx-auto w-full'>
                    <img src={logo} alt="" className='md:mx-auto ' />
                    <div className="mt-10 mx-auto text-center w-[100%]">
                        <h2 className="text-[30px] font-[500] text-brand-primary md:text-center">Email Verification</h2>
                        <p style={{color: '#303137'}}>Kindly follow the instruction provided to continue</p>
                        
                        <Space direction="vertical" size={16} style={{display: 'flex', alignItems: 'center'}}>
                            <Card className='mx-auto md:text-center p-5 mt-5 w-[70%]' style={{color: '#7E7C7C'}}>
                                <p>A verification email has been sent to:</p>

                                <p className='text-brand-primary font-[600] my-5'>{email}</p>

                                <p className='my-5'>Please follow the instructions in the verification email to complete your account creation.</p>

                                <p className='my-2'>Please follow the instructions in the verification email to complete your account creation.</p>
                                
                                <p className='my-2'>Didn't receive an email?</p>
                            </Card>
                            <div className="md:w-full mt-2">
                                <button type="button" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                    RESEND VERIFICATION
                                </button>
                                <p className="text-center text-[#AEB5C2] mt-5 cursor-pointer" onClick={() => navigate('/auth')}>Back to <span className='text-brand-primary'>Sign In</span></p>
                            </div>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default EmailVerification;