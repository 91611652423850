import React from 'react'
import { dayPeriod } from '../../../core-units/utils'
import notification from "../../../assets/icons/notification.svg"
import profile from "../../../assets/icons/profile.svg"
import logo from '../../../assets/images/logo.svg'
import hamburger from '../../../assets/icons/hamburger.svg'
import { useState } from 'react'
import {  Drawer } from 'antd';
import SideBar from './SideBar'

const Header = ({user}) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className='md:p-10 p-5 relative flex  '>
      <Drawer  placement="left" onClose={onClose} open={open} width={'70vw'} closable={false} >
        <SideBar state={setOpen}/>
      </Drawer>
        <p className="text-lg capitalize font-[500] mt-3 hidden md:block">Good {dayPeriod()} {user.firstname}</p>
        <img src={logo} alt="" className='w-28 md:w-full md:hidden block' />
        <div className="ml-auto flex md:gap-5 gap-2">
            <img src={notification} alt="" className='w-8 md:w-full' />
            <img src={profile} alt="" className='w-8 md:w-full' />
            <img src={hamburger} alt="" className='w-8 md:w-full md:hidden block' onClick={showDrawer} />
        </div>
    </div>
  )
}

export default Header