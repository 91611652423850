import React, { useState } from "react";
import { Table, Modal, Button , Spin, Tooltip} from "antd";
import eye from "../../../assets/icons/eye.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import authorize from "../../../assets/icons/authorize.svg";
import verifiedGreen from "../../../assets/icons/verified-green.svg";
import verified from "../../../assets/icons/verified.svg";
import CircleClose from "../../../assets/icons/circleClose.svg";
import iconInfo from "../../../assets/icons/infoBlue.svg";
import {  LoadingOutlined } from "@ant-design/icons";
import api from "../../../core-units/api";


const CustomerTable = ({ data, state, onPageChange, perPage, total, page }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
  const navigate = useNavigate();
  const view = (rec) => {
    navigate(`/customers/${rec.id}`);
  };

  const doAuthorize = () => {
    setLoading(true)
    api.delete(`user/authorize?user_id=${user.id}`).then(res => {
      console.log(res.data)
      setLoading(false)
      handleCancel()

    }).catch(err=>{
      console.log(err) 
      setLoading(false)
      handleCancel()
    })
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    
    },
    {
      title: "Phone Number",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      key: "email",
      render: (record) => (
        <>
          <span>{record?.email}</span>
        </>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "created_at",
      key: "date",
      render: (record) => <span>{dayjs(record?.created_at).format("DD/MM/YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <>
          {record === 0 && <span className="text-[#F79637]">Pending</span>}
          {record === 1 && <span className="text-[#00B224]">Approved</span>}
          {record === 2 && <span className="text-[#EE3539]">Rejected</span>}
        </>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="flex gap-4 w-full">
           <Tooltip title="View Details">

            <img
              src={eye}
              alt=""
              className="cursor-pointer"
              onClick={() => view(record)}
            />
          </Tooltip>
          {record.is_authorized === 0 &&
          <Tooltip title="Authorize User">

            <img
              src={authorize}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setUser(record)
                setIsModalOpen(true)}}
            />
          </Tooltip>
          }
          <Tooltip title="Verification Status">
            <>
            {record.status === 0 && 
            <img
              src={verified}
              alt=""
              className="cursor-pointer"
              onClick={() => view(record)}
            />
            }

            {record.status === 1 && 
            <img
              src={verifiedGreen}
              alt=""
              className="cursor-pointer"
              onClick={() => view(record)}
            />
            } 
            </>
          </Tooltip>

        </div>
      ),
    },
  ];

  const handleCancel = () => {
    state.refetch()
    setUser(null)
    // window.location.reload()
    setIsModalOpen(false);
  };

  return (
    <div className="h-[50vh] overflow-auto">
      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className="flex h-full w-full items-center justify-center p-10">
          <div>
            <img src={iconInfo} alt="" className=" mx-auto" />
            <p className="mt-8">Are you sure you want to authorize this user</p>
            <div className="flex justify-around w-full mt-10">
              <Button
                onClick={doAuthorize}
                type="text"
                className=" uppercase bg-brand-primary font-bold "
              >
                {loading ? (
                  <span className="text-white">
                    {" "}
                    <Spin indicator={<LoadingOutlined spin />} /> LOADING...
                  </span>
                ) : (
                  <span className="text-white">CONTINUE</span>
                )}
              </Button>
              <Button
                onClick={handleCancel}
                type="text"
                className=" uppercase bg-brand-secondary font-bold "
              >
                <span className="text-white">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ 
          defaultPageSize: perPage, 
          total,
          current: page, 
          // pageSizeOptions: ['10', '20', '30', '50', '100']
          onChange: (page, pageSize) => {
            onPageChange(page);
          },
        }}
        sticky={true}
      />
    </div>
  );
};

export default CustomerTable;
