import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
// import icon from '../../../assets/icons/foward.svg'
import dIcon from "../../../assets/icons/dashboard.svg";
import dIconD from "../../../assets/icons/dashboardDark.svg";
import trans from "../../../assets/icons/transaction.svg";
import transD from "../../../assets/icons/transactionDark.svg";
import logout from "../../../assets/icons/logout.svg";
import plus from "../../../assets/icons/circledPlus.svg";
import leaseIcon from "../../../assets/icons/lease.svg";
import leaseWhite from "../../../assets/icons/lease-white.svg";
import walletIcon from "../../../assets/icons/wallet.svg";
import walletWhite from "../../../assets/icons/wallet-white.svg";
import { FileDoneOutlined, ShoppingCartOutlined, TeamOutlined, TransactionOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { getUserAccount } from "../../../core-units/utils/queryFn";
import { formatNumber } from "../../../core-units/utils";
import { AddFundsModal } from "../../pages/Wallet/AddFunds";
import { useDispatch, useSelector } from "react-redux";

const SideBar = ({ state, user }) => {
  const { pathname } = useLocation();
  const [account, setAccount] = useState(null);
  const {data} = useQuery({ queryKey: ['account'], queryFn: () => getUserAccount(), retry : false, refetchOnWindowFocus: false, } )
  const {addFunds} = useSelector(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) 
      setAccount(data.data.account);
  }, [data]);

  const logoutFn = () => {
    // user.remove();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="w-full md:h-screen md:px-10 px-0 md:py-10 py-10 md:relative ">
      <img src={logo} alt="" />
      <div> 
        <div className="mt-2 wallet-wrapper">
          <div className="flex py-2 px-2" style={{justifyContent: 'space-between'}}>
            <div>
              <small style={{display: 'block', marginBottom: '10px'}}>{account?.bank_name}</small>
              <span>{ formatNumber(account?.balance)}</span>
            </div>
            <div className="">
              <span className="wallet-balance">{account?.account_no} </span>
            </div>
          </div>
          <div>
          </div>
          <div 
            style={{cursor: 'pointer'}}
            className="wallet-footer flex md:text-center text-white" 
            onClick={()=> dispatch({type: 'TOGGLE_FUNDS_MODAL'})}>
            <img src={plus} className="mr-2" />
            <span>Add Funds</span>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <NavLink
          to={"/"}
          onClick={() => {
            state && state(false);
          }}
        >
          {/* "flex gap-5 content-center px-2 rounded-[10px]" */}
          <div
            className={
              pathname === "/"
                ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                : "flex items-center gap-5 content-center px-2 rounded-[10px]"
            }
          >
            {pathname === "/" ? (
              <img src={dIcon} alt="" className="" />
            ) : (
              <img src={dIconD} alt="" className="" />
            )}
            <p
              className={
                pathname === "/"
                  ? "font-[500] text-[16px] mt-4 text-white "
                  : "font-[500] text-[16px] mt-4 "
              }
            >
              Dashboard
            </p>
            {/* <img src={icon} alt="" className="md:ml-12 ml-6" /> */}
          </div>
        </NavLink>
        <NavLink
          to={"/transactions"}
          onClick={() => {
            state && state(false);
          }}
        >
          {/* "flex gap-5 content-center px-2 rounded-[10px]" */}
          <div
            className={
              pathname.includes("/transactions")
                ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                : "flex gap-5 content-center px-2 rounded-[10px] "
            }
          >
            {pathname.includes("/transactions") ? (
              <img src={trans} alt="" className="" />
            ) : (
              <img src={transD} alt="" className="" />
            )}
            <p
              className={
                pathname.includes("/transactions")
                  ? "font-[500] text-[16px] mt-4 text-white "
                  : "font-[500] text-[16px] mt-4 "
              }
            >
              Transactions
            </p>
            {/* <img src={icon} alt="" className=" md:ml-8 ml-4" /> */}
          </div>
        </NavLink>
        {user?.role !== "ADMIN" && (
          <NavLink
            to={"/bill-payment"}
            onClick={() => {
              state && state(false);
            }}
          >
            {/* "flex gap-5 content-center px-2 rounded-[10px]" */}
            <div
              className={
                pathname.includes("/bill-payment")
                  ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                  : "flex gap-5 content-center px-2 rounded-[10px] "
              }
            >
              <FileDoneOutlined style={{ fontSize: '25px'}} />
              <p
                className={
                  pathname.includes("/bill-payment")
                    ? "font-[500] text-[16px] mt-4 text-white "
                    : "font-[500] text-[16px] mt-4 "
                }
              >
                Bill Payment
              </p>
              {/* <img src={icon} alt="" className=" md:ml-8 ml-4" /> */}
            </div>
          </NavLink> 
        )}
        <NavLink
          to={"/lease"}
          onClick={() => {
            state && state(false);
          }}
        >
          <div
            className={
              pathname.includes("/lease")
                ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                : "flex gap-5 content-center px-2 rounded-[10px] "
            }
          >
              {pathname.includes("/lease") ? (
                <img src={leaseWhite} alt="" className="" />
              ) : (
                <img src={leaseIcon} alt="" className="" />
              )}
            <p
              className={
                pathname.includes("/lease")
                  ? "font-[500] text-[16px] mt-4 text-white "
                  : "font-[500] text-[16px] mt-4 "
              }
            >
              Lease
            </p>
          </div>
        </NavLink>
        {user?.role !== "ADMIN" && (

        <NavLink
          to={"/wallet"}
          onClick={() => {
            state && state(false);
          }}
        >
          <div
            className={
              pathname.includes("/wallet")
                ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                : "flex gap-5 content-center px-2 rounded-[10px] "
            }
          >
            {pathname.includes("/wallet") ? (
              <img src={walletWhite} alt="" className="" />
            ) : (
              <img src={walletIcon} alt="" className="" />
            )}
            <p
              className={
                pathname.includes("/wallet")
                  ? "font-[500] text-[16px] mt-4 text-white "
                  : "font-[500] text-[16px] mt-4 "
              }
            >
              Wallet
            </p>
          </div>
        </NavLink>)}
        {user?.role === "ADMIN" && (
          <>
            <NavLink
              to={"/products"}
              onClick={() => {
                state && state(false);
              }}
            >
              <div
                className={
                  pathname.includes("/products")
                    ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                    : "flex gap-5 content-center px-2 rounded-[10px] "
                }
              >
                <ShoppingCartOutlined style={{ fontSize: '25px'}} />
                <p
                  className={
                    pathname.includes("/products")
                      ? "font-[500] text-[16px] mt-4 text-white "
                      : "font-[500] text-[16px] mt-4 "
                  }
                >
                  Products
                </p>
              </div>
            </NavLink>
            <NavLink
              to={"/customers"}
              onClick={() => {
                state && state(false);
              }}
            >
              <div
                className={
                  pathname.includes("/customers")
                    ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                    : "flex gap-5 content-center px-2 rounded-[10px] "
                }
              >
                <TeamOutlined style={{ fontSize: '25px'}} />
                <p
                  className={
                    pathname.includes("/customers")
                      ? "font-[500] text-[16px] mt-4 text-white "
                      : "font-[500] text-[16px] mt-4 "
                  }
                >
                  Users
                </p>
              </div>
            </NavLink>
            <NavLink
              to={"/financial-partners"}
              onClick={() => {
                state && state(false);
              }}
            >
              <div
                className={
                  pathname.includes("/financial-partners")
                    ? "flex gap-5 content-center px-2 rounded-[10px] bg-brand-primary text-white "
                    : "flex gap-5 content-center px-2 rounded-[10px] "
                }
              >
                <TransactionOutlined style={{ fontSize: '25px'}} />
                <p
                  className={
                    pathname.includes("/financial-partners")
                      ? "font-[500] text-[16px] mt-4 text-white "
                      : "font-[500] text-[16px] mt-4 "
                  }
                >
                  Financial Partners
                </p>
              </div>
            </NavLink>
          </>
        )}

        <div className="absolute bottom-20">
          {/* "flex gap-5 content-center px-2 rounded-[10px] mt-5" */}
          <div
            className="flex gap-5 content-center px-2 rounded-[10px] cursor-pointer"
            onClick={() => logoutFn()}
          >
            <img src={logout} alt="" className="" />
            <p className="font-[500] text-[16px] mt-0.5 ">Logout</p>
          </div>
        </div>
      </div>
      <AddFundsModal isModalOpen={addFunds} handleClose={() => dispatch({type: 'TOGGLE_FUNDS_MODAL'})} user={user} />

    </div>
  );
};

export default SideBar;
