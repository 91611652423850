import { useNavigate } from "react-router-dom";
import arrowLeft from "../../../assets/icons/arrow-left.svg";

export const BackButton = () => {
    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(-1)} className="flex bg-[#474A56] rounded-lg px-5 text-white py-2" style={{alignItems: 'center'}}>
            <img src={arrowLeft} className="mr-2" />
            <span>Back</span>
        </button>
    )
}