import React from 'react';
import dayjs from 'dayjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const labelG = (array) => {
  let v = []
  array.forEach((i) => {
    v.push(dayjs(i.date).format('DD-MMM'))
  })
  return v
}

const dataSetG = (array) => {
  let v1 = []
  let v2 = []
  array.forEach((i) => {
    v1.push(i.amount)
    v2.push(i.amount)
  })
  return {v1, v2}
}

export function Barchart({data, view}) {
  console.log(view, data, "bar data")
  const labels = labelG(data)
  const _data = {
    labels,
    datasets: [
      {
        label: 'Successful',
        data: dataSetG(data).v1,
        backgroundColor: '#2F8FCD',
      },
      // {
      //   label: 'Failed',
      //   data: dataSetG(data).v2,
      //   backgroundColor: '#F79637',
      // },
    ],
  };
  const options = {
    scales: {
        x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
    },
  responsive: true,
  maintainAspectRatio: !view ? true : false,
  plugins: {
    legend: {
      position: 'bottom',
      display : false
    //   labels: {
    //     usePointStyle: true,
    //     pointStyle: 'circle',
    //     padding: 20,
    //   }
    }
  },
};
 
  return <Bar options={options} data={_data} />; 
}
