import React, { useEffect, useState } from "react";
import { Input, Button, notification } from "antd";
import circledPlus from "../../../assets/icons/circledPlus.svg";
import download from "../../../assets/icons/download.svg";
import AdminTable from '../../components/AdminTable/Index'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCustomers } from "../../../core-units/utils/queryFn";
import { Spin, Skeleton } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CustomerTable from "../../components/AdminTable/CustomerTable";


const Customers = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (link) => {
      console.log(link);
      let res = await getCustomers(false, false, link);
      return res;
    },
    onSuccess: (data) => {
      console.log("succ", data);
      customers.remove();
      queryClient.setQueryData(["customers"], data);
    },
  });
  const [notice, contextHolder] = notification.useNotification();
  

  const customers = useQuery({
    queryKey: ["customers"],
    queryFn: () => getCustomers(page),
    retry: false,
  });

  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    customers.remove();
    customers.refetch();
    // eslint-disable-next-line
  }, [page]);

  const downloadTrans = (type) => {
    // console.log(type);
    getCustomers(false, type);
  };

  const pagi = (link) => {
    // console.log(link.active, "ll");
    if (link.active) {
      mutation.mutate(link.url);
    }
  };

  return (
    <div className="md:p-10 p-5">
      {contextHolder}
      <div className="md:flex">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Users
        </h2>
        <div className="ml-auto flex gap-5">
          <Input size="small" className="w-full" placeholder="Serach by name" />
          <Button
            className=" mt-1 bg-brand-primary border-0  flex justify-center items-center  gap-3 "
            size="large"
          >
            <img src={circledPlus} alt="" />
          </Button>

        </div>
      </div>
      <div className=" md:my-10 rounded-[10px] md:py-8 py-5 px-0">
        {/* <div className="md:flex hidden">
          <p className="text-lg mb-5"> </p>
          <div className="flex  ml-auto gap-5">
            <div
              className="flex gap-2 border py-3 px-3 rounded-lg cursor-pointer hover:bg-black"
              onClick={() => downloadTrans("PDF")}
            >
              <img src={download} alt="" />
              <span className="mt-0.5 text-[#9B9898]">PDF</span>
            </div>
            <div
              className="flex gap-2 border py-3 px-3 rounded-lg cursor-pointer hover:bg-black"
              onClick={() => downloadTrans("EXCEL")}
            >
              <img src={download} alt="" />
              <span className="mt-0.5 text-[#9B9898]">Excel </span>
            </div>
          </div>
        </div> */}
        <div className="mt-5 hidden md:block">
          {customers.data && 
            <CustomerTable 
              data={customers.data.data} 
              state={customers} 
              total={customers.data.total}
              page={page}
              perPage={customers.data.per_page}
              onPageChange={(n) => setPage(n)} 
            />
          }
          {customers.isLoading && (
            <div className="h-[50vh] flex content-center justify-center align-middle items-center">
              <Spin size="large" />
            </div>
          )}
          {/* <div className="flex  flex-wrap mt-5 gap-2">
            {customers.data?.links.map((link) => (
              <>
                <div
                  onClick={() => pagi(link)}
                  className={
                    link.active
                      ? "px-3 py-1 rounded bg-brand-primary text-white cursor-pointer"
                      : "px-3 py-1 rounded bg-brand-primary bg-opacity-20 text-white cursor-not-allowed"
                  }
                  dangerouslySetInnerHTML={{ __html: link.label }}
                ></div>
              </>
            ))}
          </div> */}
        </div>
        <div className="mt-5 min-h-[50vh]  md:hidden">
          {customers.isLoading && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
          {customers.data && (
            <>
              {customers.data.data.map((i) => (
                <div
                  className="bg-[#ECF3FF] px-3 pt-3 mt-3 rounded"
                  onClick={() => navigate(`/customers/${i.id}`)}
                >
                  <div className="flex">
                    <div className="">
                      <p className="font-semibold ">{i.biller_name}</p>
                      <p className=" text-sm -mt-2">
                        {dayjs(i.created_at).format("DD MMM. YYYY")}
                      </p>
                    </div>
                    <div className="ml-auto flex items-center gap-3">
                      <div className="font-semibold text-brand-primary text-xl">
                        {i.amount}
                      </div>
                      <img src={download} alt="" />
                    </div>
                  </div>
                  <hr className=" border-[#B3D5F0] w-full" />
                  <div className="flex pt-2">
                    {/* <p className=" text-xs w-1/2">Sender : {i.user?.wallet?.account_no} </p>
                <p className=" text-xs w-1/2 text-right">Receiver: :{i.bill_type === "payment" || i.bill_type === "airtime" ? i.customer : i.user?.wallet?.account_no } </p> */}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customers;
