import './App.css';
import 'antd/dist/reset.css';
import {  ConfigProvider } from 'antd';
import {QueryClient,QueryClientProvider} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from "react-router-dom";
import { router } from './core-units/router';
import {SWRConfig} from 'swr';
import api from './core-units/api';

function App() {
  const queryClient = new QueryClient()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2F8FCD',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SWRConfig
          value={{
            fetcher: url => api.get(url).then(res => res),
            refreshInterval: 15 * 60 * 1000,
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            errorRetryInterval: 0,
            errorRetryCount: 2,
          }}>
            <RouterProvider router={router} />
          </SWRConfig>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App;
