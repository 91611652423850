import { Button, Input, Modal, Select, notification } from "antd"
import { useCallback, useEffect, useState } from "react"
import { checkVerification, getDocTypes } from "../../../core-units/utils/queryFn";
import api from "../../../core-units/api";
import { useQuery } from "@tanstack/react-query";
import { InputField } from "../../components/Inputs/Input";
const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


export const VerifyLease = ({isModalOpen, handleClose, navigate , leaseId}) => {
    const document = useQuery({queryFn : getDocTypes, queryKey : ['documents'] })
    const [file, setFIle] = useState()
    const [proofOfAddress, setProofOfAddress] = useState()
    const [bankStatment, setBankStatement] = useState()
    const [document_type_id, setDocument_type_id] = useState()
    const [loading, setLoading] = useState(false)
    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
      notice[type]({
        message: data.message,
        description: data.description,
      });
    };

    const init = () => {
        checkVerification().then(res => {
            setLoading(false)
            if(res.message == 'User has been verified'){
                handleClose();
                setFIle(null);
                setDocument_type_id(null)
                navigate(`/lease/${leaseId}/guarantor`);

            }
        }).catch(err => {
            setLoading(false)
        })
    }

    if (isModalOpen) init();


    const uploadDocument = () => {
        if (!document_type_id) {
            openNotificationWithIcon("error", {message : 'Verification Error', description : 'Select document type',})
            return
        }
        if (!file) {
            openNotificationWithIcon("error", {message : 'Verification Error', description : 'Select a file',})
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        formData.append('document_type_id', document_type_id)
        if (proofOfAddress)
            formData.append('proof_of_address', proofOfAddress)

        if (bankStatment)
            formData.append('bank_statment', bankStatment)

        setLoading(true);
        
        api.post('lease-application/verification', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            console.log(res, 'res')
            setLoading(false)
            if(res.status){
                navigate(`/lease/${leaseId}/guarantor`)
                return
            }
            openNotificationWithIcon("success", {message : 'Success', description : res.message,})

        }).catch(err => {
            console.log(err, 'err')
            setLoading(false)
            openNotificationWithIcon("error", {message : 'Upload Error', description : 'An error occured. Please try again',})

        })
    }


    return (
        <Modal
            centered
            open={isModalOpen}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
            title="Upload Documents"
        >
            {contextHolder}
            <p>Provide either your utility bill, voter’s card, driver’s licence or International passport for verification.</p>
            <div className="md:py-8 py-4 ">
                <div className="mt-5 md:mt-0 w-full mb-5">
                    <span className='text-[#AEB5C2]'>Identity Document for Applicant</span>
                    <Select 
                      size="large"
                      showSearch
                      className="customSelect mt-1"
                      options={document.data
                        ? document.data.map((i) => {
                            return { label: i.name, value: i.id };
                          })
                        : []} 
                      filterOption={filterOption}
                      onChange={(e) => setDocument_type_id(e)}
                  />
                    
                </div>
                <div className="mt-5 md:mt-0 w-full mb-3">
                    <span className='text-[#AEB5C2]'>Select Document</span>
                    <InputField 
                        type="file" 
                        size='large' 
                        className='mt-1' 
                        placeholder="Click to select file"
                        name='file' 
                        onChange={(file) => setFIle(file)} 
                    />
                </div>
                <div className="mt-5 md:mt-0 w-full mb-3">
                    <span className='text-[#AEB5C2]'>Upload Proof of address</span>
                    <InputField 
                        type="file" 
                        size='large' 
                        className='mt-1' 
                        placeholder="Click to select file"
                        name='file' 
                        onChange={(file) => setProofOfAddress(file)} 
                    />
                </div>
                <div className="mt-5 md:mt-0 w-full">
                    <span className='text-[#AEB5C2]'>Upload Bank statement</span>
                    <InputField 
                        type="file" 
                        size='large' 
                        className='mt-1' 
                        placeholder="Click to select file"
                        name='file' 
                        onChange={(file) => setBankStatement(file)} 
                    />
                </div>
                <Button
                    onClick={() => uploadDocument()}
                    loading={loading}
                    type="text"
                    className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
                >
                    <span className="text-white uppercase font-bold">
                        {loading ? 'Uploading...' : 'Upload'}
                    </span>
                </Button>
            </div>
        </Modal>
    )
}