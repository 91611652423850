const initialState = {
    registerData: null,
	addFunds: false
}

export default (state = initialState, action) => {
    switch (action.type) {
		case 'SET_REGISTER_DATA':
			return {
				...state,
				registerData: action.payload
			}
		case 'TOGGLE_FUNDS_MODAL':
			return {
				...state,
				addFunds: !state.addFunds
			}
		
        default:
			return state
	}
}