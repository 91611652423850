import React, { useEffect, useRef, useState } from "react"
import { Checkbox, DatePicker, Input } from "antd"
import './styles.css';
import upload from '../../../assets/icons/upload.svg'
import dayjs from "dayjs";

export const InputField = ({size, type, className, style, name, onChange, value, onBlur, prefix, suffix, label, inputRef, readonly = false}) => {
    const [styles, setStyles] = useState({})
    const [file, setFile] = useState(null)
    const ref = useRef();

    useEffect(() => {
        if (style) setStyles(style)
    }, [style]);

    switch (type) {
        case 'password':
            return (
                <Input.Password
                    size={size} 
                    className={`customInput ${className}`} 
                    style={styles}
                    name={name} 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    value={value} 
                    ref={inputRef}

                />
            )
        case 'date':
            return (
                <DatePicker
                    size={size} 
                    className={`customInput w-full ${className}`} 
                    style={styles}
                    name={name} 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    value={dayjs(value) || dayjs()} 
                    ref={inputRef}

                />
            )
        case 'checkbox':
            return (
                <Checkbox 
                    className={`customCheck ${className}`} 
                    onChange={onChange}
                    name={name}
                    style={styles}
                    checked={value}
                    ref={inputRef}
                >
                    <span dangerouslySetInnerHTML={{__html: label}}></span>
                </Checkbox>
            )
        case 'file':
            return (
                <>
                    <Input 
                        suffix={<img src={upload} />}
                        size={size} 
                        className={`customInput ${className}`} 
                        style={styles}
                        name={name} 
                        onClick={() => ref.current.click()} 
                        value={file?.name} 
                        readOnly={true}
                        ref={inputRef}
                    />
                    <input 
                        type="file"
                        ref={ref} 
                        style={{display: 'none'}} 
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                            onChange(e.target.files[0]);
                        }}
                    />
                </>
            )
        default:
            return (
                <Input 
                    prefix={prefix}
                    suffix={suffix}
                    size={size} 
                    className={`customInput ${className}`} 
                    style={styles}
                    name={name} 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    value={value} 
                    readOnly={readonly}
                    ref={inputRef}
                />
            )
    }
    
}