import React, { useEffect } from "react";
import { Button, Input, Empty, Spin } from "antd";
import search from "../../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getUserLease } from "../../../core-units/utils/queryFn";
import calender from "../../../assets/icons/calender.svg";
import dayjs from "dayjs";
import { LeaseApplicationStatusBtn } from "./LeaseApplicationStatusBtn";

const LeaseItem = ({ data, navigate }) => {
  return (
    <div 
      className="pb-10 mt-10 flex justify-between gap-5" 
      style={{borderBottom: '1px solid #D9D9D9'}}
      onClick={()=>{navigate(`/lease/${data.id}/status`)}}
    >
      <div className="rounded-lg w-full" >
        <img src={data.product.image_url} alt="" className="rounded w-full" style={{width: '205px', height: '130px'}} />
      </div>
      <div>
        <h2 className="font-bold text-brand-primary">{data.product.product_name}</h2>
        <p className=" text-brand-base lease-desc-half" style={{lineHeight: 2}}>
          {data.product.description}
        </p>
        <div className="flex gap-2">
          <img src={calender} alt="" />
          <span className=" text-brand-base">{dayjs(data?.created_at).format('DD/MM/YYYY')}</span>
        </div>
      </div>
      <div className="">
          <LeaseApplicationStatusBtn status={data.status} />
          
          <Button
              size="large"
            className=" bg-brand-primary px-10 py-2 border-0 text-white items-center"
          >
            <span className="font-bold">DETAILS</span>
          </Button>

      </div>
    </div>
  );
};

const MyLease = () => {
  const navigate = useNavigate();
  
  const allLease = useQuery({
    queryKey: ["mylease"],
    queryFn: getUserLease,
    retry: false,
    refetchOnWindowFocus: false,
  });


  return (
    <div className="md:p-10 p-5">
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          My Lease 
        </h2>
        <div className="flex gap-5">
          <Input className="w-full" placeholder="Search for Lease Items" />
          <Button
            className="h-full bg-brand-primary border-0  "
          >
            <img src={search} alt="" className="w-[25px]" />
          </Button>
        </div>
      </div>
      {!allLease.isLoading && allLease.data && allLease.data.length === 0 && (
        <div className="mt-40">
          <Empty description={<span>No Lease Product Added</span>}>
            <Button
              onClick={() => navigate(-1)}
              className="h-full mt-1 bg-brand-secondary border-0  flex mx-auto justify-center items-center  gap-3 "
              size="large"
            >
              <span className="text-white ">Go Back</span>
            </Button>
          </Empty>
        </div>
      )}
      {allLease.isLoading && (
        <div className="flex h-full mt-40 items-center  justify-center">
          {" "}
          <Spin size="large" />
        </div>
      )}
      {allLease.data && (
        <div className="mt-10 p-10 bg-white rounded gap-4">
          {allLease.data.map((i) => (
            <LeaseItem data={i} key={i.id} navigate={navigate} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MyLease;
