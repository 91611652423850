import { Col, Row, Select, notification} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { leaseApply } from "../../../core-units/utils/queryFn";
import { GuarantorForm } from "../../components/Forms/GuarantorForm";
import { BackButton } from "../../components/PayBills/BackButton";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { InputField } from "../../components/Inputs/Input";

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


export const Guarantors = () => {
  const { id } = useParams();
  const [guarantors, setGuarantors] = useState([]);
  const [referredBy, setReferredBy] = useState('');
  const [financePartner, setFinancePartner] = useState('');
  const [docTypes, setDocTypes] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const {data: documentTypes} = useSWR(`utilities/document-type`)
  const {data: partners} = useSWR(`admin/finance-partners`);
  // console.log(partners);

  const [notice, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, data) => {
    notice[type]({
      message: data.message,
      description: data.description,
    });
  };

  useEffect(() => {
    if (documentTypes) {
      const docs = documentTypes.data.map(item => { return {value: item.id, label: item.name}});
      setDocTypes(docs);
    }
  }, [documentTypes])

  const submit = (e) => {
    e.preventDefault();

    // validate guarantors
    if(guarantors[0].guarantor_name === guarantors[1].guarantor_name) {
      openNotificationWithIcon("error", {message : 'Error', description : 'Both guarantors name cannot be the same',});
      return;
    }

    if(guarantors[0].guarantor_email === guarantors[1].guarantor_email) {
      openNotificationWithIcon("error", {message : 'Error', description : 'Both guarantors email cannot be the same',});
      return;
    }

    if(guarantors[0].guarantor_phone === guarantors[1].guarantor_phone) {
      openNotificationWithIcon("error", {message : 'Error', description : 'Both guarantors phone cannot be the same',});
      return;
    }

    if(guarantors[0].guarantor_address === guarantors[1].guarantor_address) {
      openNotificationWithIcon("error", {message : 'Error', description : 'Both guarantors address cannot be the same',})
      return;
    }

    const formData = new FormData();
    formData.append('lease_product_id', id);
    formData.append('credit_check', 1);
    formData.append('referred_by', referredBy);
    formData.append('finance_partner_id', financePartner);

    guarantors.forEach((guarantor, index) => {
      Object.keys(guarantor).forEach(key => formData.append(`guarantors[${index}][${key}]`, guarantor[key]));

    });

    setSubmitting(true);
    leaseApply(formData).then(res => {
      setSubmitting(false)
        if (res.status) {
          navigate(`/lease/me`)
        } else {
          openNotificationWithIcon("error", {message : 'Error', description : res.message,})
        }
    }).catch(err => {
      console.log(err);
      setSubmitting(false)
      openNotificationWithIcon("error", {message : 'Error', description : err.data.message,})
    })
  }

  return (
      
      <div className="md:p-10 p-5">
        {contextHolder}
        
        <div className="md:flex justify-between">
          <h2 className="md:text-[32px] mb-0 text-[24px] text-brand-primary">
            Lease Application
          </h2>
          <BackButton />
        </div>
        <div className="mt-10 bg-white rounded-lg md:p-10">
          <form onSubmit={submit} className="">
            <Row className="w-full mb-5">
                <Col span={11} className="mr-10">
                  <div className="mt-2">
                    <span className='text-[#AEB5C2]'>Referred By</span>
                    <InputField 
                        size='large' 
                        className='mt-1' 
                        name='referred_by' 
                        onChange={(e) => setReferredBy(e.target.value)}
                    />
                  </div>
                </Col>
                <Col span={11}>
                  <span className='text-[#AEB5C2]'>Financing Partner</span>
                  <Select 
                      size="large"
                      showSearch
                      filterOption={filterOption}
                      className="customSelect mt-2"
                      options={partners?.data?.data?.map(item => ({value: item.id, label: item.name}))} 
                      onChange={(value) => setFinancePartner(value)}
                  />
                </Col>
              </Row>
            <div className="md:flex gap-10">
              
              <Row className="w-full">
                <GuarantorForm 
                  title={'Guarantor 1 Form'} 
                  documentTypes={docTypes}
                  updateField={(data) => {
                    const newArr = [...guarantors]
                    newArr[0] = data;
                    setGuarantors(newArr)
                  }} 
                />
                <GuarantorForm 
                  title={'Guarantor 2 Form'} 
                  documentTypes={docTypes}
                  updateField={(data) => {
                    const newArr = [...guarantors]
                    newArr[1] = data;
                    setGuarantors(newArr)
                  }}
                />
              </Row>    
            </div>
          
            <div className="mt-5">
              
              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="submit"
                  className="h-10 flex justify-center items-center rounded-md text-white text-center bg-brand-primary w-full"
                >
                  {isSubmitting ? (
                    <span className="text-white uppercase font-bold">
                      Submitting...
                    </span>
                  ) : (
                    <span className="text-white uppercase font-bold">
                      Submit Application
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }