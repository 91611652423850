import React from "react";
import AdminLease from "./AdminLease";
import UserLease from "./UserLease";


const Lease = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if(user?.role === 'ADMIN') {
    return <AdminLease />
  } else {
    return <UserLease />
  }
};

export default Lease;
