import {  Col, Row} from "antd";
import { Formik } from "formik";
import { InputField } from "../Inputs/Input";
import { saveCustomer } from "../../../core-units/utils/queryFn";


export const BvnNinInfo = ({next, prev, data, onComplete}) => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstname: data?.bvn_info?.firstname || "",
          lastname: data?.bvn_info?.lastname || "",
          email: data?.bvn_info?.email || "",
          phone: data?.bvn_info?.phone || "",
          date_of_birth: data?.bvn_info?.date_of_birth || "",
          gender: data?.bvn_info?.gender || "",
          state_of_residence: data?.bvn_info?.state_of_residence || "",
          lga_of_residence: data?.bvn_info?.lga_of_residence || "",
        }}
        
        onSubmit={(values, { setSubmitting }) => {
          const newData = {...data};
          saveCustomer(newData).then(res => {
            setSubmitting(false);
            console.log('res' , res);
            if (res.status) {
              onComplete();
            } else {

            }
          }).catch(err => {
            
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="py-10">
            <Row type="flex" align="middle">
                <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>First Name</span>
                    <InputField readonly size="large" className="mt-1" name="firstname" value={values.firstname} />
                    <span className="text-red-500"> {touched.firstname && errors.firstname}</span>   
                </Col>
                <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>Last Name</span>
                    <InputField readonly size="large" className="mt-1" name="lastname" value={values.lastname} />
                    <span className="text-red-500"> {errors.lastname && touched.lastname}</span>   
                </Col>
              </Row>
              
              <Row className='mt-10'>
                <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>Phone</span>
                    <InputField readonly size="large" className="mt-1" name="phone" value={values.phone} />
                    <span className="text-red-500"> {touched.phone && errors.phone}</span>   
                </Col>
                <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>Email</span>
                    <InputField readonly size="large" className="mt-1" name="email" value={values.email} />
                    <span className="text-red-500"> {errors.email && touched.email}</span>   
                </Col>
              </Row>
              
              <Row className='mt-10'>
              
              <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>State of Origin</span>
                    <InputField readonly size="large" className="mt-1" name="email" value={values.state_of_residence} />

                    <span className="text-red-500"> {errors.state && touched.state && errors.state}</span>   
                </Col>
                <Col span={11} className=''>
                    <span className='text-[#AEB5C2]'>LGA</span>
                    <InputField readonly size="large" className="mt-1" name="email" value={values.lga_of_residence} />
                    <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
                </Col>
            </Row>
            <Row className='mt-10'>
              
            <Col span={11} className='mr-10'>
                    <span className='text-[#AEB5C2]'>Date of Birth</span>
                    <InputField 
                      readonly
                      size="large" 
                      className="mt-1" 
                      name="dob" 
                      value={values.date_of_birth} 
                    />
                    <span className="text-red-500"> {errors.dob && touched.dob && errors.dob}</span>   
                </Col>
                <Col span={11}>
                    <span className='text-[#AEB5C2]'>Gender</span>
                    <InputField 
                      readonly
                      size="large" 
                      className="mt-1" 
                      name="gender" 
                      value={values.gender} 
                    />
                    <span className="text-red-500"> {errors.dob && touched.dob && errors.dob}</span>   
                </Col>
            </Row>
           
            <div className="md:flex gap-10 mt-10">

              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="button"
                  onClick={() => {
                    prev(data)
                  }}
                  className="h-10 flex justify-center items-center rounded-md text-center w-full"
                >
                  <span className="text-dark uppercase font-bold">
                    Prev
                  </span>
                </button>
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="submit"
                  className="h-10 flex justify-center items-center rounded-md text-white text-center bg-brand-primary w-full"
                >
                 {isSubmitting ? (
                    <span className="text-white uppercase font-bold">
                      Loading
                    </span>
                  ) : (
                    <span className="text-white uppercase font-bold">
                      Submit
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }