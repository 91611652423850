import { Col, Select } from "antd";
import { useFormik } from "formik";
import { InputField } from "../Inputs/Input";
import * as Yup from 'yup';

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


export const GuarantorForm = ({title, updateField, documentTypes}) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          guarantor_name: '',
          guarantor_email: '',
          guarantor_phone: '',
          guarantor_address: '',
          guarantor_bvn: '',
          document_type: '',
          file: ''
        },
        validationSchema: Yup.object({
          guarantor_name: Yup.string().min(3).required('Enter a guarantor name'),
          guarantor_email: Yup.string().email().required('This field is required'),
          guarantor_address: Yup.string().required('This field is required'),
          guarantor_phone: Yup.string().required('This field is required'),
          guarantor_bvn: Yup.string().required('Please provide a valid BVN for the guarantor'),
          document_type: Yup.string().required('Please Select a document type'),
          file: Yup.string().required('Please select a file to upload'),
        }),
        onSubmit: (values, {setSubmitting}) => {
        },
    });
    
    const handleChange = (field, value) => {
        formik.setFieldValue([field], value);
        const formData = formik.values;
        updateField({...formData, [field]: value});
    };

    return (
        <Col span={11} className="mr-10">
            <h1 style={{fontSize: 20}}>{title}</h1>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Guarantor Name</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='guarantor_name' 
                    onChange={(e) => handleChange('guarantor_name', e.target.value)}
                    onBlur={formik.handleBlur} 
                    value={formik.values.guarantor_name} 
                />
                <span className="text-red-500"> {formik.errors.guarantor_name}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Guarantor Email</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='guarantor_email' 
                    onChange={(e) => handleChange('guarantor_email', e.target.value)}
                    onBlur={formik.handleBlur} 
                    value={formik.values.guarantor_email} 
                />
                <span className="text-red-500"> {formik.errors.guarantor_email}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Guarantor Phone</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='guarantor_phone' 
                    onChange={(e) => handleChange('guarantor_phone', e.target.value)}
                    onBlur={formik.handleBlur} 
                    value={formik.values.guarantor_phone} 
                />
                <span className="text-red-500"> {formik.errors.guarantor_phone}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Guarantor Address</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='guarantor_address' 
                    onChange={(e) => handleChange('guarantor_address', e.target.value)}
                    onBlur={formik.handleBlur} 
                    value={formik.values.guarantor_address} 
                />
                <span className="text-red-500"> {formik.errors.guarantor_address}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Guarantor NIN</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='guarantor_bvn' 
                    onChange={(e) => handleChange('guarantor_bvn', e.target.value)}
                    onBlur={formik.handleBlur} 
                    value={formik.values.guarantor_bvn} 
                />
                <span className="text-red-500"> {formik.errors.guarantor_bvn}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Identity Document for Guarantor</span>
                <Select 
                    size="large"
                    showSearch
                    filterOption={filterOption}
                    className="customSelect mt-1"
                    options={documentTypes} 
                    defaultValue={formik.values?.document_type}
                    onChange={(value) => handleChange('document_type', value)}
                />
                <span className="text-red-500"> {formik.errors.document_type}</span>   
            </div>
            <div className="mt-5">
                <span className='text-[#AEB5C2]'>Upload Document</span>
                <InputField 
                    type="file" 
                    size='large' 
                    className='mt-1' 
                    name='file' 
                    onChange={(file) => handleChange('file', file)} 
                />
                <span className="text-red-500"> {formik.errors.file}</span>   
            </div>
            
        </Col>
    )
}