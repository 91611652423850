import {  Button, Col, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import airtime from "../../../assets/icons/airtime.svg";
import data_bundle from "../../../assets/icons/data.svg";
import cables from "../../../assets/icons/cable.svg";
import internet from "../../../assets/icons/internet.svg";
import power from "../../../assets/icons/electricity.svg";
import cloud_energy from "../../../assets/icons/cloud-energy.svg";
import steamaco from "../../../assets/icons/steamaco.svg";
import useSWR from 'swr';
import { LoaderComponent } from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
const icons = [
  airtime,
  data_bundle,
  cables,
  internet,
  power,
  cloud_energy,
  steamaco
]
const BillPayment = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const {data} = useSWR('bill-payment/bills');
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setBills(data?.data);
      setLoading(false)
      dispatch({type: 'SET_BILLS', payload: data?.data});
    }
    dispatch({type: 'SET_BILL_FORM', payload: null})
  }, [data]);
  
  return (
    <div className="md:p-10 p-5">
      
      <div className="md:flex">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Bill Payment
        </h2>
      </div>
      <div className="mt-10 bg-white rounded-lg md:p-10">
      {loading ?
        <LoaderComponent />
      : 
        <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 32 }} className="flex">
          {bills.map((item, i) => (
            <Col className="bill-payment" key={`bill-${i}`}>
              <Link to={`/bill-payment/${item.slug}`} className="item">
                <img
                  src={{
                    'airtime': airtime,
                    'data_bundle': data_bundle,
                    'cloud_energy': cloud_energy,
                    'power': power,
                    'internet': internet,
                    'cables': cables,
                    'steamaco': steamaco
                  }[item.slug]}
                  alt=""
                  className="cursor-pointer"
                />
                <span>{item.name}</span>
              </Link>
            </Col>
          ))}
        </Row>
        }
      </div>
      
    </div>
  );
};

export default BillPayment;
