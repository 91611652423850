import { Button, Modal, notification } from "antd";
import React from "react";
import { useState } from "react";
import success from "../../../assets/icons/greenSuc.svg";
import api from "../../../core-units/api";
import { useNavigate, useParams } from "react-router-dom";
import { ProductForm } from "../../components/Forms/ProductForm";
import { singleLease } from "../../../core-units/utils/queryFn";
import { useQuery } from "@tanstack/react-query";
import { BackButton } from "../../components/PayBills/BackButton";


const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false)

  const [notice, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, data) => {
    notice[type]({
      message: data.message,
      description: data.description,
    });
  };

  const product = useQuery({
    queryKey: ["product", id],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => singleLease(id),
  });
  // const cont = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateLease = (data) => {
    setSubmitting(true)
    api
      .post(`/lease-products/${product.data.id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setSubmitting(false)
        navigate('/products')
      })
      .catch((err) => {
        // console.log(err, "error");
        setSubmitting(false)
        openNotificationWithIcon("error", {message : 'Error', description : err.data.message})

      })
      // .finally(setSubmitting(false));
  };

  return (
    <div className="md:p-10 p-5">
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="md:py-16 py-8 ">
          <img src={success} alt="" className="w-[20%] mx-auto" />
          <p className="text-center mt-5">Product Successfully Updated</p>
          <Button
            // onClick={cont}
            type="text"
            className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
          >
            <span className="text-white uppercase font-bold">Continue</span>
          </Button>
        </div>
      </Modal>
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Edit Product Details
        </h2>
        <BackButton />
      </div>
      <div className="mt-10 bg-white rounded-lg md:p-10">
        {contextHolder}
        <div>
          <ProductForm onSubmit={updateLease} isSubmitting={isSubmitting} data={product.data} />
        </div>
      </div>
    </div>
  );
};

export default Edit;
