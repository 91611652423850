import React from 'react'
import logo from '../../../assets/images/logo.svg'
import { Input } from 'antd';
import { Formik } from 'formik';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin , notification} from "antd";
import {useNavigate} from 'react-router-dom'
import api from '../../../core-units/api';



const ForgotPassword = () => {
  const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
      );

  const login = (values, setSubmitting) => {
    console.log(values)
    // openNotificationWithIcon('success', {message : 'Title', description : 'Descript'})
    api.post('/auth/password-reset', values).then(async res => {
        console.log(res.message)
        openNotificationWithIcon('info', {message : res.message, description : ''})
        if(res.message === 'Email successfully sent'){
            setTimeout(() => {
                navigate('/auth')
            }, 3000)
        }
        setSubmitting(false)
    }).catch(err => {
        console.log(err)
        openNotificationWithIcon('error', {message : 'Error', description : err.data.message})
    })
  }
  const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
          message: data.message,
          description:data.description,
        });
      };
  const navigate = useNavigate()
  return (
    <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
        {contextHolder}
        <div className="md:w-3/5 w-screen rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
            <div className='py-20 mx-auto w-full'>
                <img src={logo} alt="" className='md:mx-auto ' />
                <div className="mt-10  w-[100%]">
                    <h2 className="text-[32px] text-brand-primary md:text-center">Password Reset</h2>
                    <p className=" md:text-center">Enter your email and a reset link will be sent to you </p>
                    <Formik
                        initialValues={{ email: '' }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                            errors.email = 'Email is required';
                            } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                            errors.email = 'Invalid email address';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log(values)
                            login(values, setSubmitting)
                        }}
                        >{({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                 <div className="mt-10 mx-auto md:w-[70%] w-full py-5">
                                    <span className='text-[#AEB5C2]'>Username or Email</span>
                                    <Input size='large' className='mt-1' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                    <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
                                </div>
                                <div className="md:w-[70%] w-full mx-auto mt-5">
                                    <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                        {isSubmitting ? <>
                                            <Spin indicator={antIcon} />
                                            <span className="ml-3 text-white">Loading</span>
                                        </> : <>Submit</>}
                                    </button>
                                    <p className="text-center text-[#AEB5C2] mt-10 cursor-pointer" onClick={() => navigate('/auth')}>Back to login</p>
                                </div>
                                </form>
                            )}
                        </Formik>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword