import React from 'react'
import { Table, Tooltip } from 'antd'
import download from '../../../assets/icons/download.svg'
import eye from '../../../assets/icons/eye.svg'
import {useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'
import { downloadReciept } from '../../../core-units/utils/queryFn'


const TableComp = ({data, onPageChange, perPage, total, page}) => {
    console.log(data, "ddd")
    const navigate = useNavigate()
  
      const view = (rec) => {
        console.log(rec)
        navigate(`/transactions/${rec.id}`)
      }
      
      const columns = [
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'date',
          render : (date) => (
            <span>{dayjs(date).format('DD/MM/YYYY')}</span>
          )
        },
        {
          title: 'Bill Type',
          dataIndex: 'biller_name',
          key: 'bill_type',
        },
        {
          title: 'From Account',
          key: 'from',
          render : (record) => (
            <><span>{record?.user?.wallet?.account_no}</span></>
          )
        },
        {
          title: 'To Account',
          key: 'to',
          render : (record) => (
            <>{record.bill_type === "transfer" ?<span>{record?.recipient?.account_no}</span> :
            record.bill_type === "airtime" ||record.bill_type ===  'data_bundle' ? <span>{record.customer}</span> :<span>{record.recipient?.account_no}</span> } </>
          )
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Action',
          render : (record) => (
            <div className='flex gap-4 w-full'>
              <Tooltip title="View Record">
                <img src={eye} alt="" className='cursor-pointer' onClick={() => view(record)} />
              </Tooltip>
              <Tooltip title="Dowload">
                <img src={download} alt="" className='cursor-pointer' onClick={() => downloadReciept(record.id)} />
              </Tooltip>
              {/* <Button type="text"  className='border border-brand-red bg-opacity-50 text-brand-red'>View More</Button> */}
            </div>
          )
        },
      ];
      console.log(total, perPage)
  return (
    <div className='h-[50vh] overflow-auto'>
        <Table 
          dataSource={data} 
          columns={columns} 
          rowKey={(record) => record.id}
          pagination={{ 
            defaultPageSize: 20, 
            total,
            current: page,
            // showSizeChanger: true, 
            // pageSizeOptions: ['10', '20', '30', '50', '100']
            onChange: (page, pageSize) => {
              onPageChange(page);
            },
          }}
          sticky={true} 
        />
    </div>
  )
}

export default TableComp