import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/logo.svg'
import { Card, Col, Row, Space } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin , notification} from "antd";
import {useNavigate, useSearchParams} from 'react-router-dom'
import { InputField } from '../../components/Inputs/Input';
import plain from '../../../assets/icons/plain.svg';
import success from '../../../assets/icons/success-no-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { verifyBVN, initiateBVN} from '../../../core-units/utils/queryFn';


const BvnNinVerification = ({ navigation }) => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );

    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
            message: data.message,
            description:data.description,
        });
    };

    const [searchParams, ] = useSearchParams();

    const status = searchParams.get('status');
    const message = searchParams.get('message');
    const reference = searchParams.get('productReference');
  

    const {registerData} = useSelector(state => state.user);
    const navigate = useNavigate();
    const [canSkip, setCanSkip] = useState(false);
    const [bvnSubmitting, setBvnSumitting] = useState(false);
    const [bvnVerified, setBvnVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bvn, setBvn] = useState(registerData?.bvn || '');
    const [bvnErr, setBvnErr] = useState('');

    const dispatch = useDispatch();


    useEffect(() => {
        if (reference){
            verifyBVNStatus();
        }
    }, [reference]);

    const validateBVN = () => {
        if (bvn !== '') {
            setBvnSumitting(true);
            dispatch({type: 'SET_REGISTER_DATA', payload: {...registerData, bvn}});
            initiateBVN({bvn, flag:'web'})
                .then(res => {
                    setBvnSumitting(false);
                    if (res.status) {
                        if (res.data.url) {
                            window.location.href = res.data.url;
                        } else {
                            
                        }
                    } else {
                        openNotificationWithIcon("error", {message: 'Something went wrong', description: res.message})
                    }
                })
                .catch(err => {
                    setBvnSumitting(false);
                    openNotificationWithIcon("error", {message: 'Something went wrong', description: ""})
                });
        } else {
            setBvnErr('Please enter your BVN')
        }
    };

    const verifyBVNStatus = async () => {
        setLoading(true);
        await verifyBVN({
            status,
            message,
            reference,
            flag: 'web'
        }).then(res => {
            setLoading(false);
            if (res.status) {
                openNotificationWithIcon("success", {message: 'Success', description: res.message})
                setBvnVerified(true)
            }else {
                openNotificationWithIcon("error", {message: 'Error', description: res.message})
            }
        }).catch(err => {
            setLoading(false);
            openNotificationWithIcon("error", {message: 'Internal Error', description: err.data.message})
        })
    }

    return (
        <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
            {contextHolder}
            <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
                <div className='py-20 mx-auto w-full'>
                    <img src={logo} alt="" className='md:mx-auto ' />
                    <div className="mt-10  w-[100%]">
                        <h2 className="text-[32px] text-brand-primary md:text-center">Account Setup</h2>
                        <p className=" md:text-center">Kindly provide the required information to continue </p>
                        
                        <Space direction="vertical" size={16} style={{marginLeft: 100}}>
                            <Row className='w-full' style={{borderBottomWidth: 3, borderColor: '#2f8fcd'}}>
                                <Col className='account-setup-tabs'>User Info</Col>
                                <Col className='account-setup-tabs'>Business Info</Col>
                                <Col className='account-setup-tabs active'>BVN & NIN</Col>
                                <Col className='account-setup-tabs'>PIN</Col>
                            </Row>
                            <Card title="BVN & NIN Verification" >
                                <div className="w-full mx-auto mt-5">
                                    <Row type="flex" align="middle">
                                        <Col className='mr-8 w-[80%]'>
                                            <span className='text-[#AEB5C2]'>Bank Verification Number (BVN)</span>
                                            <InputField 
                                                size="large" 
                                                className="mt-1" 
                                                name="bvn" 
                                                onChange={(e) => setBvn(e.target.value)} value={bvn} 
                                            />
                                            <span className="text-red-500"> {bvnErr}</span>   
                                        </Col>
                                        <Col span={3}>
                                            <button 
                                                onClick={validateBVN}
                                                className='bg-brand-secondary rounded-lg p-3 px-5 mt-7'
                                            >
                                                {bvnSubmitting ? <>
                                                    <Spin indicator={antIcon} />
                                                </>
                                                :
                                                bvnVerified ?
                                                    <img src={success} style={{height: 24}} />

                                                    :
                                                     <img src={plain} />
                                            }
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-10'>
                                        <Col className='mr-8 w-[80%]'>
                                            <span className='text-[#AEB5C2]'>National Identification Number (NIN)</span>
                                            <InputField size="large" className="mt-1" name="nin" />
                                        </Col>
                                        <Col span={3}>
                                            <button className='bg-brand-secondary rounded-lg p-3 px-5 mt-7'>
                                                <img src={plain} />
                                            </button>
                                        </Col>
                                    </Row>
                                    
                                    <Row className='mt-10'>
                                        <Col span={11} className='mr-10'>
                                            <span className='text-[#AEB5C2]'>&nbsp;</span>
                                            <button onClick={() => navigate(-1)} type="button" className='bg-brand-tertiary w-full text-white font-bold p-3 rounded-lg'>
                                                Back
                                            </button>
                                        </Col>
                                        <Col span={11}>
                                            <span className='text-[#AEB5C2]'>&nbsp;</span>
                                            <button 
                                                type="submit"  
                                                className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'
                                                disabled={canSkip || loading}
                                                onClick={() => navigate('/pin-setup')}
                                            >
                                                {loading ? <>
                                                                <Spin indicator={antIcon} />
                                                                <span className="ml-3 text-white">Verifying...</span>
                                                            </> : <>CONTINUE</>}
                                                
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BvnNinVerification;