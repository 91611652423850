import React from "react";
import { Button, Spin, Switch, Modal, notification } from "antd";
import circledPlus from "../../../assets/icons/circledPlus.svg";
import { useNavigate, useParams } from "react-router-dom";
import edit from "../../../assets/icons/edit.svg";
import del from "../../../assets/icons/del.svg";
import { formatNumber } from "../../../core-units/utils";
import { useQuery } from "@tanstack/react-query";
import { singleLease } from "../../../core-units/utils/queryFn";
import userGroup from "../../../assets/icons/userGroup.svg";
import calender from "../../../assets/icons/calender.svg";
import { useState } from "react";
import CircleClose from "../../../assets/icons/circleClose.svg";
import iconInfo from "../../../assets/icons/infoBlue.svg";
import myLease from "../../../assets/icons/my-lease.svg";
import plane from "../../../assets/icons/plain.svg";
import api from "../../../core-units/api";
import { VerifyLease } from "../Lease/VerifyLease";
import { BackButton } from "../../components/PayBills/BackButton";

const View = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doVerify, setDoVerify] = useState(false);
  const [options, setOptions] = useState({})
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const [notice, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, data) => {
    notice[type]({
      message: data.message,
      description: data.description,
    });
  };

  const product = useQuery({
    queryKey: ["product", id],
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: () => singleLease(id),
  });


  const changeStat = (checked) => {
    console.log(checked);
    // setIsModalOpen(true);
    setOptions({
        message : 'Do you want to deactivate product?',
        btnAction : () => deactivate(checked ? 1 : 0)
    })
    setIsModalOpen(true)
  };

  const handleCancel = () => {
    // product.remove();
    setIsModalOpen(false);
  };

  const deactivate = (stat) => {
    let data = product.data
    data.status = stat
    api.post(`/lease-products/${data.id}`, data).then(res => {
        handleCancel()
    }).catch(err => {
        console.log(err)
    })
  }

  const deleteLease = () => {
    api.delete(`/lease-products/${product.data.id}`).then(res => {
        console.log(res)
        navigate('/products')
    }).catch(err => {
        console.log(err)
    })
  }

  const delx = () => {
    setOptions({
        message : 'Do you want to delete this product?',
        btnAction : deleteLease
    })
    setIsModalOpen(true)
  }

  const doSubscribe = () => {
    // if (user?.bvn_isverified === 1) {
      setDoVerify(true);
    // } else {
    //   openNotificationWithIcon("error", {message : 'Verification Error', description : 'Plese complete your BVN verification to continue'})
    // }
  }

  
  return (
    <div className="md:p-10 p-5">
            {contextHolder}
      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closeIcon={<img src={CircleClose} alt="" />}
      >
        <div className="flex h-full w-full items-center justify-center p-10">
          <div>
            <img src={iconInfo} alt="" className=" mx-auto" />
            <p className="mt-8">{options.message}</p>
            <div className="flex justify-around w-full mt-10">
              <Button
                onClick={options.btnAction}
                type="text"
                className=" uppercase bg-brand-primary font-bold "
              >
                <span className="text-white">CONTINUE</span>
              </Button>
              <Button
                onClick={handleCancel}
                type="text"
                className=" uppercase bg-brand-secondary font-bold "
              >
                <span className="text-white">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          View Product
        </h2>
        <div className="ml-auto flex justify-between gap-5">
          {user?.role === 'ADMIN' ? 
          <Button
            className="h-full bg-brand-secondary border-0  flex w-full justify-center items-center  gap-3 "
            size="large"
            onClick={() => navigate('/lease/add')}
          >
            <img src={circledPlus} alt="" />
            <span className="text-white ">Add Product</span>
          </Button>
          : 
            <Button
              className="h-full w-[250px] bg-brand-secondary border-0  flex w-full justify-center items-center  gap-3 "
              size="large"
              onClick={() => navigate('/lease/me')}
            >
              <img src={myLease} alt="" />
              <span className="text-white ">My Lease</span>
            </Button>
          }
          <BackButton />
        </div>
      </div>
      <div className="bg-white rounded-lg md:p-16 mt-5">
        {product.data && (
          <div>
            {user?.role === 'ADMIN' &&
            <div className="flex justify-end gap-5">
              <img src={edit} style={{cursor: 'pointer'}} alt="" onClick={() => navigate(`/lease/${product.data.id}/edit`)} />
              <img src={del} style={{cursor: 'pointer'}} alt="" onClick={delx} />
            </div>}
            <div className="md:flex gap-5 xl:gap-24">
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <div className="h-[175px] w-full overflow-x-auto  rounded-t-md" 
                style={{
                  backgroundImage: `url(${product.data.image_url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain'
                }}>
                  {/* <img
                    src={product.data.image_url}
                    className=""
                    alt=""
                    style={{
                      height: '100%',
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  /> */}
                </div>
                <div className="mt-3 bg-brand-primary px-5 py-2 rounded-md flex justify-between">
                  <div className="bg-white rounded-full text-brand-primary w-60 py-1 px-2 text-left">
                    Total Amount Payable
                  </div>
                  <div className=" font-bold text-white  py-1 text-center">
                    {formatNumber(product.data.amount)}
                  </div>
                </div>
                <div className="mt-3 bg-[#DADADA] px-5 py-2 rounded-md flex justify-between">
                  <div className="bg-white rounded-full text-brand-primary w-60 py-1 px-2 text-left">
                    Initial Down Payment
                  </div>
                  <div className=" font-bold text-white  py-1 text-center">
                    {formatNumber(product.data.initial_payment)}
                  </div>
                </div>
                <div className="mt-3 bg-[#DADADA] px-5 py-2 rounded-md flex justify-between">
                  <div className="bg-white rounded-full text-brand-primary w-60 py-1 px-2 text-left">
                    Monthly Payment
                  </div>
                  <div className=" font-bold text-white  py-1 text-center">
                    {formatNumber(product.data.monthly_payment)}
                  </div>
                </div>
                <div className="mt-3 bg-[#DADADA] px-5 py-2 rounded-md flex justify-between">
                  <div className="bg-white rounded-full text-brand-primary w-60 py-1 px-2 text-left">
                    Duration
                  </div>
                  <div className=" font-bold text-brand-base py-1  text-right">
                    {product.data.lease_duration} Months
                  </div>
                </div>
                
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0 rounded-t-md">
                <h5 className="font-bold text-lg text-brand-primary">
                  {product.data.product_name}
                </h5>
                <p className=" text-brand-base">{product.data.category.name}</p>
                <div className="bg-[#F8F8F8] p-3  cursor-pointer">
                  <div className="flex mt-2 gap-3 justify-between">
                    <div className="flex gap-2 w-1/3">
                      <img src={userGroup} alt="" />
                      <span className=" text-brand-base">
                        {product.data.subscribers_count}
                      </span>
                    </div>
                    <div className="flex gap-2 w-1/3 items-center ">
                      <img src={calender} alt="" />
                      <span className=" text-brand-base mt-1">
                        {product.data.lease_duration} Months
                      </span>
                    </div>
                    <div className="flex gap-2 w-1/3 justify-end">
                      {user?.role === 'ADMIN' &&
                        <Switch
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          defaultChecked={product.data.status}
                          onChange={changeStat}
                        />
                      }
                    </div>
                  </div>
                </div>
                <p className="mt-10 text-brand-base" style={{lineHeight: 2}}>
                  {product.data.description}
                </p>
                {user?.role !== 'ADMIN' &&
                  <Button
                    className="bg-brand-secondary border-0  flex justify-center items-center  gap-3 "
                    size="large"
                    onClick={doSubscribe}
                  >
                    <img src={plane} alt="" />
                    <span className="text-white font-bold">SUBSCRIBE NOW</span>
                  </Button>
                }
              </div>
            </div>
          </div>
        )}
        {product.isLoading && (
          <div className="flex h-full mt- items-center  justify-center">
            {" "}
            <Spin size="large" />
          </div>
        )}
      </div>
      <VerifyLease 
        isModalOpen={doVerify} 
        handleClose={() => setDoVerify(false)} 
        navigate={navigate} e
        leaseId={id}
      />
    </div>
  );
};

export default View;
