import React from 'react'
import logo from '../../../assets/images/logo.svg'
import success from '../../../assets/icons/success-1.svg'
import { Card, Space } from 'antd';
import {useNavigate} from 'react-router-dom'


const RegistrationComplete = ({ navigation }) => {

  const navigate = useNavigate()
  return (
    <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
        <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
            <div className='py-20 mx-auto w-full'>
                <img src={logo} alt="" className='md:mx-auto ' />
                <div className="mt-10 mx-auto  w-[100%]">
                    <h2 className="text-[32px] text-brand-primary md:text-center">Registration Complete</h2>
                    
                    <Space direction="vertical" size={16} style={{display: 'flex', alignItems: 'center'}}>
                        <Card className='mx-auto md:text-center w-[100%]'>
                            <img src={success} alt="" className='md:mx-auto my-10' />
                            <div className='mx-auto md:text-center'>
                                <h1 style={{fontSize: 25}}>Account Created</h1>
                                <p>Your account has been created successfully.<br /> Click proceed to access your account.</p>
                            </div>
                            <div className="md:w-[70%] mx-auto mt-5">
                                <button 
                                    onClick={() => navigate('/')}
                                    type="button" 
                                    className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                    PROCEED
                                </button>
                            </div>
                        </Card>
                    </Space>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RegistrationComplete;