import React from 'react'
import logo from '../../../assets/images/logo.svg'
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin , notification} from "antd";
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup';
import { register } from '../../../core-units/utils/queryFn';
import { InputField } from '../../components/Inputs/Input';

const SignUpSchema = Yup.object({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    firstname: Yup.string().max(255).required('First name is required'),
    lastname: Yup.string().max(255).required('Last name is required'),
    phone: Yup.string().max(11).required('Enter a valid phone number'),
    password: Yup.string().max(255).required('Password field is required'),
    policy: Yup.boolean().oneOf([true], 'You have not accepted our T&C'),
})


const Register = () => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );
    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
        message: data.message,
        description:data.description,
        });
    };
    const navigate = useNavigate();

    const submit = (values, setSubmitting) => {
        // openNotificationWithIcon('success', {message : 'Title', description : 'Descript'})
        register(values).then(async res => {
            setSubmitting(false)
            if (res.status) {
                openNotificationWithIcon('info', {message : res.message, description : ''})
                setTimeout(() => {
                    navigate('/email-verification?email=' + values.email)
                }, 3000)
            } else {
                openNotificationWithIcon('error', {message : res.message, description : ''})
            }
        }).catch(err => {
            console.log(err)
            openNotificationWithIcon('error', {message : 'Error', description : err.data.message})
        })
    }
  
  return (
    <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
        {contextHolder}
        <div className="md:w-3/5 w-screen rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
            <div className='py-20 mx-auto w-full'>
                <img src={logo} alt="" className='md:mx-auto ' />
                <div className="mt-10  w-[100%]">
                    <h2 className="text-[32px] text-brand-primary md:text-center">Create New Account</h2>
                    <p className=" md:text-center">Kindly provide the required information to continue </p>
                    <Formik
                        initialValues={{ 
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            password: '',
                            policy: false,
                            device_no: '',
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            console.log(values)
                            submit(values, setSubmitting)
                        }}
                        >{({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => (
                            <div className="w-full mx-auto mt-10" style={{marginLeft: 20}}>

                                <form onSubmit={handleSubmit} className=''>
                                    <Row type="flex" align="middle">
                                        <Col span={11} className='mr-10'>
                                            <span className='text-[#AEB5C2]'>First Name</span>
                                            <InputField size="large" className="mt-1" name="firstname" onChange={handleChange} onBlur={handleBlur} value={values.firstname} />
                                            <span className="text-red-500"> {errors.firstname && touched.firstname && errors.firstname}</span>   
                                        </Col>
                                        <Col span={11}>
                                            <span className='text-[#AEB5C2]'>LastName</span>
                                            <InputField size="large" className="mt-1" name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname} />
                                            <span className="text-red-500"> {errors.lastname && touched.lastname && errors.lastname}</span>   
                                        </Col>
                                    </Row>
                                    <Row className='mt-10'>
                                        <Col span={11} className='mr-10'>
                                            <span className='text-[#AEB5C2]'>Email Address</span>
                                            <InputField size="large" className="mt-1" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                            <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
                                        </Col>
                                        <Col span={11}>
                                            <span className='text-[#AEB5C2]'>Phone Number</span>
                                            <InputField 
                                                size="large" 
                                                className="mt-1 input-prefix" 
                                                name="phone" 
                                                onChange={handleChange} 
                                                onBlur={handleBlur} 
                                                value={values.phone} 
                                                prefix="+234"
                                            />
                                            <span className="text-red-500"> {errors.phone && touched.phone && errors.phone}</span>   
                                        </Col>
                                    </Row>
                                    <Row className='mt-10'>
                                        <Col span={11} className='mr-10'>
                                            <span className='text-[#AEB5C2]'>Password</span>
                                            <InputField type="password" size='large' className='mt-1' name='password' onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                            <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
                                        </Col>
                                        <Col span={11}>
                                            <span className='text-[#AEB5C2]'>Confirm Password</span>
                                            <InputField 
                                                type="password" 
                                                size='large' 
                                                className='mt-1' 
                                                name='confirm_password' 
                                                onChange={handleChange} 
                                                onBlur={handleBlur} 
                                                value={values.confirm_password} 
                                            />
                                            <span className="text-red-500"> {errors.confirm_password && touched.confirm_password && errors.confirm_password}</span>   
                                        </Col>
                                    </Row>
                                    <Row className='mt-10'>
                                        <Col span={11} className='mr-10 flex'>
                                            <InputField 
                                                type="checkbox" 
                                                className='mt-1' 
                                                name='policy' 
                                                onChange={handleChange} 
                                                value={values.policy} 
                                                label={`I have read and agreed to the <a href='#'>terms</a> and <a href='#'>privacy policy</a>`}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="md:w-[70%] mx-auto mt-5">
                                        <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                            {isSubmitting ? <>
                                                <Spin indicator={antIcon} />
                                                <span className="ml-3 text-white">Loading</span>
                                            </> : <>Continue</>}
                                        </button>
                                        <p className="text-center text-[#AEB5C2] mt-10 cursor-pointer" onClick={() => navigate('/auth')}>Back to <span className='text-brand-primary'>Login</span></p>
                                    </div>
                                </form>
                            </div>
                            )}
                        </Formik>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register