import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/logo.svg'
import { Card, Col, Input, Row, Select, Space } from 'antd';
import { Formik } from 'formik';
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup';
import { getLgas } from '../../../core-units/utils/queryFn';
import { InputField } from '../../components/Inputs/Input';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

const FormSchema = Yup.object({
    address: Yup.string().max(255).required('Please enter a contact address'),
    state: Yup.number().required('State field is required'),
    dob: Yup.string().max(255).required('Your date of birth is required'),
    employment_status: Yup.number().required('Field is required'),
    avg_monthly_salary: Yup.string().required('This field must be checked'),
});

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const AccountSetup = () => {
    const [userTypes, setUserTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([]);
    const [statuses, setStatuses] = useState([]);
    
    const {data: statesData, error} = useSWR('utilities/states?q=161');
    const {data: empStatusData} = useSWR('utilities/employment-status');
    const {data: userTypeData} = useSWR('user-types');
    const {registerData} = useSelector(state => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (statesData && statesData.status) {
          setStates(statesData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [statesData]);
    
    useEffect(() => {
        if (userTypeData && userTypeData.status) {
          setUserTypes(userTypeData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [userTypeData]);
    
    useEffect(() => {
        if (empStatusData && empStatusData.status) {
          setStatuses(empStatusData.data.map(item => ({value: item.id, label: item.name})));
        }
    }, [empStatusData]);

    useEffect(() => {
        if (registerData && registerData.state && lgas.length < 1)
            fetchLgas(registerData.state);

    }, [registerData]);

    const fetchLgas = async state_id => {
        await getLgas(state_id).then(res => {
            if (res.status) {
                setLgas(res.data.map(item => ({value: item.id, label: item.name})));
            }
        });
    };

    return (
        <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
            <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
                <div className='py-20 mx-auto w-full'>
                    <img src={logo} alt="" className='md:mx-auto ' />
                    <div className="mt-10  w-[100%]">
                        <h2 className="text-[32px] text-brand-primary md:text-center">Account Setup</h2>
                        <p className=" md:text-center">Kindly provide the required information to continue </p>
                        
                        <Space direction="vertical" size={16} style={{marginLeft: 30}}>
                            <Row className='w-full' style={{borderBottomWidth: 3, borderColor: '#2f8fcd'}}>
                                <Col className='account-setup-tabs active'>User Info</Col>
                                <Col className='account-setup-tabs'>Business Info</Col>
                                <Col className='account-setup-tabs'>BVN & NIN</Col>
                                <Col className='account-setup-tabs'>PIN</Col>
                            </Row>
                            <Card title="User Information" >
                            
                                <Formik
                                    initialValues={{ 
                                        address: registerData?.address || '',
                                        state: registerData?.state || '',
                                        state_name: registerData?.state_name || '',
                                        lga: registerData?.lga || '',
                                        lga_name: registerData?.lga_name || '',
                                        dob: registerData?.dob || '',
                                        employment_status: registerData?.employment_status || '',
                                        employment_status_name: registerData?.employment_status_name || '',
                                        avg_monthly_salary: registerData?.avg_monthly_salary || '',
                                        user_type: registerData?.user_type || '',
                                        user_type_name: registerData?.user_type_name || '',
                                        partner_code: ''
                                    }}
                                    validationSchema={FormSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        dispatch({type: 'SET_REGISTER_DATA', payload: values});

                                        if (values.user_type === 1) {
                                            navigate("/bvn-nin-verification")
                                        } else {
                                            navigate("/business-setup")
                                        }
                                    }}
                                >{({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    handleSubmit,
                                        /* and other goodies */
                                }) => (
                                    <div className="w-full mx-auto mt-5">

                                        <form onSubmit={handleSubmit} className=''>
                                            <Row type="flex" align="middle">
                                                <Col span={11} className='mr-10'>
                                                    <span className='text-[#AEB5C2]'>Contact Address</span>
                                                    <InputField size="large" className="mt-1" name="address" onChange={handleChange} onBlur={handleBlur} value={values.address} />
                                                    <span className="text-red-500"> {errors.address && touched.address && errors.address}</span>   
                                                </Col>
                                                <Col span={11}>
                                                    <span className='text-[#AEB5C2]'>State of Origin</span>
                                                    <Select 
                                                        size="large"
                                                        showSearch
                                                        className="customSelect mt-1"
                                                        options={states} 
                                                        defaultValue={values?.state}
                                                        filterOption={filterOption}
                                                        onChange={(value) => {
                                                            const selectedItem = states.find(item => item.value === value)
                                                            fetchLgas(value);
                                                            setFieldValue('state', value);
                                                            setFieldValue('state_name', selectedItem.label);
                                                        }}
                                                    />
                                                    <span className="text-red-500"> {errors.state && touched.state && errors.state}</span>   
                                                </Col>
                                            </Row>
                                            <Row className='mt-10'>
                                                <Col span={11} className='mr-10'>
                                                    <span className='text-[#AEB5C2]'>LGA</span>
                                                    <Select 
                                                        size="large"
                                                        showSearch
                                                        filterOption={filterOption}
                                                        className="customSelect mt-1"
                                                        options={lgas} 
                                                        defaultValue={values?.lga}
                                                        onChange={(value) => {
                                                            const selectedItem = lgas.find(item => item.value === value)
                                                            setFieldValue('lga', value);
                                                            setFieldValue('lga_name', selectedItem.lable);
                                                        }}
                                                    />
                                                    <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
                                                </Col>
                                                <Col span={11}>
                                                    <span className='text-[#AEB5C2]'>Date of Birth</span>
                                                    <InputField 
                                                        type="date" 
                                                        size="large" 
                                                        className="mt-1" 
                                                        name="dob" 
                                                        onChange={(date) => setFieldValue('dob', date)} 
                                                        value={dayjs(values.dob || new Date())} 
                                                    />
                                                    <span className="text-red-500"> {errors.dob && touched.dob && errors.dob}</span>   
                                                </Col>
                                            </Row>
                                            <Row className='mt-10'>
                                                <Col span={11} className='mr-10'>
                                                    <span className='text-[#AEB5C2]'>Employment Status</span>
                                                    <Select 
                                                        size="large"
                                                        showSearch
                                                        filterOption={filterOption}
                                                        className="customSelect mt-1"
                                                        options={statuses} 
                                                        defaultValue={values?.employment_status}
                                                        onChange={(value) => {
                                                            const selectedItem = statuses.find(item => item.value === value)
                                                            setFieldValue('employment_status', value);
                                                            setFieldValue('employment_status_name', selectedItem.lable);
                                                        }}
                                                    />
                                                    <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
                                                </Col>
                                                <Col span={11}>
                                                    <span className='text-[#AEB5C2]'>Average Monthly Income</span>
                                                    <InputField type="number" size='large' className='mt-1' name='avg_monthly_salary' onChange={handleChange} onBlur={handleBlur} value={values.avg_monthly_salary} />
                                                    <span className="text-red-500"> {errors.avg_monthly_salary && touched.avg_monthly_salary}</span>   
                                                </Col>
                                            </Row>
                                            <Row className='mt-10'>
                                                <Col span={11} className='mr-10'>
                                                    <span className='text-[#AEB5C2]'>User Type</span>
                                                    <Select 
                                                        size="large"
                                                        showSearch
                                                        filterOption={filterOption}
                                                        className="customSelect mt-1"
                                                        options={userTypes} 
                                                        defaultValue={values?.user_type}
                                                        onChange={(value) => {
                                                            setFieldValue('user_type', value);
                                                        }}
                                                    />
                                                    <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
                                                </Col>
                                                {/* <Col span={11}>
                                                    <span className='text-[#AEB5C2]'>Partner Code</span>
                                                    <InputField size='large' className='mt-1' name='partner_code' onChange={handleChange} onBlur={handleBlur} value={values.partner_code} />
                                                    <span className="text-red-500"> {errors.partner_code && touched.partner_coded}</span>   
                                                </Col> */}
                                            </Row>
                                            <div className="md:w-[70%] mx-auto mt-5">
                                                <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                                    Continue
                                                </button>
                                                <p className="text-center text-[#AEB5C2] mt-10 cursor-pointer" onClick={() => navigate('/auth')}>Back to <span className='text-brand-primary'>Login</span></p>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                </Formik>
                            </Card>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSetup;