import axios from "axios";
import { API } from "../../config";


const http = axios;

const api = http.create({
  baseURL: API,
  timeout: 45000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers['x-device-type'] = 'WEB';

    const token = window.localStorage.getItem("sess") ?? "";

    if (token)
      config.headers.Authorization = "Bearer " + token;

    const apiKey = window.localStorage.getItem("apiKey") ?? "";

    if (apiKey)
      config.headers['x-cpayapi-key'] = apiKey;

    return config;
  },
  function (err) {
    // Do something with request error
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  function (err) {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.error === "invalid_token"
    ) {
      localStorage.clear();
      window.location = "/auth";
    }
    return Promise.reject(err.response);
  }
);

export default api