import { useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import { formatNumber } from '../../../core-units/utils';
import addIcon from '../../../assets/icons/circledPlus.svg';
import success from '../../../assets/icons/success-green.svg';
import download from '../../../assets/icons/download.svg';

export const PaymentSuccessful = () => {
    // const { type } = useParams();
    const {activeBill} = useSelector(state => state.billpayment);
    const {formData } = useSelector(state => state.billpayment);
    // const navigate = useNavigate();
    
    return (
        <div className="md:p-10 p-5">
            <div className="md:flex justify-between">
                <h2 className="md:text-[32px] text-[24px] mb-0 text-brand-primary">
                    {activeBill?.name}
                </h2>
            </div>

            <div className="mt-10 bg-white rounded-md md:p-10">
                <div className="bill-wrapper">
                    <img src={success} alt="success" className='md:mx-auto my-5' />
                    <h1 className="text-brand-primary font-[500] text-center">Payment Successful</h1>
                    <ul className='bill-info'>
                        
                        <li className='flex justify-between'>
                            <span>Description</span>
                            <span className='font-[600]'>{activeBill?.name}</span>
                        </li> 
                        <li className='flex justify-between'>
                            <span>Amount</span>
                            <span className='font-[600]'>{formatNumber(formData?.amount)}</span>
                        </li> 
                        {formData?.bill_type === 'airtime' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Network Provider</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {(formData?.bill_type === 'data_bundle' || formData?.bill_type === 'internet') && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Network Provider</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>{formData?.bill_type === 'data_bundle' ? 'Data Bundle' : 'Internet Plan'}</span>
                                <span className='font-[600]'>{formData?.biller_name}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'power' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Biller</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Account Number/User ID</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'cables' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Biller</span>
                                <span className='font-[600]'>{formData?.provider}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Package</span>
                                <span className='font-[600]'>{formData?.biller_name}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>IUC Number</span>
                                <span className='font-[600]'>{formData?.customer}</span>
                            </li> 
                        </>)}


                        {formData?.bill_type === 'cloudenergybill' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>Account Reference</span>
                                <span className='font-[600]'>{formData?.account_reference}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Phone Number</span>
                                <span className='font-[600]'>{formData?.phone}</span>
                            </li> 
                        </>)}

                        {formData?.bill_type === 'steamaco' && 
                        (<>
                            <li className='flex justify-between'>
                                <span>SteamaCo ID</span>
                                <span className='font-[600]'>{formData?.steamaco_id}</span>
                            </li> 
                            <li className='flex justify-between'>
                                <span>Customer Name</span>
                                <span className='font-[600]'>{formData?.account_name}</span>
                            </li> 
                        </>)}
                        <li className='flex justify-center'>
                            <img src={download} alt='adfas' className='mr-2' />
                            <span className='font-[500]'>DONWLOAD RECEIPT</span>
                        </li> 
                    </ul>
                    
                    <div className="mt-5 flex">
                        <Link to={`/bill-payment`} className='bg-brand-primary text-center w-[50%] mr-2 text-white font-bold p-3 rounded-lg'>
                            BACK TO HOME
                        </Link>
                        <Link to="/add-beneficiary" className='bg-brand-secondary w-[50%] flex justify-center text-white font-bold p-3 rounded-lg'>
                            <img src={addIcon} alt="beneficiary" className='mr-2' /> <span>BENEFICIARY</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}