import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import SideBar from '../components/pageBuilder/SideBar'
import Header from '../components/pageBuilder/Header'
// import api from '../../core-units/api'
import {useQuery} from '@tanstack/react-query'
import { getUser } from '../../core-units/utils/queryFn'
import logo from '../../assets/images/logo.svg'





const DefaultLayout = () => {
  const user = useQuery({ queryKey: ['user'], queryFn: () => getUser(), retry : false, refetchOnWindowFocus: false, } )
  if(user.isLoading){
    return (
      <div className="w-screen h-screen bg-brand-primary bg-opacity-50 flex justify-center content-center align-middle">
        {/* <div className=" border border-red-500 "> */}
            <img src={logo} alt="" className='w-[50%]'  />
        {/* </div> */}
      </div>
    )
  }
  if(user.isError){
    return (
      <Navigate to={'/auth'} />
    )
  }
  
  if(user.data && user.data.data.iscomplete === 1){
    localStorage.setItem('user', JSON.stringify(user.data.data))

    return (
      <div className='flex'>
          <div className="min-w-[20%] top-0 left-0 md:fixed hidden md:block">
              <SideBar user={user?.data?.data}/>
          </div>
          <div className="md:w-[80%] w-screen min-h-screen md:bg-[#ECF3FF] ml-auto">
              <Header user={user?.data?.data}/>
              <Outlet context={[user]}/>
          </div>
      </div>
    )
  } else {
    return (
      <Navigate to={'/account-setup'} />
    )
  }
}

export default DefaultLayout