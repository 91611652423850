import React from "react";
import rejected from "../../../assets/icons/circleClose-white.svg";
import verified from "../../../assets/icons/verified-white.svg";
import { Button } from "antd";

export const LeaseApplicationStatusBtn = ({status}) => {
    if(status === 0) {
        return <Button
        size="large"
        className="btn-pending w-full px-5 py-2 border-0 mb-2 text-white flex mx-auto justify-center items-center  gap-1  "
      >
        <img src={verified} alt="" />
        <span>Pending</span>
      </Button>
    } else if (status === 1) {
       return  <Button
            size="large"
            className="btn-approved  w-full px-5 py-2 border-0 mb-2 text-white flex mx-auto justify-center items-center  gap-1  "
          >
            <img src={verified} alt="" />
            <span>Approved</span>
          </Button>
    } else {
       return  <Button
            size="large"
            className="btn-rejected  w-full px-5 py-2 border-0 mb-2 text-white flex mx-auto justify-center items-center  gap-1  "
          >
            <img src={rejected} alt="" />
            <span>Rejected</span>
          </Button>
    }
}