/* eslint eqeqeq: 0 */
import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import {persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

import reducers from './reducers/rootReducer';
import history from '../core-units/utils/history';

const persistConfig = {
	key: 'root',
	storage,
}

let middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
	const logger = createLogger({
		collapsed: true,
	});
	middlewares = [...middlewares, logger];
}
middlewares = [...middlewares, routerMiddleware(history)];

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default store;

