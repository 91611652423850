import { createBrowserRouter } from "react-router-dom";
import Auth from "../../views/pages/Auth";
import ForgotPassword from "../../views/pages/Auth/ForgotPassword"; 
import DefaultLayout from "../../views/layout/DefaultLayout";
import Dashboard from "../../views/pages/Dashboard";
import Transactions from "../../views/pages/Transactions/Index";
import SingleTransaction from "../../views/pages/Transactions/SingleTransaction";
import Products from "../../views/pages/Products";
import Add from "../../views/pages/Products/Add";
import View from "../../views/pages/Products/View";
import Lease from "../../views/pages/Lease";
import ViewLease  from '../../views/pages/Lease/View'
import BillPayment from "../../views/pages/BillPayment";
import Customers from "../../views/pages/Customers";
import Partners from "../../views/pages/Partners";
import Register from "../../views/pages/Auth/Register";
import AccountSetup from "../../views/pages/Auth/AccountSetup";
import BusinessSetup from "../../views/pages/Auth/BusinessSetup";
import BvnNinVerification from "../../views/pages/Auth/BvnNinVerification";
import PinSetup from "../../views/pages/Auth/PinSetup";
import RegistrationComplete from "../../views/pages/Auth/RegistrationComplete";
import BillForm from "../../views/pages/BillPayment/BillForm";
import { Confirmation } from "../../views/pages/BillPayment/Confirmation";
import { PaymentSuccessful } from "../../views/pages/BillPayment/PaymentSuccessful";
import EmailVerification from "../../views/pages/Auth/EmailVerification";
import WalletHome from "../../views/pages/Wallet";
import ViewDetails from "../../views/pages/Customers/ViewDetails";
import MyLease from "../../views/pages/Lease/MyLease";
import { TransactionStatus } from "../../views/pages/Wallet/TransactionStatus";
import { Guarantors } from "../../views/pages/Lease/Guarantors";
import UserLeaseView from "../../views/pages/Lease/UserLeaseView";
import Edit from "../../views/pages/Products/Edit";

export const router = createBrowserRouter([
    {
        path: '/',
        element:  <DefaultLayout/>,
        children : [
            {
                path : '/',
                element : <Dashboard/>
            },
            {
                path : '/transactions',
                element : <Transactions/>
            },
            {
                path : '/transactions/:id',
                element : <SingleTransaction/>
            },
            {
                path : '/transaction/:ref/status',
                element : <TransactionStatus/>
            },
            {
                path : '/products',
                element : <Products/>
            },
            {
                path : '/lease',
                element : <Lease/>
            },
            {
                path : '/lease/add',
                element : <Add/>
            },
            {
                path : '/lease/me',
                element : <MyLease />
            },
            {
                path : '/lease/:id/view',
                element : <View/>
            },
            {
                path : '/lease/:id/edit',
                element : <Edit />
            },
            {
                path : '/lease/:id/status',
                element : <UserLeaseView/>
            },
            {
                path : '/lease/:id/application',
                element : <ViewLease/>
            },
            {
                path : '/lease/:id/guarantor',
                element : <Guarantors />
            },
            {
                path : '/bill-payment',
                element : <BillPayment />
            },
            {
                path : '/bill-payment/:type',
                element : <BillForm />
            },
            {
                path : '/bill-payment/:type/preview-confirm',
                element : <Confirmation />
            },
            {
                path : '/bill-payment/:type/success',
                element : <PaymentSuccessful />
            },
            {
                path : '/customers',
                element : <Customers />
            },
            {
                path : '/customers/:id',
                element : <ViewDetails />
            },
            {
                path : '/wallet',
                element : <WalletHome />
            },
            {
                path : '/financial-partners',
                element : <Partners />
            },
        ]
    },
    {
        path: '/auth',
        element:  <Auth/>
    },
    {
        path: '/register',
        element:  <Register />
    },
    {
        path: '/account-setup',
        element:  <AccountSetup />
    },
    {
        path: '/business-setup',
        element:  <BusinessSetup />
    },
    {
        path: '/forgot-password',
        element:  <ForgotPassword/>
    },
    {
        path: '/bvn-nin-verification',
        element:  <BvnNinVerification/>
    },
    {
        path: '/pin-setup',
        element:  <PinSetup/>
    },
    {
        path: '/completed-setup',
        element:  <RegistrationComplete/>
    },
    {
        path: '/email-verification',
        element:  <EmailVerification />
    },
])