import { Table } from "antd";
import dayjs from "dayjs";
import { formatNumber } from "../../../core-units/utils";

export const PaymentSchedule = ({schedules}) => {

    const columns = [
        {
          title: "Payment Date",
          dataIndex: "created_at",
          key: "created_at",
          render: (text) => dayjs(text).format('DD/MM/YYYY HH:mm')
         },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text) => formatNumber(text)
        },
        {
          title: "Date Paid",
          dataIndex: "date_paid",
          key: "date_paid",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (t, r) => (
            r.status === 0 ? 
              <span className=" text-brand-secondary font-bold">NOT PAID</span>
            : <span className=" text-brand-success font-bold">PAID</span>
          )
        },
    ];

    return (
        <Table
            dataSource={schedules}
            columns={columns}
            pagination={false}
            sticky={false}
        />
    )
}