import { useFormik } from "formik";
import {useDispatch, useSelector} from 'react-redux';
import useSWR from 'swr';
import { InputField } from "../Inputs/Input";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { Col, Row, Select } from "antd";
import { getBillProducts } from "../../../core-units/utils/queryFn";
import { useNavigate } from "react-router-dom";

export const DataForm = ({bill}) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [billers, setBillers] = useState([]);
  const {formData} = useSelector(state => state.billpayment);

  const {data: billerData} = useSWR(`bill-payment/billers?q=${bill?.id}`);

  useEffect(() => {
    if (billerData) {
        const newArr = billerData.data.map(item => ({value: item.biller_code, label: item.name}))
        setBillers(newArr)
    }
  }, [billerData]);

  const formik = useFormik({
    initialValues: {
        country: bill?.country,
        customer: formData?.customer || '',
        type: formData?.type || '',
        amount: formData?.amount || '',
        biller_name: formData?.biller_name || "",
        provider: formData?.provider || '',
        bill_type: bill?.slug,
        provider_id: formData?.provider_id || '',
        product_id: formData?.product_id || ''
    },
    validationSchema: Yup.object({
      amount: Yup.string().min(3).required('An amount is required'),
      biller_name: Yup.string().required('This field is required'),
      customer: Yup.string().required('Enter a valid phone number'),
      type: Yup.string().required('You have not selected a product'),
    }),
    onSubmit: (values, helpers) => {
        dispatch({type: 'SET_BILL_FORM', payload: values});
        navigate(`/bill-payment/${bill?.slug}/preview-confirm`)
    },
  });

  const changeBiller = async code => {
    setLoading(true);
    setProducts([]);

    await getBillProducts(code)
      .then(res => {
        setLoading(true);
        setProducts(res.data.map(item => ({value: item.id, label: item.biller_name, amount: item.amount})));
      })
      .catch(err => {
        setLoading(true);
      });
  };

  const handleChange = (field, value) => {
    formik.setFieldValue([field], value);
    // dispatch(fieldChangeBillPayment(formik.values));
  };


    return (
        <form onSubmit={formik.handleSubmit}>

            <Row>
                <Col span={11} className="mr-8">
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Network Provider</span>
                        <Select 
                            size="large"
                            className="customSelect mt-1"
                            options={billers} 
                            defaultValue={formData?.provider_id}
                            onChange={(value) => {
                                const selectedItem = billers.find(item => item.value === value)
                                changeBiller(value);
                                handleChange('provider', selectedItem.label);
                                handleChange('provider_id', value);

                            }}
                        />
                        <span className="text-red-500"> {formik.errors.biller_name && formik.touched.biller_name && formik.errors.biller_name}</span>   

                    </div>
                </Col>
                <Col span={11}>
                    <div className="mt-5 pb-5">
                        <span className='text-[#AEB5C2]'>Available Bundle</span>
                        <Select 
                            size="large"
                            className="customSelect mt-1"
                            options={products} 
                            defaultValue={formData?.biller_name}
                            onChange={(value) => {
                                const selectedItem = products.find(item => item.value === value);
                                if (selectedItem) {
                                    handleChange('type', selectedItem.label);
                                    handleChange('biller_name', selectedItem.label);
                                    handleChange('amount', selectedItem.amount.toString());
                                    handleChange('product_id', value);
                                }

                            }}
                        />
                        <span className="text-red-500"> {formik.errors.type && formik.touched.type && formik.errors.type}</span>   
                    </div>    
                </Col>
            </Row>
            
            
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>Amount</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='amount' 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    value={formik.values.amount} 
                    readonly={true}
                />
                <span className="text-red-500"> {formik.errors.amount && formik.touched.amount && formik.errors.amount}</span>   
            </div>
            <div className="mt-5 pb-5">
                <span className='text-[#AEB5C2]'>Phone Number</span>
                <InputField 
                    size='large' 
                    className='mt-1' 
                    name='customer' 
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    value={formik.values.customer} 
                />
                <span className="text-red-500"> {formik.errors.customer && formik.touched.customer && formik.errors.customer}</span>   
            </div>
            <div className="mt-5">
                <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                    PROCEED
                </button>
            </div>
        </form>
    )
}