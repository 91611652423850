const initialState = {
    activeBill: null,
    bills: [],
    providers: [],
	formData: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
		case 'SET_BILLS':
			return {
				...state,
				bills: action.payload
			}
		case 'SET_ACTIVE_BILL': {
			return {
				...state,
				activeBill: action.payload
			}
		}
		case 'SET_BILL_FORM': {
			return {
				...state,
				formData: action.payload
			}
		}
		
        default:
			return state
	}
}