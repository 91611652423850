import React from 'react'
// import bg from '../../../assets/images/authBg.svg'
import logo from '../../../assets/images/logo.svg'
import { Input } from 'antd';
import {useNavigate} from 'react-router-dom'
import api from '../../../core-units/api';
import { Formik } from 'formik';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin , notification} from "antd";

import './index.css'
import { hash, updateDeviceNo } from '../../../core-units/utils';
import { MD5 } from 'crypto-js';
import { getUser } from '../../../core-units/utils/queryFn';

const Auth = () => {

    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
          message: data.message,
          description:data.description,
        });
      };
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
      );
    
    const login = (values, setSubmitting) => {
        api.post('/auth/login', values).then(res => {
            if (res.status) { 
                localStorage.setItem('sess', res.data)

                getUser().then(async usrRes => {
                    // console.log(usrRes);
                    if (usrRes.status) {

                        const user = usrRes.data;

                        const web_no = MD5(user.id).toString();
                            
                        if (!user.web_no) {
                            await updateDeviceNo({web_no});
                        } 
                        // generate hash key
                        const keyString = web_no + user.email + user.id;
                        const apiKey = hash(keyString);
                        
                        localStorage.setItem('apiKey', apiKey)

                        if (user.email_verified === 0) {
                            window.location.replace('/email-verification')

                        } else if (user.iscomplete === 0) {
                            window.location.replace('/account-setup')
                        } else {
                            
                            window.location.replace('/')
                            setSubmitting(false)
                        }
                    } else {
                        setSubmitting(false);
                        openNotificationWithIcon("error", {message : 'Login Error', description : usrRes.message})
                    }                   
                }).catch(err => {
                    setSubmitting(false);
                    console.log(err);
                    openNotificationWithIcon("error", {message : 'Login Error', description : err.data.message})
                    
                })
            } else {
                openNotificationWithIcon("error", {message : 'Login Error', description : res.message})
            }
        }).catch(err => {
            console.log(err.data.message)
            openNotificationWithIcon("error", {message : 'Login Error', description : err.data.message})
            setSubmitting(false)
        })
    } 
  const navigate = useNavigate()
  return (
    <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
        {contextHolder}
        <div className="md:w-3/5 rounded-[30px] md:min-h-[60%] min-h-screen bg-white flex">
            <div className="md:w-[60%] w-screen px-10 md:py-10 pt-40">
                <img src={logo} alt="" className='' />
                <div className="mt-10">
                    <h2 className="text-[32px] text-brand-primary">Login</h2>
                    <p className="">Welcome back! Please enter your details</p>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                            errors.email = 'Email is required';
                            } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                            errors.email = 'Invalid email address';
                            }else if(!values.password){
                                errors.password = 'Password is required'
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            // setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            // setSubmitting(false);
                            // }, 400);
                            login(values, setSubmitting)
                        }}
                        >{({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                     <div className="mt-10 pb-5">
                                        <span className='text-[#AEB5C2]'>Username or Email</span>
                                        <Input size='large' className='mt-1' name='email' onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                        <span className="text-red-500"> {errors.email && touched.email && errors.email}</span>   
                                    </div>
                                    <div className="mt- py-5">
                                        <div className="flex"><span className='text-[#AEB5C2]'>Password</span> <p className="text-brand-primary w-full text-right cursor-pointer font-semibold" onClick={() => navigate('/forgot-password')}>Forgot Password?</p></div>
                                        <Input.Password size='large' className='mt-1' name='password' onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                        <span className="text-red-500"> {errors.password && touched.password && errors.password}</span>   
                                    </div>
                                    <div className="mt-5">
                                        <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                            {isSubmitting ? <>
                                                <Spin indicator={antIcon} />
                                                <span className="ml-3 text-white">Loading</span>
                                            </> : <>Login</>}
                                        </button>
                                        <p className="text-center text-[#AEB5C2] mt-10 cursor-pointer" onClick={() => navigate('/register')}>Don't have an account? <span className='text-brand-primary '>Register Here</span></p>
                                    </div>
                                </form>
                            )}
                        </Formik>
                   
                </div>
            </div>
            <div className="w-[40%] hidden md:block bg">
                {/* <img src={bg} alt="" className='h-full w-full md:min-h-[60%]' /> */}
            </div>
        </div>
    </div>
  )
}

export default Auth

