import { Button, Input, Modal } from "antd";
import React from "react";
import { useState } from "react";
import success from "../../../assets/icons/greenSuc.svg";
import api from "../../../core-units/api";
import { useNavigate } from "react-router-dom";
import { ProductForm } from "../../components/Forms/ProductForm";
import { BackButton } from "../../components/PayBills/BackButton";


const Add = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false)


  // const cont = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createLease = (data) => {
    setSubmitting(true)
    api
      .post("/lease-products", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setSubmitting(false)
        navigate('/products')
      })
      .catch((err) => {
        console.log(err, "error");
        setSubmitting(false)
      })
      // .finally(setSubmitting(false));
  };

  return (
    <div className="md:p-10 p-5">
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="md:py-16 py-8 ">
          <img src={success} alt="" className="w-[20%] mx-auto" />
          <p className="text-center mt-5">Product Successfully Added</p>
          <Button
            // onClick={cont}
            type="text"
            className="mt-8 h-10 flex justify-center items-center text-white text-center bg-brand-primary w-full"
          >
            <span className="text-white uppercase font-bold">Continue</span>
          </Button>
        </div>
      </Modal>
      <div className="md:flex justify-between">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Add Products
        </h2>
        <BackButton />
      </div>
      <div className="mt-10 bg-white rounded-lg md:p-10">
        <div>
          <ProductForm onSubmit={createLease} isSubmitting={isSubmitting} />
        </div>
      </div>
    </div>
  );
};

export default Add;
