import React, { useEffect, useState } from "react";
import { Input, Button, notification } from "antd";
import circledPlus from "../../../assets/icons/circledPlus.svg";
import download from "../../../assets/icons/download.svg";
import { useQuery } from "@tanstack/react-query";
import { fetchFinancialPartners } from "../../../core-units/utils/queryFn";
import { Spin, Skeleton } from "antd";
import dayjs from "dayjs";
import PartnersTable from "../../components/AdminTable/PartnersTable";
import { PartnerForm } from "../../components/Forms/PartnerForm";


const Partners = () => {

  const [notice, contextHolder] = notification.useNotification();
  const [partner, setPartner] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  
  const partners = useQuery({
    queryKey: ["partners"],
    queryFn: () => fetchFinancialPartners(),
    retry: false,
  });

  useEffect(() => {
    partners.remove();
    partners.refetch();
    // eslint-disable-next-line
  }, [page]);



  const handleCancel = (data) => {
    setPartner(null)
    setIsModalOpen(false);
    if (data) 
      partners.refetch();
  };
  
  return (
    <div className="md:p-10 p-5">
      {contextHolder}
      <div className="md:flex">
        <h2 className="md:text-[32px] text-[24px] text-brand-primary">
          Finance Partners
        </h2>
        <div className="ml-auto flex gap-5">
          <Button
            className=" mt-1 bg-brand-secondary border-0 flex justify-center items-center  gap-3 "
            size="large"
            onClick={() => setIsModalOpen(true)}
          >
            <img src={circledPlus} alt="" /> 
            <span className="text-white ">Add Partner</span>

          </Button>
        </div>
      </div>
      <div className=" md:my-10 rounded-[10px] md:py-8 py-5 px-0">
        <div className="mt-5 hidden md:block">
          {partners.data && 
            <PartnersTable 
              data={partners.data.data} 
              state={partners} 
              total={partners.data.total}
              page={page}
              onEdit={(val) => {
                setPartner(val);
                setIsModalOpen(true);
              }}
              onRefresh={() => partners.refetch()}
              perPage={partners.data.per_page}
              onPageChange={(n) => setPage(n)} 
            />
          }
          {partners.isLoading && (
            <div className="h-[50vh] flex content-center justify-center align-middle items-center">
              <Spin size="large" />
            </div>
          )}
        </div>
        <div className="mt-5 min-h-[50vh]  md:hidden">
          {partners.isLoading && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>
      </div>
      <PartnerForm isModalOpen={isModalOpen} handleCancel={handleCancel} data={partner} />
    </div>
  );
};

export default Partners;
