import { Table, Tooltip } from "antd";
import download from "../../../assets/icons/download.svg";
import { downloadGuarantorID } from "../../../core-units/utils/queryFn";

export const GuarantorsList = ({guarantors}) => {

    const downloadID = async (item) => {
      await downloadGuarantorID(item.id).then(res => {
        if (res.data) window.open(res.data, '_blank');

      }).catch(err => {
        console.log(err);
      })
    }

    const columns = [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Phone No.",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "Action",
          render: (record) => (
            <div className="flex gap-4 w-full">
              <Tooltip title="Download Identification">
                <img
                  src={download}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => downloadID(record)}
                />
              </Tooltip>
            </div>
          ),
        },
    ];

    return (
        <Table
            dataSource={guarantors}
            columns={columns}
            pagination={false}
            sticky={true}
        />
    )
}