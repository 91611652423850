import { Col, Row} from "antd";
import { Formik } from "formik";
import { InputField } from "../Inputs/Input";

export const BusinessInformation = ({next, prev, data}) => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          business_address: data?.business?.business_address || "",
          business_name: data?.business?.business_name || "",
          business_logo: data?.business?.business_logo || "",
        }}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.business_address) {
        //     errors.business_address = "Provide a business address";
        //   }
        //   if (!values.business_name) {
        //     errors.business_type = "Please enter a business name";
        //   }    
        //   return errors;
        // }}w
        onSubmit={(values, { setSubmitting }) => {
          const newData = {...data};
          next({
            ...newData,
            business_address: values?.business_address,
            business_name: values.business_name,
            business_logo: '',
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
          handleChange,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="py-10">
             <Row type="flex" align="middle">
                <Col className='mr-10 w-full' span={12}>
                    <span className='text-[#AEB5C2]'>Company Name</span>
                    <InputField size="large" className="mt-1" name="business_name" onChange={handleChange} onBlur={handleBlur} value={values.business_name} />
                    <span className="text-red-500"> {errors.business_name && touched.business_name}</span>   
                </Col>
            </Row>
            <Row className='mt-10'>
                <Col className='mr-10 w-full' span={12}>
                    <span className='text-[#AEB5C2]'>Company Address</span>
                    <InputField size="large" className="mt-1" name="business_address" onChange={handleChange} onBlur={handleBlur} value={values.business_address} />
                    <span className="text-red-500"> {errors.business_address && touched.business_address}</span>   
                </Col>
                
            </Row>
            <Row className='mt-10'>
                <Col className='mr-10 w-full' span={12}>
                    <span className='text-[#AEB5C2]'>Upload Company Logo</span>
                    <InputField 
                        type="file" 
                        size='large' 
                        className='mt-1' 
                        name='business_logo' 
                        onChange={(file) => setFieldValue('business_logo', file)} 
                    />
                </Col>
            </Row>
            <div className="md:flex gap-10 mt-10">

              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="button"
                  onClick={() => {
                    const newData = {...data};
                    prev({
                      ...newData,
                      business_address: values?.business_address,
                      business_name: values?.business_name,
                    });
                  }}
                  className="h-10 flex justify-center items-center rounded-md text-center w-full"
                >
                  <span className="text-dark uppercase font-bold">
                    Prev
                  </span>
                </button>
              </div>
              <div className="md:w-1/2 mt-5 md:mt-0">
                <button
                  type="submit"
                  className="h-10 flex justify-center items-center rounded-md text-white text-center bg-brand-primary w-full"
                >
                  <span className="text-white uppercase font-bold">
                    Next
                  </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }