import React, { useRef, useState } from 'react'
import logo from '../../../assets/images/logo.svg'
import { Card, Col, Row, Space, Spin } from 'antd';
import { notification} from "antd";
import {useNavigate} from 'react-router-dom'
import { InputField } from '../../components/Inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { accountSetup } from '../../../core-units/utils/queryFn';


const PinSetup = () => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );
    const [isSubmitting, setSubmitting] = useState(false);
    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
          message: data.message,
          description:data.description,
        });
    };
    const navigate = useNavigate();
    const {registerData} = useSelector(state => state.user);
    const [pin, setPIN] = useState('');
    const [otpRef, setOtpRef] = useState({
        otp1: useRef(),
        otp2: useRef(),
        otp3: useRef(),
        otp4: useRef(),
    });

  const submitPin = () => {
    if (pin.length <= 0 || pin.length != 4) {
      openNotificationWithIcon("error", {message: 'PIN Error', description: 'PIN must be 4 digit'})
      return;
    }
    const payload = {...registerData, pin, biometric: 0};
    setSubmitting(true);
    accountSetup(payload)
      .then(res => {
        setSubmitting(false);
        if (res.status) {
            openNotificationWithIcon("success", {message: 'Success', description: res.message})
            navigate('/completed-setup');
        } else {
            openNotificationWithIcon("error", {message: 'Account Error', description: res.message})
        }
      })
      .catch(err => {
        setSubmitting(false);
        openNotificationWithIcon("error", {message: 'Account Error', description: err.data.message})
    });
  };

  const otpController = (val, next, prev, index) => {
    if (val.length < 1) {
      const code = pin.slice(0, -1);
      setPIN(code);
      if (index === 0) {
        setPIN('');
      } else {
        prev.current.focus();
      }
    } else if (next && val.length > 0) {
      const code = pin + val;
      // add value to code
      setPIN(code);
      next.current.focus();
    } else {
      const code = pin + val;
      // add value to code
      setPIN(code);

      return 0;
    }
  };
  return (
    <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
        {contextHolder}
        <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
            <div className='py-20 mx-auto w-full'>
                <img src={logo} alt="" className='md:mx-auto ' />
                <div className="mt-10  w-[100%]">
                    <h2 className="text-[32px] text-brand-primary md:text-center">Account Setup</h2>
                    <p className=" md:text-center">Kindly provide the required information to continue </p>
                    
                    <Space direction="vertical" size={16} style={{marginLeft: 100}}>
                        <Row className='w-full' style={{borderBottomWidth: 3, borderColor: '#2f8fcd'}}>
                            <Col className='account-setup-tabs'>User Info</Col>
                            <Col className='account-setup-tabs'>Business Info</Col>
                            <Col className='account-setup-tabs'>BVN & NIN</Col>
                            <Col className='account-setup-tabs active'>PIN</Col>
                        </Row>
                        <Card title="PIN Setup" >
                        
                            <div className="w-full mx-auto mt-5">

                                <Row type="flex" align="middle">
                                    <Col className='mr-5 w-[10%]'>
                                        <InputField 
                                            size="large" 
                                            className="mt-1" 
                                            inputRef={otpRef.otp1}
                                            maxLength={1}
                                            onChange={(e) => otpController(e.target.value, otpRef.otp2, '', 0)}
                                        />
                                    </Col>
                                    <Col className='mr-5 w-[10%]'>
                                        <InputField 
                                            size="large" 
                                            className="mt-1" 
                                            inputRef={otpRef.otp2}
                                            maxLength={1}
                                            onChange={(e) => otpController(e.target.value, otpRef.otp3, otpRef.otp1, 1)}
                                        />
                                    </Col>
                                    <Col className='mr-5 w-[10%]'>
                                        <InputField 
                                            size="large" 
                                            className="mt-1" 
                                            inputRef={otpRef.otp3}
                                            maxLength={1}
                                            onChange={(e) => otpController(e.target.value, otpRef.otp4, otpRef.otp2, 2)}

                                        />
                                    </Col>
                                    <Col className='mr-8 w-[10%]'>
                                        <InputField 
                                            size="large" 
                                            className="mt-1" 
                                            inputRef={otpRef.otp4}
                                            maxLength={1}
                                            onChange={(e) => otpController(e.target.value, '', otpRef.otp3, 3)}
                                        />
                                    </Col>
                                </Row>
                                
                                <Row className='mt-10'>
                                    <Col span={11} className='mr-10'>
                                        <span className='text-[#AEB5C2]'>&nbsp;</span>
                                        <button onClick={() => navigate(-1)} type="button" className='bg-brand-tertiary w-full text-white font-bold p-3 rounded-lg'>
                                            Back
                                        </button>
                                    </Col>
                                    <Col span={11}>
                                        <span className='text-[#AEB5C2]'>&nbsp;</span>
                                        <button 
                                            onClick={submitPin}
                                            type="submit" 
                                            className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'
                                        >
                                            {isSubmitting ? <>
                                                <Spin indicator={antIcon} />
                                                <span className="ml-3 text-white">Setting up account...</span>
                                            </> : <>CONTINUE</>}
                                        </button>
                                    </Col>
                                </Row>
                                
                            </div>
                               
                        </Card>
                    </Space>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PinSetup;