import { Formik } from "formik";
import React from "react";
import { Input, Select, Spin } from "antd";
import upload from "../../../assets/icons/upload.svg";
import { useQuery } from "@tanstack/react-query";
import { getProductCat } from "../../../core-units/utils/queryFn";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const ProductForm = ({ onSubmit, isSubmitting, data }) => {

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );

    const categories = useQuery({
        queryKey: ["product_categories"],
        queryFn: getProductCat,
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
              product_name: data?.product_name || "",
              amount: data?.amount || "",
              description: data?.description || "",
              product_category_id: data?.product_category_id || "",
              lease_duration: data?.lease_duration || "",
              initial_amount: data?.initial_payment || "",
              image: "",
              // discount: data?.discount || "",
              people: data?.no_of_users || "",
              status: data?.status || ""
            }}
            validate={(values) => {
              const errors = {};
              if (!values.product_name) {
                errors.product_name = "Product Name is Required";
              }
              if (!values.product_category_id) {
                errors.product_category_id = "Select Product Category";
              }
              if (!values.amount) {
                errors.amount = "Enter Amount";
              }
              if (!values.lease_duration) {
                errors.lease_duration = "Enter Lease Duration";
              }
              if (!values.description) {
                errors.description = "Enter Product Description";
              }
              if (!values.initial_amount) {
                errors.initial_amount = "Please enter initial amount";
              }
              // if (!values.discount) {
              //   errors.discount = "Enter Product Discount";
              // }
              if (!values.people) {
                errors.people = "Enter Number of People ";
              }
              return errors;
            }}
            onSubmit={(values) => {
              values.no_of_users = values.people;
              onSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="md:flex gap-10">
                  <div className="md:w-1/2">
                    <p>Product Name</p>
                    <Input
                      size="large"
                      name="product_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      status={
                        errors.product_name && touched.product_name && "error"
                      }
                      value={values.product_name}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.product_name &&
                        touched.product_name &&
                        errors.product_name}
                    </span>
                  </div>
                  <div className="md:w-1/2 mt-5 md:mt-0">
                    <p>Product Category</p>
                    <Select
                      name="product_category_id"
                      onBlur={handleBlur}
                      options={
                        categories.data
                          ? categories.data.map((i) => {
                              return { label: i.name, value: i.id };
                            })
                          : []
                      }
                      size="large"
                      className="w-full"
                      status={
                        errors.product_category_id &&
                        touched.product_category_id &&
                        "error"
                      }
                      onChange={(value) => setFieldValue('product_category_id', value)}
                      value={values.product_category_id}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.product_category_id &&
                        touched.product_category_id &&
                        errors.product_category_id}
                    </span>
                  </div>
                </div>
                <div className="md:flex gap-10 md:mt-10 mt-5">
                  <div className="md:w-1/2">
                    <p>Product Image</p>
                    <label htmlFor="product_image">
                      <div
                        className={`border py-1 px-5 flex justify-between items-center rounded-md ${
                          errors.image && touched.image && "border-red-500"
                        }`}
                      >
                        <span>{values.image && values.image.name}</span>
                        <img src={upload} alt="" className=" ml-auto" />
                        <input
                          onBlur={handleBlur}
                          size="large"
                          type="file"
                          accept="image/png, image/jpeg"
                          className=" hidden"
                          name="product_image"
                          id="product_image"
                          onChange={(e) => {
                            values.image = e.target.files[0];
                          }}
                        ></input>
                      </div>
                    </label>
                    <span className="text-red-500 mt-5">
                      {errors.image && touched.image && errors.image}
                    </span>
                  </div>
                  <div className="md:w-1/2 mt-5 md:mt-0">
                    <p>Lease Duration</p>
                    <Input
                      size="large"
                      type="number"
                      className="w-full"
                      name="lease_duration"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      status={
                        errors.lease_duration &&
                        touched.lease_duration &&
                        "error"
                      }
                      value={values.lease_duration}

                    />
                    <span className="text-red-500 mt-5">
                      {errors.lease_duration &&
                        touched.lease_duration &&
                        errors.lease_duration}
                    </span>
                  </div>
                 
                </div>
                
                <div className="md:flex gap-10 md:mt-10 mt-5">
                  <div className="w-full">
                    <p>Desciption</p>
                    <TextArea
                      rows={4}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      status={
                        errors.description && touched.description && "error"
                      }
                    />
                    <span className="text-red-500 mt-5">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </span>
                  </div>
                </div>
                <div className="md:flex gap-10 md:mt-10 mt-5">
                  <div className="md:w-1/2">
                    <p>Total Amount Payable</p>
                    <Input
                      onBlur={handleBlur}
                      size="large"
                      type="number"
                      name="amount"
                      onChange={handleChange}
                      status={errors.amount && touched.amount && "error"}
                      value={values.amount}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.amount && touched.amount && errors.amount}
                    </span>
                  </div>
                  <div className="md:w-1/2">
                    <p>Initial down payment</p>
                    <Input
                      onBlur={handleBlur}
                      size="large"
                      type="number"
                      name="initial_amount"
                      onChange={handleChange}
                      value={values.initial_amount}
                      status={errors.initial_amount && touched.initial_amount && "error"}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.initial_amount && touched.initial_amount && errors.initial_amount}
                    </span>
                  </div>
                  {/* <div className="md:w-1/2 mt-5 md:mt-0">
                    <p>Monthly Payment </p>
                    <Input
                      type="number"
                      size="large"
                      className="w-full"
                      name="discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discount}
                      status={errors.discount && touched.discount && "error"}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.discount && touched.discount && errors.discount}
                    </span>
                  </div> */}
                 
                </div>
                
                <div className="md:flex gap-10 md:mt-10 mt-5">
                  <div className="md:w-1/2">
                    <p>Number of people allowed</p>
                    <Input
                      onBlur={handleBlur}
                      size="large"
                      type="number"
                      name="people"
                      onChange={handleChange}
                      value={values.people}
                      status={errors.people && touched.people && "error"}
                    />
                    <span className="text-red-500 mt-5">
                      {errors.people && touched.people && errors.people}
                    </span>
                  </div>
                  {data && (
                    <div className="md:w-1/2 mt-5 md:mt-0">
                        <p>Product Status</p>
                        <Select
                        name="status"
                        onBlur={handleBlur}
                        options={[
                            {value: '', label: 'Select Status'},
                            {value: 1, label: 'Active'},
                            {value: 0, label: 'Inactive'},
                        ]}
                        size="large"
                        className="w-full"
                        status={
                            errors.status &&
                            touched.status &&
                            "error"
                        }
                        onChange={(value) => setFieldValue('status', value)}
                        value={values.status}
                        />
                        <span className="text-red-500 mt-5">
                        {errors.status &&
                            touched.status &&
                            errors.status}
                        </span>
                    </div>
                  )}
                  <div className="md:w-1/2 mt-5 md:mt-0">
                    <button
                      type="submit"
                      className="mt-8 h-10 flex justify-center items-center rounded-md text-white text-center bg-brand-primary w-full"
                    >
                      {isSubmitting ? (
                        <>
                            <Spin indicator={antIcon} />
                            <span className="ml-3 text-white">Loading</span>
                        </>
                      ) : (
                        <span className="text-white uppercase font-bold">
                          Save
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
    )
}