import React from 'react'
import logo from '../../../assets/images/logo.svg'
import { Card, Col, Row, Space } from 'antd';
import { Formik } from 'formik';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin , notification} from "antd";
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup';
import { businessSetup } from '../../../core-units/utils/queryFn';
import { InputField } from '../../components/Inputs/Input';


const BusinessSetup = ({ navigation }) => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
    );

    const [notice, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, data) => {
        notice[type]({
            message: data.message,
            description:data.description,
        });
    };
    const navigate = useNavigate()
    return (
        <div className='w-screen h-screen flex  content-center bg-[#ECF3FF]  items-center justify-center' >
            {contextHolder}
            <div className="md:w-3/5 w-screen m-auto rounded-[30px] px-10 pt-20 md:pt-0 md:min-h-[60%] min-h-screen bg-white flex">
                <div className='py-20 mx-auto w-full'>
                    <img src={logo} alt="" className='md:mx-auto ' />
                    <div className="mt-10  w-[100%]">
                        <h2 className="text-[32px] text-brand-primary md:text-center">Account Setup</h2>
                        <p className=" md:text-center">Kindly provide the required information to continue </p>
                        
                        <Space direction="vertical" size={16} style={{marginLeft: 100}}>
                            <Row className='w-full' style={{borderBottomWidth: 3, borderColor: '#2f8fcd'}}>
                                <Col className='account-setup-tabs'>User Info</Col>
                                <Col className='account-setup-tabs active'>Business Info</Col>
                                <Col className='account-setup-tabs'>BVN & NIN</Col>
                                <Col className='account-setup-tabs'>PIN</Col>
                            </Row>
                            <Card title="Business Information" >
                            
                                <Formik
                                    initialValues={{ 
                                        business_address: '',
                                        business_name: '',
                                        business_logo: '',
                                    }}
                                    validationSchema={
                                        Yup.object({
                                            business_address: Yup.string()
                                            .max(255)
                                            .required('Enter a business address'),
                                            business_name: Yup.string().required('Enter a business name'),
                                        })
                                    
                                    }
                                    onSubmit={(values, { setSubmitting }) => {
                                        const formData = new FormData();
                                        formData.append('business_name', values.business_name);
                                        formData.append('business_address', values.business_address);
                                        
                                        if (values.business_logo) {
                                            formData.append('business_logo', values.business_logo);
                                        }

                                        businessSetup(formData)
                                            .then(res => {
                                                setSubmitting(false);
                                                if (res.status) {
                                                    navigate('/bvn-nin-verification')
                                                } else {
                                                    openNotificationWithIcon('error', {message: 'Error', description: res.message})
                                                }
                                            })
                                            .catch(err => {
                                                setSubmitting(false);
                                                openNotificationWithIcon('error', {message: 'Request Error', description: err.data.message})
                                            });
                                    }}
                                    >{({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                        /* and other goodies */
                                    }) => (
                                        <div className="w-full mx-auto mt-5">

                                            <form onSubmit={handleSubmit} className=''>
                                                <Row type="flex" align="middle">
                                                    <Col className='mr-10 w-full'>
                                                        <span className='text-[#AEB5C2]'>Company Name</span>
                                                        <InputField size="large" className="mt-1" name="business_name" onChange={handleChange} onBlur={handleBlur} value={values.business_name} />
                                                        <span className="text-red-500"> {errors.business_name && touched.business_name}</span>   
                                                    </Col>
                                                </Row>
                                                <Row className='mt-10'>
                                                    <Col className='mr-10 w-full'>
                                                        <span className='text-[#AEB5C2]'>Company Address</span>
                                                        <InputField size="large" className="mt-1" name="business_address" onChange={handleChange} onBlur={handleBlur} value={values.business_address} />
                                                        <span className="text-red-500"> {errors.business_address && touched.business_address}</span>   
                                                    </Col>
                                                    
                                                </Row>
                                                <Row className='mt-10'>
                                                    <Col className='mr-10 w-full'>
                                                        <span className='text-[#AEB5C2]'>Upload Company Logo</span>
                                                        <InputField 
                                                            type="file" 
                                                            size='large' 
                                                            className='mt-1' 
                                                            name='business_logo' 
                                                            onChange={(file) => setFieldValue('business_logo', file)} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-5'>
                                                    <Col span={11} className='mr-10'>
                                                        <span className='text-[#AEB5C2]'>&nbsp;</span>
                                                        <button onClick={() => navigate(-1)} type="button" className='bg-brand-tertiary w-full text-white font-bold p-3 rounded-lg'>
                                                            BACK
                                                        </button>
                                                    </Col>
                                                    <Col span={11}>
                                                        <span className='text-[#AEB5C2]'>&nbsp;</span>
                                                        <button type="submit" className='bg-brand-primary w-full text-white font-bold p-3 rounded-lg'>
                                                            {isSubmitting ? <>
                                                                <Spin indicator={antIcon} />
                                                                <span className="ml-3 text-white">Loading</span>
                                                            </> : <>CONTINUE</>}

                                                        </button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    )}
                                </Formik>
                            </Card>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessSetup;